import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, CanLoad, Route, UrlSegment } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RootGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService,
    private router: Router) { }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.authService.isAuthenticated()) {
      return true;
    }
    window.location.href = window.location.origin + '/api/countryx';
  }

  canActivate() {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    window.location.href = window.location.origin + '/api/countryx';
  }
}
