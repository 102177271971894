import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { UtilsService } from '../core/utils.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  logging: boolean = true;

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private idle: Idle, private keepalive: Keepalive,
    private router: Router, private modalService: BsModalService, private authService: AuthService, public utils: UtilsService) {

    this.authService.getLoggedInUserID();// initiate login request
    if (this.authService.isAuthenticated()) {
      this.logging = false;
    }
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(900);// interpret as session timeout? in seconds for 15 minutes
    // sets a timeout period of 30 seconds. after 30 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      // this.childModal.hide();
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your session will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(30);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    if (this.authService.isAuthenticated()) {
      console.log('Idle watch started')
      idle.watch()
      this.timedOut = false;
    } else {
      console.log('Idle watch stopped')
      idle.stop();
    }

    this.reset();
  }
  ngOnInit(): void {
    console.log('');
  }

  reset() {

    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.authService.reset();// call api to reset cookie maxage
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.authService.removeUserInfo();
    this.authService.logout();
  }
  onActivate(event) {
    window.scroll(0, 0);
  }

}
