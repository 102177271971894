import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nodata',
  templateUrl: './nodata.component.html',
  styleUrls: ['./nodata.component.scss']
})
export class NodataComponent implements OnInit {

  reportPeriod: string;
  concentration: string;
  format: string;
  bank: string;
  region: string;
  country: string;
  caseManager: string;
  fieldOffice: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    const { bank, caseManager, concentration, country, fieldOffice, format, region, reportPeriod } =
      this.route.snapshot.queryParams;

    this.reportPeriod = reportPeriod;
    this.concentration = concentration;
    this.format = format;
    this.bank = bank.join(', ');
    this.region = region.join(', ');
    this.country = country.join(', ');
    this.caseManager = caseManager.join(', ');
    this.fieldOffice = fieldOffice.join(', ');
  }

  backToSearch(): Promise<boolean> {
    return this.router.navigate(['/countryx']);
  }
}
