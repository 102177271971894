import { MainReportColumn, FilterVars } from '../models/tableAndExcel.model';



export const bankMainColumns: MainReportColumn[] = [
  {
    name: 'cert',
    display: 'Cert'
  },
  {
    name: 'instClass',
    display: 'Class'
  },
  {
    name: 'bank',
    display: 'Bank'
  },
  {
    name: 'holdingCompany',
    display: 'Holding Company'
  },
  {
    name: 'city',
    display: 'City'
  },
  {
    name: 'state',
    display: 'State'
  },
  {
    name: 'totalAssets',
    display: 'Total Assets'
  },
  {
    name: 'tier1Cap',
    display: 'Tier1 Cap'
  }
];

export const bankColumnsToDisplay: string[] = bankMainColumns.map(col => col.name);

export const bankInfoHeaders: string[] = bankMainColumns.map((col) => col.display);

export const bankDisplayedColumnsForms: string[] = ['cert', 'class', 'bank', 'region', 'caseManager', 'fieldOffice', 'country', 'holdingCompany', 'city', 'state', 'totalAssets', 'tier1Cap'];

export const bankInnerDisplayedColumns = ['country', 'currCountryRiskExp', 'prevCountryRiskExp', 'currCountryRiskCon', 'currTransferRiskExp', 'prevTransferRiskExp', 'currTransferRiskCon'];

export const subtotalColumns = ['subtotal', 'currCountryRiskExpTotal', 'prevCountryRiskExpTotal', 'blank1', 'currTransferRiskExpTotal', 'prevTransferRiskExpTotal', 'blank2'];

export const bankTableSubhead = '(All Figures are in U.S. Dollars, Rounded to the Nearest Million)';

export const dataCategories = ['COUNTRY RISK CLAIMS', 'TRANSFER RISK CLAIMS'];

export const dataCategoriesExcel = ['', 'COUNTRY RISK CLAIMS', '', '', 'TRANSFER RISK CLAIMS'];

export const expSubtotals: MainReportColumn[] = [{
  name: 'subTotal',
  display: 'SubTotal',
},
{
  name: 'subTotCountryRiskClaims',
  display: 'SubTotCountryRiskClaims',
},
{
  name: 'subTotTransferRiskClaims',
  display: 'SubTotTransferRiskClaims',
},
{
  name: 'subTotUnusedCommitments',
  display: 'SubTotUnusedCommitments',
},
{
  name: 'subTotGuaranteesExcludingCDS',
  display: 'SubTotGuaranteesExcludingCDS',
},

{
  name: 'subTotCDSGrossProtectionSold',
  display: 'SubTotCDSGrossProtectionSold',
},
{
  name: 'subTotCDSGrossProtectionPurchased',
  display: 'SubTotCDSGrossProtectionPurchased',
},
{
  name: 'subTotCDSNetProtectionSold',
  display: 'SubTotCDSNetProtectionSold',
},
{
  name: 'subTotCDSNetProtectionPurchased',
  display: 'SubTotCDSNetProtectionPurchased',
}
];

export const expSubtotalColumns: string[] = expSubtotals.map(col => col.name);

export const expBankColumnForms: MainReportColumn[] = [
  {
    name: 'bank',
    display: 'Bank'
  },
  {
    name: 'cert',
    display: 'Cert'
  },
  {
    name: 'city',
    display: 'City'
  },
  {
    name: 'holdingCompany',
    display: 'Holding Company'
  },
  {
    name: 'instClass',
    display: 'Class'
  },
  {
    name: 'totalAssets',
    display: 'Total Assets'
  },
  {
    name: 'tier1Cap',
    display: 'Tier 1 Cap'
  },
];

export const expBankColumnHeaders = expBankColumnForms.map(col => col.name);

export const expBankColumnDisplay = expBankColumnForms.map(col => col.display);

export const createExpExcelBankRow = () => {
  const headers = [...expBankColumnDisplay];
  headers.splice(4, 0, '')
  headers.splice(1, 0, '');
  return headers;
}

export const expMainColumns: MainReportColumn[] = [
  {
    name: 'country',
    display: 'Country'
  },
  {
    name: 'countryRiskClaims',
    display: 'Country Risk Claims'
  },
  {
    name: 'transferRiskClaims',
    display: 'Transfer Risk Claims'
  },
  {
    name: 'unusedCommitments',
    display: 'Unused Commitments'
  },
  {
    name: 'guaranteesExCDS',
    display: 'Guarantees Excluding CDS'
  },
  {
    name: 'cdsGrPrSold',
    display: 'CDS:Gross Protection Sold'
  },
  {
    name: 'cdsGrPrPurchased',
    display: 'CDS:Gross Protection Purchased'
  },
  {
    name: 'cdsNetPrSold',
    display: 'CDS:Net Protection Sold'
  },
  {
    name: 'cdsNetPrPurchased',
    display: 'CDS:Net Protection Purchased'
  }
];

export const expMainColumnsToDisplay: string[] = expMainColumns.map(col => col.name);

export const expMainColumnsExcel: string[] = expMainColumns.map(col => col.display);

export const setFilterVars = (filterType: string): FilterVars => {
  let filterTitle = '';
  let subColumns: MainReportColumn[];
  switch (filterType) {
    case 'caseManager':
      filterTitle = 'Case Manager';
      subColumns = [
        {
          name: 'region',
          display: 'Region'
        },
        {
          name: 'fieldOffice',
          display: 'Field Office'
        }
      ];
      break;
    case 'fieldOffice':
      filterTitle = 'Field Office';
      subColumns = [
        {
          name: 'caseManager',
          display: 'Case Manager'
        },
        {
          name: 'region',
          display: 'Region'
        }
      ];
      break;
    default:
      filterTitle = 'Region';
      subColumns = [
        {
          name: 'caseManager',
          display: 'Case Manager'
        },
        {
          name: 'fieldOffice',
          display: 'Field Office'
        }
      ];
  }
  return {
    filterTitle,
    subColumns
  }
}