import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import {
  MatButtonModule
} from '@angular/material/button';
import {
  MatCheckboxModule
} from '@angular/material/checkbox';
import {
  MatInputModule
} from '@angular/material/input';
import {
  MatSelectModule
} from '@angular/material/select';
import {
  MatDatepickerModule
} from '@angular/material/datepicker';
import {
  MatNativeDateModule
} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { FormsComponent } from './forms/forms.component';
import { CxselectComponent } from './cxselect/cxselect.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HeaderComponent } from './header/header.component';
import { RootComponent } from './root/root.component';
import { FacsimileComponent } from './facsimile/facsimile.component';
import { HelpComponent } from './help/help.component';
import { MainComponent } from './main/main.component';
import { BankComponent } from './bank/bank.component';
import { TableComponent } from './table/table.component';
import { CxtableComponent } from './cxtable/cxtable.component';
import { CallbackComponent } from './callback/callback.component';
import { LoadingComponent } from './core/loading.component';
import { TableBasicExample } from './basic/basic.component';
import { NodataComponent } from './nodata/nodata.component';
import { TableBasicExampleComponent } from './table-basic-example/table-basic-example.component';
import { CountryComponent } from './country/country.component';
import { FacSelectComponent } from './facselect/facselect.component';
import { Form009Y2005Component } from './form009-y2005/form009-y2005.component';
import { Form009Y2006Component } from './form009-y2006/form009-y2006.component';
import { Form009Y2013Component } from './form009-y2013/form009-y2013.component';
import { AuthComponent } from './auth/auth.component';
// import { TokenInterceptor } from './services/token.interceptor';
import { HomeWarnComponent } from './home-warn/home-warn.component';
import { UnauthComponent } from './unauth/unauth.component';
import { BankexpComponent } from './bankexp/bankexp.component';
import { CountryexpComponent } from './countryexp/countryexp.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { ModalModule } from 'ngx-bootstrap/modal';
import { ExcelButtonComponent } from './excel-button/excel-button.component';
import { BankFilteredComponent } from './bank-filtered/bank-filtered.component';
import { CountryFilteredComponent } from './country-filtered/country-filtered.component';
import { BankexpFilteredComponent } from './bankexp-filtered/bankexp-filtered.component';
import { CountryexpFilteredComponent } from './countryexp-filtered/countryexp-filtered.component';
import { ResetButtonComponent } from './reset-button/reset-button.component';
import { NodataButtonComponent } from './nodata-button/nodata-button.component';
import { ExposureHeaderComponent } from './exposure-header/exposure-header.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    AppComponent,
    FormsComponent,
    CxselectComponent,
    HeaderComponent,
    RootComponent,
    FacsimileComponent,
    HelpComponent,
    MainComponent,
    BankComponent,
    TableComponent,
    CxtableComponent,
    CallbackComponent,
    LoadingComponent,
    TableBasicExample,
    NodataComponent,
    TableBasicExampleComponent,
    CountryComponent,
    FacSelectComponent,
    Form009Y2005Component,
    Form009Y2006Component,
    Form009Y2013Component,
    AuthComponent,
    HomeWarnComponent,
    UnauthComponent,
    BankexpComponent,
    CountryexpComponent,
    ExcelButtonComponent,
    BankFilteredComponent,
    CountryFilteredComponent,
    BankexpFilteredComponent,
    CountryexpFilteredComponent,
    ResetButtonComponent,
    NodataButtonComponent,
    ExposureHeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    MatTableModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    NgSelectModule
  ],
  providers: [CountryXApiService, UtilsService],
  // providers: [CountryXApiService, UtilsService, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
  bootstrap: [RootComponent]
})
export class AppModule { }
