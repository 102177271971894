import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { User, LoginResponse } from '../models/user.interface';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-home-warn',
  templateUrl: './home-warn.component.html',
  styleUrls: ['./home-warn.component.scss']
})
export class HomeWarnComponent implements OnInit {

  userInfo: User = {
    user_id: '',
    user_name: '',
    email: '',
    scope: '',
    isCountryXUser: false
  };

  loginResponse: LoginResponse = {
    accessToken: '',
    refreshToken: '',
    user: this.userInfo,
  };

  constructor(private router: Router, private apiService: ApiService, private route: ActivatedRoute, private authService: AuthService) {

    console.log('Calling getloggedInUserID..')
    this.authService.getLoggedInUserID();

  }

  ngOnInit(): void {
  }


}


