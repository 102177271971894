import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CXReport, CXReportStructured, SixthRowElement, ExpDetailRowElement, SubtotalRowElement, CXReportBankBase, CXReportBankExpBase, CXReportCountry, CXReportCountryBase, CXReportCountryCaseManagerBase, CXReportCountrySubBase, CXReportCountrySubBaseExp, CXReportBankBaseExp, CXReportBankExp, CXReportCountryBaseExp, CXReportCountryExp, SubTotalGroupExp, CXReportCountryFiltered, CXReportBankFiltered, CXReportBankFilteredExp, CXReportCountryFilteredExp, CXReportCountryFilteredBaseExp } from 'src/app/models/report.model';
import { ExposureSelectCriteria } from '../models/selectCriteria.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class CountryXApiService {
  cxReportCountryListExp: any[];
  cxReportCountryCaseManagerListExp: any[];
  constructor(private httpClient: HttpClient) { }

  private cxData: any = undefined;
  cxStructuredReports: CXReportStructured[] = [];
  cxReportBankFiltered: CXReportBankFiltered[] = [];
  cxReportCountryList: CXReportCountry[] = [];
  cxReportCountryFilteredList: CXReportCountryFiltered[] = [];
  //for expanded report
  cxReportBankExp: CXReportBankExp[];
  cxReportBankFilteredExp: CXReportBankFilteredExp[];
  cxReportCountryExp: CXReportCountryExp[] = [];
  cxReportCountryFilteredExp: CXReportCountryFilteredExp[] = [];

  cxReportData: CXReport[] = [];

  setData(cxData: any) {
    this.cxData = cxData;
  }

  getData(): any {
    return this.cxData;
  }

  private async request(method: string, url: string, data?: any) {
    console.log(url);
    const result = this.httpClient.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body'
    });
    console.log('here is result from api request..', result)
    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  arrayToString(criteriaArray: any) {
    var queryParamString = '';
    for (let i = 0; i < criteriaArray.length; i++) {
      queryParamString += criteriaArray[i].replace(/,/g, ";") + ',';
    }
    queryParamString = queryParamString.substring(0, queryParamString.length - 1)
    queryParamString += '&';
    return queryParamString;
  }

  formatNumber(numberValue) {
    if (numberValue.toString().toLowerCase().includes('subtotal'))
      return numberValue;
    return numberValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  url_prefix = API_URL + `api/`;

  getCountryXDataByBank(critObj: ExposureSelectCriteria, prevQtr: string) {
    console.log(critObj);
    const { bankBHCName, region, country, caseManager, fieldOffice } = critObj;
    const format = critObj.reportFormat[0];
    const reportPeriod = critObj.reportingPeriod[0];
    const concentration = critObj.concentration[0];
    let url = this.url_prefix + 'getCountryXByBank?';
    console.log('getCountryXDataByBank');

    var queryParams = '';

    if (format)
      queryParams += 'format=' + format;

    if (reportPeriod)
      queryParams += '&reportPeriod=' + reportPeriod;
    if (prevQtr)
      queryParams += '&prevQtr=' + prevQtr;

    if (bankBHCName) {
      queryParams += '&bank=';
      var bankStr = this.arrayToString(bankBHCName);
      queryParams += bankStr;
    }

    if (region)
      queryParams += '&region=' + region;
    if (country)
      queryParams += '&country=' + country;
    if (caseManager) {
      queryParams += '&caseManager=';
      var caseManagerStr = this.arrayToString(caseManager);
      queryParams += caseManagerStr;
    }
    if (fieldOffice)
      queryParams += '&fieldOffice=' + fieldOffice;
    if (concentration)
      queryParams += '&concentration=' + concentration;


    // queryParams = queryParams.substring(0, queryParams.length - 1)
    url += queryParams;

    console.log('api call received, sending request..', url)
    return this.request('GET', url);

  }

  calculatePreviousQuarter(reportPeriod: string) {

    let currQuart = new Date(reportPeriod);
    let prevQuart: string;
    let strM;
    let intD;
    let strY;

    let currentQuarter = (Math.floor((currQuart.getMonth() - 1) / 3)) + 1;
    var lastQuarter = (currentQuarter > 1) ? currentQuarter - 1 : currentQuarter = 4;

    strY = currQuart.getFullYear();

    switch (lastQuarter) {
      case 1: strM = '03'; intD = 31; break;
      case 2: strM = '06'; intD = 30; break;
      case 3: strM = '09'; intD = 30; break;
      case 4: strM = '12'; intD = 31; strY = strY - 1; break;
    }

    prevQuart = strY + "-" + strM + "-" + intD;
    console.log('calculatePreviousQuarter prevQuart', prevQuart);
    return prevQuart;

  }

  getPreviousQuarter(reportPeriod: string) {
    let url = this.url_prefix + 'getPreviousQuarter?';
    console.log('getPreviousQuarter for ', reportPeriod);

    if (reportPeriod)
      url += 'reportPeriod=' + reportPeriod;

    console.log('api call received, sending request..', url)

    return this.request('GET', url);
  }

  getCurrentQuarter() {
    let url = this.url_prefix + 'getCurrentReportingPeriod';
    return this.request('GET', url);
  };

  getTransformedCountryXDataByBank(cxReportOriginalData: CXReport[]): CXReportStructured[] {
    this.cxStructuredReports = [];
    const banks: string[] = [];

    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { bank } = rec;
      const bankIdx = banks.indexOf(bank.toUpperCase());
      if (bankIdx === -1) {
        banks.push(bank.toUpperCase());
        // Create object for this bank
        const baseObj: CXReportStructured = this.createBaseBankObj(rec);
        this.cxStructuredReports.push(baseObj);

      } else if (bankIdx > -1) {
        // If bank already has a record in cxStructuredReports, create new sixthRow for that bank and push it to the array, then update subtotal row
        const sixthRow: SixthRowElement = this.createSixthRowElement(rec);
        const bankIdx = banks.findIndex((item) => item === rec.bank.toUpperCase());
        this.cxStructuredReports[bankIdx].sixthRowElement.push(sixthRow);
        this.bankNoFilterAddToSubtotalRow(this.cxStructuredReports[bankIdx], rec);
      }
    }
    console.log('final cxReportBankList', this.cxStructuredReports);
    return this.cxStructuredReports;
  }

  getTransformedCountryXDataByBankExp(cxReportOriginalData: CXReport[]): CXReportBankExp[] {
    this.cxReportBankExp = [];
    console.log('data sent by getCountryXDataByBank.Expanded', cxReportOriginalData)

    const banks: string[] = [];

    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { bank } = rec;
      const bankIdx = banks.indexOf(bank.toUpperCase());
      if (bankIdx === -1) {
        banks.push(bank.toUpperCase());
        // Create bank base with expDetailRowElement
        const baseBankObj: CXReportBankBaseExp = this.createBaseBankExpObj(rec);
        const subtotalRow: SubTotalGroupExp = this.createInitialSubtotalRowExp(rec);
        const completeObj: CXReportBankExp = {
          cxReportBankBaseListExp: [baseBankObj],
          subtotalRow,
        }
        this.cxReportBankExp.push(completeObj);

      } else if (bankIdx > -1) {
        // If bank already has a record in cxReportBankExp, create new expDetailRowElement for that bank and push it to the array, then update subtotal row
        const expDetailElement: ExpDetailRowElement = this.createBankExpDetailElement(rec);
        const bankIdx = banks.findIndex((item) => item === rec.bank.toUpperCase());
        this.cxReportBankExp[bankIdx].cxReportBankBaseListExp[0].expDetailRowElement.push(expDetailElement);
        this.addToSubtotalRowExp(this.cxReportBankExp[bankIdx], rec);
      }
    }

    console.log('How does expanded cxReportStructuredData[] - Expanded look like?', this.cxReportBankExp)
    return this.cxReportBankExp;
  }

  getTransformedCountryXDataByBankFiltered(cxReportOriginalData: CXReport[], filter: string): CXReportBankFiltered[] {
    this.cxReportBankFiltered = [];
    console.log('data sent by getCountryXDataByBank..', cxReportOriginalData)
    const filteredMatches: string[] = [];

    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { bank } = rec;
      const filterString = rec[filter].toUpperCase();

      const filterIdx = filteredMatches.indexOf(filterString);
      // If the filter parent has data and hasn't been created, add it.
      if (filterIdx === -1) {
        filteredMatches.push(filterString);
        // Since the filter parent was just created, the bank associated with it is new too. Build object and push to array
        const bankObj: CXReportBankBase = this.createBaseBankObjWithFilter(rec);
        const subtotalRow = this.createInitialSubtotalRow(rec);

        const combinedObj: CXReportBankFiltered = {
          cxReportBankBaseList: [bankObj],
          subtotalRow,
          filterGroup: filterString,
        }
        this.cxReportBankFiltered.push(combinedObj);

        // If the filter has data and already exists...
      } else if (filterIdx > -1) {
        const { cxReportBankBaseList } = this.cxReportBankFiltered[filterIdx];
        const bankIdx = cxReportBankBaseList.findIndex((item) => item.fourthRowGroup.bank.toUpperCase() === bank.toUpperCase());

        // If the bank does not exist in the parent already, create object and push to existing parent array and add to subtotal
        if (bankIdx === -1) {
          const newBankObj: CXReportBankBase = this.createBaseBankObjWithFilter(rec);
          cxReportBankBaseList.push(newBankObj);
        } else {
          // If the bank does exist in the parent, create just sixthRowElement and push to that banks's array
          const newSixthRowElement: SixthRowElement = this.createSixthRowElement(rec);
          cxReportBankBaseList[bankIdx].sixthRowElement.push(newSixthRowElement);
        }
        this.addToSubtotalRow(this.cxReportBankFiltered[filterIdx], rec);
      }
    }
    console.log('How does cxReportStructuredData[] look like?', this.cxReportBankFiltered)
    return this.cxReportBankFiltered;
  }

  getTransformedCountryXDataByBankFilteredExp(cxReportOriginalData: CXReport[], filter: string): CXReportBankFilteredExp[] {
    this.cxReportBankFilteredExp = [];
    console.log('data sent by getCountryXDataByBank..', cxReportOriginalData)

    const filteredMatches: string[] = [];
    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { bank } = rec;
      const filterString = rec[filter].toUpperCase();

      const filterIdx = filteredMatches.indexOf(filterString);
      // If the filter parent has data and hasn't been created, add it.
      if (filterIdx === -1) {
        filteredMatches.push(filterString);
        // Since the filter parent was just created, the bank associated with it is new too. Build object and push to array
        const bankObj: CXReportBankExpBase = this.createBaseBankExpObj(rec);
        const subtotalRow: SubTotalGroupExp = this.createInitialSubtotalRowExp(rec);

        const combinedObj: CXReportBankFilteredExp = {
          cxReportBankBaseListExp: [bankObj],
          subtotalRow,
          filterGroup: filterString,
        }

        this.cxReportBankFilteredExp.push(combinedObj);

        // If the filter has data and already exists...
      } else if (filterIdx > -1) {
        const { cxReportBankBaseListExp: cxReportBankBaseList } = this.cxReportBankFilteredExp[filterIdx];
        const bankIdx = cxReportBankBaseList.findIndex((item) => item.fourthRowGroup.bank.toUpperCase() === bank.toUpperCase());

        // If the bank does not exist in the parent already, create object and push to existing parent array and add to subtotal
        if (bankIdx === -1) {
          const newBankObj: CXReportBankExpBase = this.createBaseBankExpObj(rec);
          cxReportBankBaseList.push(newBankObj);
        } else {
          // If the bank does exist in the parent, create just expDetailRowElement and push to that banks's array
          const newElement: ExpDetailRowElement = this.createBankExpDetailElement(rec);
          cxReportBankBaseList[bankIdx].expDetailRowElement.push(newElement);
        }
        this.addToSubtotalRowExp(this.cxReportBankFilteredExp[filterIdx], rec);
      }
    }



    console.log('How does cxReportBankFieldOfficeList[] look like?', this.cxReportBankFilteredExp)

    return this.cxReportBankFilteredExp;
  }

  getTransformedCountryXDataByCountry(cxReportOriginalData: CXReport[]): CXReportCountry[] {
    this.cxReportCountryList = [];
    console.log('data sent by getCountryXDataByBank..', cxReportOriginalData)
    const countriesWithData: string[] = [];

    // grouped by country loop
    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { country } = rec;
      const matchIdx = countriesWithData.indexOf(country.toUpperCase());
      if (matchIdx === -1) {
        countriesWithData.push(country.toUpperCase());
        // Create object for this country/bank combo
        const baseObj: CXReportCountryBase = this.createBaseCountryObj(rec);
        const subtotalRow: SubtotalRowElement = this.createInitialSubtotalRow(rec);
        const combinedObj: CXReportCountry = {
          countryGroup: country,
          cxReportCountryBaseList: [baseObj],
          subtotalRow,
        }
        this.cxReportCountryList.push(combinedObj);

      } else if (matchIdx > -1) {
        // If country already has a record in cxReportCountryList, create new CXReportCountryBase for that country and push it to the array, then update subtotal row
        const countryIdx = countriesWithData.findIndex((item) => item === rec.country.toUpperCase());
        const newBaseObj: CXReportCountryBase = this.createBaseCountryObj(rec);
        const firstCountryObj = this.cxReportCountryList[countryIdx];
        firstCountryObj.cxReportCountryBaseList.push(newBaseObj);

        this.addToSubtotalRow(firstCountryObj, rec);
      }
    }
    console.log('final cxReportCountryList', this.cxReportCountryList);
    return this.cxReportCountryList;
  }

  getTransformedCountryXDataByCountryExp(cxReportOriginalData: CXReport[]): CXReportCountryExp[] {
    this.cxReportCountryListExp = [];
    console.log('data sent by getCountryXDataByCountryExp', cxReportOriginalData)
    const countriesWithData: string[] = [];

    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { country } = rec;
      const matchIdx = countriesWithData.indexOf(country.toUpperCase());
      if (matchIdx === -1) {
        countriesWithData.push(country.toUpperCase());
        // Create object for this country/bank combo
        const baseObj: CXReportCountryBaseExp = this.createBaseCountryExpObj(rec);
        const subtotalRow: SubTotalGroupExp = this.createInitialSubtotalRowExp(rec);
        const combinedObj: CXReportCountryExp = {
          countryGroupExp: country,
          cxReportCountryBaseListExp: [baseObj],
          subtotalRow,
        }
        this.cxReportCountryListExp.push(combinedObj);

      } else if (matchIdx > -1) {
        // If country already has a record in cxReportCountryListExp, create new CXReportCountryBaseExp for that country and push it to the array, then update subtotal row
        const countryIdx = countriesWithData.findIndex((item) => item === rec.country.toUpperCase());
        const newBaseObj: CXReportCountryBaseExp = this.createBaseCountryExpObj(rec);
        const firstCountryObj = this.cxReportCountryListExp[countryIdx];
        firstCountryObj.cxReportCountryBaseListExp.push(newBaseObj);

        this.addToSubtotalRowExp(firstCountryObj, rec);
      }
    }
    console.log('final cxReportCountryList', this.cxReportCountryListExp);
    return this.cxReportCountryListExp;
  }

  getTransformedCountryXDataByCountryFiltered(cxReportOriginalData: CXReport[], filter: string): CXReportCountryFiltered[] {
    this.cxReportCountryFilteredList = [];
    const filteredMatches: string[] = [];
    console.log('data sent by getCountryXDataByBank..', cxReportOriginalData);
    // grouped by country loop
    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { country } = rec;
      const filterString = rec[filter].toUpperCase();
      const matchedIdx = filteredMatches.indexOf(filterString);
      // If the filter parent has data and hasn't been created, add it.
      if (matchedIdx === -1) {
        filteredMatches.push(filterString);
        // Since the filter parent was just created, the country associated with it is new too. Build object and push to array
        // Create object for this country/bank combo
        const countryBaseObj: CXReportCountrySubBase = this.createBaseCountryObjWithFilter(rec);
        const newBaseObj: CXReportCountryCaseManagerBase = {
          countryGroup: country,
          countrySubBaseList: [countryBaseObj]
        }
        const subtotalRow = this.createInitialSubtotalRow(rec);
        const combinedObj: CXReportCountryFiltered = {
          cxReportCountryCaseManagerBaseList: [newBaseObj],
          subtotalRow,
          filterGroup: filterString,
        }
        this.cxReportCountryFilteredList.push(combinedObj);

        // If the region has data and already exists...
      } else if (matchedIdx > -1) {
        const filterIdx = filteredMatches.findIndex((item) => item === filterString);
        const { cxReportCountryCaseManagerBaseList } = this.cxReportCountryFilteredList[filterIdx];
        const countryIdx = cxReportCountryCaseManagerBaseList.findIndex((item) => item.countryGroup.toUpperCase() === country.toUpperCase());

        // If the country does not exist in the parent already, create object and push to existing parent array
        if (countryIdx === -1) {
          const countryBaseObj: CXReportCountrySubBase = this.createBaseCountryObjWithFilter(rec);
          const newBaseObj: CXReportCountryCaseManagerBase = {
            countryGroup: country,
            countrySubBaseList: [countryBaseObj]
          }
          cxReportCountryCaseManagerBaseList.push(newBaseObj);
        } else {
          // If the country does exist in the parent, create object and push to that country's array
          const countryBaseObj: CXReportCountrySubBase = this.createBaseCountryObjWithFilter(rec);
          cxReportCountryCaseManagerBaseList[countryIdx].countrySubBaseList.push(countryBaseObj);
        }
        this.addToSubtotalRow(this.cxReportCountryFilteredList[filterIdx], rec);
      }
    }
    console.log('How does cxReportCountryFilteredList[] look like?', this.cxReportCountryFilteredList)
    return this.cxReportCountryFilteredList;
  }

  getTransformedCountryXDataByCountryFilteredExp(cxReportOriginalData: CXReport[], filter: string): CXReportCountryFilteredExp[] {
    this.cxReportCountryFilteredExp = [];
    const filteredMatches: string[] = [];
    console.log('data sent by getCountryXDataByCountryFiltered..', cxReportOriginalData);

    for (let i = 0; i < cxReportOriginalData.length; i++) {
      const rec = cxReportOriginalData[i];
      const { country } = rec;
      const filterString = rec[filter].toUpperCase();
      const matchedIdx = filteredMatches.indexOf(filterString);
      // If the filter parent has data and hasn't been created, add it.
      if (matchedIdx === -1) {
        filteredMatches.push(filterString);
        // Since the filter parent was just created, the country associated with it is new too. Build object and push to array
        // Create object for individual country/bank combo
        const countrySubBaseObj: CXReportCountrySubBaseExp = this.createBaseCountryExpObjWithFilter(rec);
        const newBaseObj: CXReportCountryFilteredBaseExp = {
          countryGroup: country,
          countrySubBaseList: [countrySubBaseObj]
        }
        const subtotalRow = this.createInitialSubtotalRowExp(rec);
        const combinedObj: CXReportCountryFilteredExp = {
          cxReportCountryBaseList: [newBaseObj],
          subtotalRow,
          filterGroup: filterString,
        }

        this.cxReportCountryFilteredExp.push(combinedObj);

        // If the filter has data and already exists...
      } else if (matchedIdx > -1) {
        const filterIdx = filteredMatches.findIndex((item) => item === filterString);
        const { cxReportCountryBaseList } = this.cxReportCountryFilteredExp[filterIdx];
        const countryIdx = cxReportCountryBaseList.findIndex((item) => item.countryGroup.toUpperCase() === country.toUpperCase());

        // If the country does not exist in the parent already, create object and push to existing parent array
        if (countryIdx === -1) {
          const countrySubBaseObj: CXReportCountrySubBaseExp = this.createBaseCountryExpObjWithFilter(rec);
          const newBaseObj: CXReportCountryFilteredBaseExp = {
            countryGroup: country,
            countrySubBaseList: [countrySubBaseObj]
          }
          cxReportCountryBaseList.push(newBaseObj);
        } else {
          // If the country does exist in the parent, create object and push to that country's array
          const countrySubBaseObj: CXReportCountrySubBaseExp = this.createBaseCountryExpObjWithFilter(rec);
          cxReportCountryBaseList[countryIdx].countrySubBaseList.push(countrySubBaseObj);
        }
        this.addToSubtotalRowExp(this.cxReportCountryFilteredExp[filterIdx], rec);
      }
    }
    console.log('How does cxReportCountryFilteredList[] look like?', this.cxReportCountryFilteredExp)
    return this.cxReportCountryFilteredExp;
  }

  getFacsimileData(format: string, reportPeriod: string, prevQtr: string, bank: string[]) {
    // return this.request('GET', `${environment.serverURL}/event`);
    // const url = `http://localhost:3306/api/getReportFormats`;
    let url = this.url_prefix + 'getCountryXByBank?';
    console.log('getFacsimileData');
    console.log(format)
    console.log(reportPeriod)
    console.log(bank)


    var queryParams = '';
    // if (format)
    //   url += 'format=' + format;

    if (reportPeriod)
      queryParams += 'reportPeriod=' + reportPeriod + '&';
    if (prevQtr)
      queryParams += 'prevQtr=' + prevQtr + '&';

    // if (bank) {
    //   queryParams += 'bank=' + bank + '&';
    // }
    if (bank) {
      queryParams += 'bank=';
      var bankStr = this.arrayToString(bank);
      queryParams += bankStr;
      // for (let i = 0; i < bank.length; i++) {
      //   queryParams += bank[i].replace(/,/g, ";") + ',';
      // }
      // queryParams = queryParams.substring(0, queryParams.length - 1)
      // queryParams += '&';
    }



    queryParams = queryParams.substring(0, queryParams.length - 1)
    url += queryParams;

    console.log('api call received, sending request..', url)

    return this.request('GET', url);

  }

  createBaseBankObj(reportRec: CXReport): CXReportStructured {
    const { bank, caseManager, cert, city, country, currCountryRiskCon, currCountryRiskExp, currTransferRiskCon, currTransferRiskExp, fieldOffice, holdingCompany, instClass, prevCountryRiskExp, prevTransferRiskExp, region, state, tier1Cap, totalAssets } = reportRec;

    return {
      fourthRowGroup: {
        bank,
        cert,
        city,
        holdingCompany,
        instClass,
        state,
        tier1Cap: this.formatNumber(tier1Cap),
        totalAssets: this.formatNumber(totalAssets)
      },
      fifthRow: {
        region,
        caseManager,
        fieldOffice,
      },
      sixthRowElement: [
        {
          country,
          currCountryRiskExp: this.formatNumber(currCountryRiskExp),
          prevCountryRiskExp: this.formatNumber(prevCountryRiskExp),
          currCountryRiskCon: `${currCountryRiskCon.toFixed(2)}%`,
          currTransferRiskExp: this.formatNumber(currTransferRiskExp),
          prevTransferRiskExp: this.formatNumber(prevTransferRiskExp),
          currTransferRiskCon: `${currTransferRiskCon.toFixed(2)}%`,
        }
      ],
      seventhRow: {
        subtotal: "Subtotal: ",
        currCountryRiskExpTotal: currCountryRiskExp,
        currTransferRiskExpTotal: currTransferRiskExp,
        prevCountryRiskExpTotal: prevCountryRiskExp,
        prevTransferRiskExpTotal: prevTransferRiskExp,
      }
    }

  }

  createBaseBankObjWithFilter(reportRec: CXReport): CXReportBankBase {
    const { bank, caseManager, cert, city, country, currCountryRiskCon, currCountryRiskExp, currTransferRiskCon, currTransferRiskExp, fieldOffice, holdingCompany, instClass, prevCountryRiskExp, prevTransferRiskExp, region, state, tier1Cap, totalAssets } = reportRec;

    return {
      fourthRowGroup: {
        bank,
        cert,
        city,
        holdingCompany,
        instClass,
        state,
        tier1Cap: this.formatNumber(tier1Cap),
        totalAssets: this.formatNumber(totalAssets)
      },
      caseMgrFieldOffRow: {
        region,
        caseManager,
        fieldOffice,
      },
      sixthRowElement: [
        {
          country,
          currCountryRiskExp: this.formatNumber(currCountryRiskExp),
          prevCountryRiskExp: this.formatNumber(prevCountryRiskExp),
          currCountryRiskCon: `${currCountryRiskCon.toFixed(2)}%`,
          currTransferRiskExp: this.formatNumber(currTransferRiskExp),
          prevTransferRiskExp: this.formatNumber(prevTransferRiskExp),
          currTransferRiskCon: `${currTransferRiskCon.toFixed(2)}%`
        }
      ],
    }
  }

  createBaseBankExpObj(reportRec: CXReport): CXReportBankBaseExp {
    const { bank, caseManager, cert, city, fieldOffice, holdingCompany, instClass, region, state, tier1Cap, totalAssets } = reportRec;

    const detailElement = this.createBankExpDetailElement(reportRec);

    return {
      caseMgrFieldOffRow: {
        caseManager,
        fieldOffice,
        region,
      },
      fourthRowGroup: {
        bank,
        cert,
        city,
        holdingCompany,
        instClass,
        state,
        tier1Cap: this.formatNumber(tier1Cap),
        totalAssets: this.formatNumber(totalAssets)
      },
      expDetailRowElement: [detailElement]
    }
  }

  createSixthRowElement(reportRec: CXReport): SixthRowElement {
    const { country, currCountryRiskCon, currCountryRiskExp, currTransferRiskCon, currTransferRiskExp, prevCountryRiskExp, prevTransferRiskExp, } = reportRec;
    return {
      country,
      currCountryRiskExp: this.formatNumber(currCountryRiskExp),
      prevCountryRiskExp: this.formatNumber(prevCountryRiskExp),
      currCountryRiskCon: `${currCountryRiskCon.toFixed(2)}%`,
      currTransferRiskExp: this.formatNumber(currTransferRiskExp),
      prevTransferRiskExp: this.formatNumber(prevTransferRiskExp),
      currTransferRiskCon: `${currTransferRiskCon.toFixed(2)}%`
    }
  }

  createBankExpDetailElement(reportRec: CXReport): ExpDetailRowElement {
    const { country, currCountryRiskExp, currTransferRiskExp, fcexc936, fcexm875, fcexm876, fcexm877, fcexm879, fcexm878 } = reportRec;

    return {
      country,
      countryRiskClaims: this.formatNumber(currCountryRiskExp),
      transferRiskClaims: this.formatNumber(currTransferRiskExp),
      unusedCommitments: this.formatNumber(fcexc936),
      guaranteesExCDS: this.formatNumber(fcexm875),
      cdsGrPrSold: this.formatNumber(fcexm877),
      cdsGrPrPurchased: this.formatNumber(fcexm876),
      cdsNetPrSold: this.formatNumber(fcexm879),
      cdsNetPrPurchased: this.formatNumber(fcexm878),
    }
  }

  createCountryExpDetailElement(reportRec: CXReport): ExpDetailRowElement {
    const { currCountryRiskExp, currTransferRiskExp, fcexc936, fcexm875, fcexm876, fcexm877, fcexm879, fcexm878 } = reportRec;

    return {
      country: '',
      countryRiskClaims: this.formatNumber(currCountryRiskExp),
      transferRiskClaims: this.formatNumber(currTransferRiskExp),
      unusedCommitments: this.formatNumber(fcexc936),
      guaranteesExCDS: this.formatNumber(fcexm875),
      cdsGrPrSold: this.formatNumber(fcexm877),
      cdsGrPrPurchased: this.formatNumber(fcexm876),
      cdsNetPrSold: this.formatNumber(fcexm879),
      cdsNetPrPurchased: this.formatNumber(fcexm878),
    }
  }

  createBaseCountryObj(reportRec: CXReport): CXReportCountryBase {
    const { bank, caseManager, cert, city, currCountryRiskCon, currCountryRiskExp, currTransferRiskCon, currTransferRiskExp, fieldOffice, holdingCompany, instClass, prevCountryRiskExp, prevTransferRiskExp, region, state, tier1Cap, totalAssets } = reportRec;

    return {
      regionCaseManagerFieldOfficeRow: {
        region,
        caseManager,
        fieldOffice,
      },
      fourthRowGroup: {
        bank,
        cert,
        city,
        holdingCompany,
        instClass,
        state,
        tier1Cap: this.formatNumber(tier1Cap),
        totalAssets: this.formatNumber(totalAssets)
      },
      sixthRowElement: [
        {
          country: "",
          currCountryRiskExp: this.formatNumber(currCountryRiskExp),
          prevCountryRiskExp: this.formatNumber(prevCountryRiskExp),
          currCountryRiskCon: `${currCountryRiskCon.toFixed(2)}%`,
          currTransferRiskExp: this.formatNumber(currTransferRiskExp),
          prevTransferRiskExp: this.formatNumber(prevTransferRiskExp),
          currTransferRiskCon: `${currTransferRiskCon.toFixed(2)}%`
        }
      ]
    }
  }

  createBaseCountryObjWithFilter(reportRec: CXReport): CXReportCountrySubBase {
    const { bank, caseManager, cert, city, currCountryRiskCon, currCountryRiskExp, currTransferRiskCon, currTransferRiskExp, fieldOffice, holdingCompany, instClass, prevCountryRiskExp, prevTransferRiskExp, region, state, tier1Cap, totalAssets } = reportRec;

    return {
      regionCaseManagerFieldOfficeRow: {
        region,
        caseManager,
        fieldOffice,
      },
      fourthRowGroup: {
        bank,
        cert,
        city,
        holdingCompany,
        instClass,
        state,
        tier1Cap: this.formatNumber(tier1Cap),
        totalAssets: this.formatNumber(totalAssets)
      },
      sixthRowElement: [
        {
          country: "",
          currCountryRiskExp: this.formatNumber(currCountryRiskExp),
          prevCountryRiskExp: this.formatNumber(prevCountryRiskExp),
          currCountryRiskCon: `${currCountryRiskCon.toFixed(2)}%`,
          currTransferRiskExp: this.formatNumber(currTransferRiskExp),
          prevTransferRiskExp: this.formatNumber(prevTransferRiskExp),
          currTransferRiskCon: `${currTransferRiskCon.toFixed(2)}%`
        }
      ]
    }
  }

  createBaseCountryExpObj(reportRec: CXReport): CXReportCountryBaseExp {
    const { bank, cert, city, holdingCompany, instClass, state, tier1Cap, totalAssets, region, caseManager, fieldOffice } = reportRec;

    const detailElement = this.createCountryExpDetailElement(reportRec);

    return {
      regionCaseManagerFieldOfficeRow: {
        region,
        caseManager,
        fieldOffice,
      },
      fourthRowGroup: {
        bank,
        cert,
        city,
        holdingCompany,
        instClass,
        state,
        tier1Cap: this.formatNumber(tier1Cap),
        totalAssets: this.formatNumber(totalAssets)
      },
      expDetailRowElement: [detailElement]
    }
  }

  createBaseCountryExpObjWithFilter(reportRec: CXReport): CXReportCountrySubBaseExp {
    const { bank, cert, city, holdingCompany, instClass, state, tier1Cap, totalAssets, caseManager, fieldOffice, region } = reportRec;

    const detailElement = this.createCountryExpDetailElement(reportRec);

    return {
      fourthRowGroup: {
        bank,
        cert,
        city,
        holdingCompany,
        instClass,
        state,
        tier1Cap: this.formatNumber(tier1Cap),
        totalAssets: this.formatNumber(totalAssets)
      },
      regionCaseManagerFieldOfficeRow: {
        caseManager,
        fieldOffice,
        region
      },
      expDetailRowElement: detailElement
    }
  }

  createInitialSubtotalRow(reportRec: CXReport): SubtotalRowElement {
    return {
      subtotal: 'Subtotal',
      currCountryRiskExpTotal: reportRec.currCountryRiskExp,
      prevCountryRiskExpTotal: reportRec.prevCountryRiskExp,
      currTransferRiskExpTotal: reportRec.currTransferRiskExp,
      prevTransferRiskExpTotal: reportRec.prevTransferRiskExp,
    };
  }

  createInitialSubtotalRowExp(reportRec: CXReport): SubTotalGroupExp {
    return {
      subTotal: 'Subtotal: ',
      subTotCountryRiskClaims: reportRec.currCountryRiskExp,
      subTotTransferRiskClaims: reportRec.currTransferRiskExp,
      subTotUnusedCommitments: reportRec.fcexc936,
      subTotGuaranteesExcludingCDS: reportRec.fcexm875,
      subTotCDSGrossProtectionSold: reportRec.fcexm877,
      subTotCDSGrossProtectionPurchased: reportRec.fcexm876,
      subTotCDSNetProtectionSold: reportRec.fcexm879,
      subTotCDSNetProtectionPurchased: reportRec.fcexm878,
    };
  }

  bankNoFilterAddToSubtotalRow(mainRec: CXReportStructured, reportRec: CXReport): void {
    mainRec.seventhRow.currCountryRiskExpTotal += reportRec.currCountryRiskExp;
    mainRec.seventhRow.currTransferRiskExpTotal += reportRec.currTransferRiskExp;
    mainRec.seventhRow.prevCountryRiskExpTotal += reportRec.prevCountryRiskExp;
    mainRec.seventhRow.prevTransferRiskExpTotal += reportRec.prevTransferRiskExp;
  }

  addToSubtotalRow(mainRec: CXReportCountry | CXReportCountryFiltered | CXReportBankFiltered, reportRec: CXReport): void {
    mainRec.subtotalRow.currCountryRiskExpTotal += reportRec.currCountryRiskExp;
    mainRec.subtotalRow.currTransferRiskExpTotal += reportRec.currTransferRiskExp;
    mainRec.subtotalRow.prevCountryRiskExpTotal += reportRec.prevCountryRiskExp;
    mainRec.subtotalRow.prevTransferRiskExpTotal += reportRec.prevTransferRiskExp;
  }

  addToSubtotalRowExp(mainRec: CXReportBankExp | CXReportBankFilteredExp | CXReportCountryFilteredExp, reportRec: CXReport): void {
    mainRec.subtotalRow.subTotCountryRiskClaims += reportRec.currCountryRiskExp;
    mainRec.subtotalRow.subTotTransferRiskClaims += reportRec.currTransferRiskExp;
    mainRec.subtotalRow.subTotUnusedCommitments += reportRec.fcexc936;
    mainRec.subtotalRow.subTotGuaranteesExcludingCDS += reportRec.fcexm875;
    mainRec.subtotalRow.subTotCDSGrossProtectionSold += reportRec.fcexm877;
    mainRec.subtotalRow.subTotCDSGrossProtectionPurchased += reportRec.fcexm876;
    mainRec.subtotalRow.subTotCDSNetProtectionSold += reportRec.fcexm879;
    mainRec.subtotalRow.subTotCDSNetProtectionPurchased += reportRec.fcexm878;
  }

  async generateLatestExposureReportFromFacsimile(reportingPeriod: string, prevQtr: string, facBank: string): Promise<void> {
    const reportFormat = ['By Bank'];

    const criteriaObj: ExposureSelectCriteria = {
      reportFormat,
      reportingPeriod: [reportingPeriod],
      bankBHCName: [facBank],
      region: ['ALL'],
      country: ['ALL'],
      caseManager: ['ALL'],
      fieldOffice: ['ALL'],
      concentration: ['0'],
      reportType: 'RegRpt'
    }
    const response: any = await this.getCountryXDataByBank(criteriaObj, prevQtr);
    const cxReportStructured = this.getTransformedCountryXDataByBank(response.data);
    this.setData(cxReportStructured);
  }
}