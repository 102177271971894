
import { MatTableDataSource } from '@angular/material/table';

import { People, people } from './sample-data';
import { CXReport } from './cxbank-data';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import { Component, OnInit, Output, Input, SimpleChange, EventEmitter, AfterViewInit, ViewChild, NgModule, SimpleChanges, OnChanges } from '@angular/core'; //added for input trial
import { ActivatedRoute } from '@angular/router';

export class Group {
  level: number = 0;
  parent: Group;
  expanded: boolean = true;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class TableBasicExample implements OnInit, OnChanges {

  reportPeriod: string;
  concentration: string;
  format: string;
  bank: string;
  region: string;
  country: string;
  caseManager: string;
  fieldOffice: string;

  cxReports: CXReport[];
  // loading: boolean = true;


  firstRowHeaders: any[] = [{ def: 'cert', name: 'CERT' }, { def: 'class', name: 'CLASS' }, { def: 'bank', name: 'BANK' }, { def: 'holdingCompany', name: 'HOLDING COMPANY' }, { def: 'city', name: 'CITY' }, { def: 'state', name: 'ST' }, { def: 'asset', name: 'TOTAL ASSETS' }, { def: 'tier1cap', name: 'TIER 1 CAP' }];

  // firstRowHeaders: string[] = ['CERT', 'CLASS', 'BANK', 'HOLDING COMPANY', 'CITY', 'ST', 'TOTAL ASSETS', 'TIER 1 CAP']
  // groupedRowColumns: string[] = ['cert', 'instClass', 'bank', 'holdingCompany', 'city', 'state', 'totalAssets', 'tier1Cap'];
  displayedColumns: string[] = ['country', 'currCountryRiskExp', 'prevCountryRiskExp', 'currCountryRiskCon', 'currTransferRiskExp', 'prevTransferRiskExp', 'currTransferRiskCon'];

  public dataSource = new MatTableDataSource<CXReport | Group>([]);
  public newDataSource = new MatTableDataSource<CXReport | Group>([]);

  groupByColumns: string[] = ['cert', 'instClass', 'bank', 'holdingCompany', 'city', 'state', 'totalAssets', 'tier1Cap', 'region', 'caseManager', 'fieldOffice'];// 'region', , 'caseManager', 'fieldOffice'

  // constructor(private apiService: CountryXApiService, public utils: UtilsService) {
  constructor(private cxApiService: CountryXApiService, private route: ActivatedRoute) {
    this.cxReports = this.cxApiService.getData();
    this.dataSource.data = this.addGroups(this.cxReports, this.groupByColumns);
    console.log('datasource data', this.dataSource.data);
    // copy the data returned from addGroups into a new Group
    let levelCheck = this.groupByColumns.length;
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i] instanceof Group) {

        console.log('Its a group', this.dataSource.data[i]);
        let groupVal = this.dataSource.data[i] as Group;

        console.log('groupVal.level', groupVal.level);
        if (groupVal.level !== levelCheck) {
          console.log('continue');
          continue;
        }
      }
      this.newDataSource.data.push(this.dataSource.data[i]);
    }
    console.log(this.newDataSource.data);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
  }

  customFilterPredicate(data: CXReport | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: CXReport): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) return false;

        let match = true;
        this.groupByColumns.forEach(
          column => {
            if (!row[column] || !data[column] || row[column] !== data[column]) match = false;
          }
        );
        return match;
      }
    );

    if (groupRows.length === 0) return true;
    if (groupRows.length > 1) throw "Data row is in more than one group!";
    const parent = <Group>groupRows[0];  // </Group> (Fix syntax coloring)

    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    // row.expanded = !row.expanded //  option to expand or collapse
    this.dataSource.filter = performance.now().toString();  // hack to trigger filter refresh
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    var rootGroup = new Group();
    var groupsAddGroup = this.getSublevel(data, 0, groupByColumns, rootGroup);
    return groupsAddGroup;
    // return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {

    // Recursive function, stop when there are no more levels. 
    if (level >= groupByColumns.length)
      return data;

    var groups = this.uniqueBy(
      data.map(
        row => {
          var result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (var i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);
    console.log('groups', groups);

    const currentColumn = groupByColumns[level];

    var subGroups = [];
    groups.forEach(group => {
      let rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn])
      let subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    })
    return subGroups;
  }

  uniqueBy(a, key) {
    var seen = {};
    return a.filter(function (item) {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.reportPeriod = params.reportPeriod;
        this.concentration = params.concentration;
        this.format = params.format;
        this.bank = params.bank;
        this.region = params.region;
        this.country = params.country;
        this.caseManager = params.caseManager;
        this.fieldOffice = params.fieldOffice;
      }
      );
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  exportExcel() {
    alert('Export as Excel');
  }

  exportPDF() {
    alert('Export as PDF');
  }
}

