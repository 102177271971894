import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { FourthRowElement, CXReport, CXReportAdapter } from 'src/app/models/report.model';


@Component({
  selector: 'cx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {



  cxReports: CXReport[] = [];


  // displayedColumnsForms: string[] = ['cert', 'class', 'bank', 'region', 'caseManager', 'fieldOffice', 'country', 'holdingCompany', 'city', 'state', 'totalAssets', 'tier1Cap']

  selectedReportFormat: string = 'By Bank';
  selectedReportingPeriod: string = '6/30/2020';
  // selectedBankBHCName: string[] = ['AMERANT BANK, NATIONAL ASSOCIATION'] ;
  // selectedRegion: string[] = ['ATLANTA'];
  // selectedCountry: string[] = ['VENEZUELA', 'JAPAN'];
  // selectedCaseManager: string = 'SERVAES, ANN';
  // selectedFieldOffice: string[] = ['SOUTH FLORIDA'];
  selectedConcentration: string = '5';

  filters: any[] = [{
    reportFormat: this.selectedReportFormat, reportingPeriod: this.selectedReportingPeriod, concentration: this.selectedConcentration
  }];

  // columnHeaders: any[] = [{ name: 'Cert' }, { name: 'Class' }, { name: 'Bank' }, { name: 'Region' }, { name: 'Case Manager' }, { name: 'Field Office' }, { name: 'Country' }, { name: 'Holding Company' }, { name: 'City' }, { name: 'State' }, { name: 'Total Assets' }, { name: 'Tier1 Cap' }]

  firstRowHeaders: any[] = [{ name: 'CERT' }, { name: 'CLASS' }, { name: 'BANK' }, { name: 'HOLDING COMPANY' }, { name: 'CITY' }, { name: 'ST' }, { name: 'TOTAL ASSETS' }, { name: 'TIER 1 CAP' }]

  // secondRowHeaders: any[] = [{ name: '' }, { name: '' }, { name: '' }, { name: 'COUNTRY RISK CLAIMS' }, { name: '' }, { name: '' }, { name: 'TRANSFER RISK CLAIMS' }, { name: '' }, { name: '' }]

  secondRowHeaders: any[] = [{ blank: '', countryRisk: 'COUNTRY RISK CLAIMS', transferRisk: 'TRANSFER RISK CLAIMS' }]

  currentQuarter = '6/30/2020';
  previousQuarter = '3/31/2020';
  expHeaderCurQtr = this.currentQuarter + ' $ EXP';
  expHeaderPrevQtr = this.previousQuarter + ' $ EXP';
  tier1Header = this.currentQuarter + ' TIER 1 CONCENTRATION';

  // thirdRowHeaders: any[] = [{ name: 'Country Name' }, { name: '' }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }];

  thirdRowHeaders: any[] = [{ countryName: 'COUNTRY NAME', blank: '', expHeaderCurQtr: this.expHeaderCurQtr, expHeaderPrevQtr: this.expHeaderPrevQtr, tier1Header: this.tier1Header }];

  fourthRowElements: FourthRowElement[] = [
    { cert: 22953, class: 'N', bank: 'AMERANT BANK, NATIONAL ASSOCIATION', holdingCompany: 'AMERANT BANCORP INC.', city: 'CORAL GABLES', state: 'FL', totalAssets: 8122, tier1Cap: 780 }
  ];

  regionHeader = 'REGION:  ';
  caseManagerHeader = 'CASE MANAGER:  ';
  fieldOfficeHeader = 'FIELD OFFICE:  ';
  regionElement = this.regionHeader + 'ATLANTA';
  caseManagerElement = this.caseManagerHeader + 'SERVAES, ANN';
  fieldOfficeElement = this.fieldOfficeHeader + 'SOUTH FLORIDA';

  // fifthRowHeaders: any[] = [
  //   { name: this.regionHeader }, { name: '' }, { name: this.caseManagerHeader }, { name: '' }, { name: this.fieldOfficeHeader }
  // ];

  // fifthRowHeaderElements: any[] = [
  //   { name: this.regionElement }, { name: this.caseManagerElement }, { name: this.fieldOfficeElement }
  // ];

  fifthRow = this.regionElement + ' ' + this.caseManagerElement + ' ' + this.fieldOfficeElement;

  currCountryRiskExp = 91;
  prevCountryRiskExp = 95;
  currCountryRiskCon = 11.66;
  currTransferRiskExp = 91;
  prevTransferRiskExp = 95;
  currTransferRiskCon = 11.66;

  // sixthRowElements: any[] = [
  //   { country: 'VENEZUELA', currCountryRiskExp: this.currCountryRiskExp, prevCountryRiskExp: this.prevCountryRiskExp, currCountryRiskCon: this.currCountryRiskCon, currTransferRiskExp: this.currTransferRiskExp, prevTransferRiskExp: this.prevTransferRiskExp, currTransferRiskCon: this.currTransferRiskCon },
  //   { country: 'JAPAN', currCountryRiskExp: 4093, prevCountryRiskExp: 4465, currCountryRiskCon: '21.35%', currTransferRiskExp: 545, prevTransferRiskExp: 823, currTransferRiskCon: '2.84%' }
  // ];

  sixthRowElement: any = { country: '', currCountryRiskExp: null, prevCountryRiskExp: null, currCountryRiskCon: null, currTransferRiskExp: null, prevTransferRiskExp: null, currTransferRiskCon: null };
  sixthRowElements: any[] = [];

  currCountryRiskExpSubTotal = 0;
  prevCountryRiskExpSubTotal = 0;
  currTransferRiskExpSubTotal = 0;
  prevTransferRiskExpSubTotal = 0;

  seventhRowElements: any[] = [];
  // seventhRowElements: any[] = [
  //   { subtotal: 'SUB TOTAL:', currCountryRiskExpSubTotal: 91 + 4093, prevCountryRiskExpSubTotal: 95 + 4465, currTransferRiskExpSubTotal: 91 + 545, prevTransferRiskExpSubTotal: 95 + 823, blank: ' ' }
  // ];



  constructor(
    private fb: FormBuilder,
    private apiService: CountryXApiService) {
  }


  private async getCountryXData() {
    console.log('getCountryXData...');
    // this.apiService.getCountryXDataByBank().subscribe((response: any) => {

    // this.cxReports = response.data.map((i: CXReport) => {
    // this.apiService.getCountryXDataByBank().then((response: any) => {
    //   response.data.map((i: CXReport) => {

    //     var cxReportObj: CXReport = { cert: null, instClass: '', bank: '', region: '', caseManager: '', fieldOffice: '', country: '', holdingCompany: '', city: '', state: '', totalAssets: null, tier1Cap: null, currCountryRiskExp: null, prevCountryRiskExp: null, currCountryRiskCon: null, currTransferRiskExp: null, prevTransferRiskExp: null, currTransferRiskCon: null };

    //     cxReportObj.cert = i.cert;
    //     cxReportObj.instClass = i.instClass;
    //     cxReportObj.bank = i.bank;
    //     cxReportObj.region = i.region;
    //     cxReportObj.caseManager = i.caseManager;
    //     cxReportObj.fieldOffice = i.fieldOffice;
    //     cxReportObj.country = i.country;
    //     cxReportObj.holdingCompany = i.holdingCompany;
    //     cxReportObj.city = i.city;
    //     cxReportObj.state = i.state;
    //     cxReportObj.totalAssets = i.totalAssets;
    //     cxReportObj.tier1Cap = i.tier1Cap;
    //     cxReportObj.currCountryRiskExp = i.currCountryRiskExp;
    //     cxReportObj.prevCountryRiskExp = i.prevCountryRiskExp;
    //     cxReportObj.currCountryRiskCon = i.currCountryRiskCon;
    //     cxReportObj.currTransferRiskExp = i.currTransferRiskExp;
    //     cxReportObj.prevTransferRiskExp = i.prevTransferRiskExp;
    //     cxReportObj.currTransferRiskCon = i.currTransferRiskCon;

    //     this.cxReports.push(cxReportObj);
    //     // console.log(this.cxReports);
    //   });
    // });

    // this.firstRowHeaders = [{ name: 'CERT' }, { name: 'CLASS' }, { name: 'BANK' }, { name: 'HOLDING COMPANY' }, { name: 'CITY' }, { name: 'ST' }, { name: 'TOTAL ASSETS' }, { name: 'TIER 1 CAP' }]

    // this.secondRowHeaders = [{ blank: '', countryRisk: 'COUNTRY RISK CLAIMS', transferRisk: 'TRANSFER RISK CLAIMS' }]

    // this.currentQuarter = '6/30/2020';
    // this.previousQuarter = '3/31/2020';
    // this.expHeaderCurQtr = this.currentQuarter + ' $ EXP';
    // this.expHeaderPrevQtr = this.previousQuarter + ' $ EXP';
    // this.tier1Header = this.currentQuarter + ' TIER 1 CONCENTRATION';

    // // thirdRowHeaders: any[] = [{ name: 'Country Name' }, { name: '' }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }];

    // this.thirdRowHeaders = [{ countryName: 'COUNTRY NAME', blank: '', expHeaderCurQtr: this.expHeaderCurQtr, expHeaderPrevQtr: this.expHeaderPrevQtr, tier1Header: this.tier1Header }];

    // this.fourthRowElements = [
    //   { cert: 22953, class: 'N', bank: 'AMERANT BANK, NATIONAL ASSOCIATION', holdingCompany: 'AMERANT BANCORP INC.', city: 'CORAL GABLES', state: 'FL', totalAssets: 8122, tier1Cap: 780 }
    // ];

    // this.regionHeader = 'REGION:  ';
    // this.caseManagerHeader = 'CASE MANAGER:  ';
    // this.fieldOfficeHeader = 'FIELD OFFICE:  ';
    // this.regionElement = this.regionHeader + 'ATLANTA';
    // this.caseManagerElement = this.caseManagerHeader + 'SERVAES, ANN';
    // this.fieldOfficeElement = this.fieldOfficeHeader + 'SOUTH FLORIDA';

    // this.fifthRow = this.regionElement + ' ' + this.caseManagerElement + ' ' + this.fieldOfficeElement;

    // this.currCountryRiskExp = 91;
    // this.prevCountryRiskExp = 95;
    // this.currCountryRiskCon = 11.66;
    // this.currTransferRiskExp = 91;
    // this.prevTransferRiskExp = 95;
    // this.currTransferRiskCon = 11.66;

    // this.sixthRowElement = { country: '', currCountryRiskExp: null, prevCountryRiskExp: null, currCountryRiskCon: null, currTransferRiskExp: null, prevTransferRiskExp: null, currTransferRiskCon: null };

    // this.currCountryRiskExpSubTotal = 91;
    // this.prevCountryRiskExpSubTotal = 95;
    // this.currTransferRiskExpSubTotal = 91;
    // this.prevTransferRiskExpSubTotal = 95;

    // this.seventhRowElements = [
    //   { subtotal: 'SUB TOTAL:', currCountryRiskExpSubTotal: 91 + 4093, prevCountryRiskExpSubTotal: 95 + 4465, currTransferRiskExpSubTotal: 91 + 545, prevTransferRiskExpSubTotal: 95 + 823, blank: ' ' }
    // ];


    // this.apiService.getCountryXDataByBank().subscribe((cxReport: CXReport[]) => {
    //   console.log('what does this look like?');
    //   console.log(cxReport);
    //   this.cxReport = cxReport;
    // });
  }
  ngOnInit(): void {
    console.log('table report on init...')

    // this.getCountryXData();
    this.cxReports = this.apiService.getData();
    console.log('is there data?', this.cxReports);
    if (this.cxReports !== undefined && this.cxReports !== null && this.cxReports !== [] && this.cxReports.length > 0) {

      console.log('cxReports has data, yay!!');

      for (let i = 0; i < 1; i++) {
        console.log(this.cxReports[i].bank); //use i instead of 0
        this.sixthRowElement.country = this.cxReports[0].country;
        this.sixthRowElement.currCountryRiskExp = this.cxReports[0].currCountryRiskExp;
        this.sixthRowElement.prevCountryRiskExp = this.cxReports[0].prevCountryRiskExp;
        this.sixthRowElement.currCountryRiskCon = this.cxReports[0].currCountryRiskCon;
        this.sixthRowElement.currTransferRiskExp = this.cxReports[0].currTransferRiskExp;
        this.sixthRowElement.prevTransferRiskExp = this.cxReports[0].prevTransferRiskExp;
        this.sixthRowElement.currTransferRiskCon = this.cxReports[0].currTransferRiskCon;
      }

      // this.sixthRowElement.country = this.cxReports[0].bank;
      // this.sixthRowElement.currCountryRiskExp = this.cxReports[0].currCountryRiskExp;
      // this.sixthRowElement.prevCountryRiskExp = this.cxReports[0].prevCountryRiskExp;
      // this.sixthRowElement.currCountryRiskCon = this.cxReports[0].currCountryRiskCon;
      // this.sixthRowElement.currTransferRiskExp = this.cxReports[0].currTransferRiskExp;
      // this.sixthRowElement.prevTransferRiskExp = this.cxReports[0].prevTransferRiskExp;
      // this.sixthRowElement.currTransferRiskCon = this.cxReports[0].currTransferRiskCon;

      this.sixthRowElements.push(this.sixthRowElement);
      console.log('sixthRowElements', this.sixthRowElements)
    } else {
      console.log('cxReports does not have  data, hmm..');
      this.sixthRowElement.country = this.cxReports[0].country;
      this.sixthRowElement.currCountryRiskExp = this.cxReports[0].currCountryRiskExp;
      this.sixthRowElement.prevCountryRiskExp = this.cxReports[0].prevCountryRiskExp;
      this.sixthRowElement.currCountryRiskCon = this.cxReports[0].currCountryRiskCon;
      this.sixthRowElement.currTransferRiskExp = this.cxReports[0].currTransferRiskExp;
      this.sixthRowElement.prevTransferRiskExp = this.cxReports[0].prevTransferRiskExp;
      this.sixthRowElement.currTransferRiskCon = this.cxReports[0].currTransferRiskCon;

      this.sixthRowElements.push(this.sixthRowElement);
      console.log('sixthRowElements', this.sixthRowElements)
    }

    this.firstRowHeaders = [{ name: 'CERT' }, { name: 'CLASS' }, { name: 'BANK' }, { name: 'HOLDING COMPANY' }, { name: 'CITY' }, { name: 'ST' }, { name: 'TOTAL ASSETS' }, { name: 'TIER 1 CAP' }]

    this.secondRowHeaders = [{ blank: '', countryRisk: 'COUNTRY RISK CLAIMS', transferRisk: 'TRANSFER RISK CLAIMS' }]

    this.currentQuarter = '6/30/2020';
    this.previousQuarter = '3/31/2020';
    this.expHeaderCurQtr = this.currentQuarter + ' $ EXP';
    this.expHeaderPrevQtr = this.previousQuarter + ' $ EXP';
    this.tier1Header = this.currentQuarter + ' TIER 1 CONCENTRATION';

    // thirdRowHeaders: any[] = [{ name: 'Country Name' }, { name: '' }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }];

    this.thirdRowHeaders = [{ countryName: 'COUNTRY NAME', blank: '', expHeaderCurQtr: this.expHeaderCurQtr, expHeaderPrevQtr: this.expHeaderPrevQtr, tier1Header: this.tier1Header }];

    this.fourthRowElements = [
      { cert: 22953, class: 'N', bank: 'AMERANT BANK, NATIONAL ASSOCIATION', holdingCompany: 'AMERANT BANCORP INC.', city: 'CORAL GABLES', state: 'FL', totalAssets: 8122, tier1Cap: 780 }
    ];

    this.regionHeader = 'REGION:  ';
    this.caseManagerHeader = 'CASE MANAGER:  ';
    this.fieldOfficeHeader = 'FIELD OFFICE:  ';
    this.regionElement = this.regionHeader + 'ATLANTA';
    this.caseManagerElement = this.caseManagerHeader + 'SERVAES, ANN';
    this.fieldOfficeElement = this.fieldOfficeHeader + 'SOUTH FLORIDA';

    this.fifthRow = this.regionElement + ' ' + this.caseManagerElement + ' ' + this.fieldOfficeElement;

    this.currCountryRiskExp = 91;
    this.prevCountryRiskExp = 95;
    this.currCountryRiskCon = 11.66;
    this.currTransferRiskExp = 91;
    this.prevTransferRiskExp = 95;
    this.currTransferRiskCon = 11.66;

    // this.sixthRowElement = { country: '', currCountryRiskExp: null, prevCountryRiskExp: null, currCountryRiskCon: null, currTransferRiskExp: null, prevTransferRiskExp: null, currTransferRiskCon: null };

    this.currCountryRiskExpSubTotal = 91;
    this.prevCountryRiskExpSubTotal = 95;
    this.currTransferRiskExpSubTotal = 91;
    this.prevTransferRiskExpSubTotal = 95;

    this.seventhRowElements = [
      { subtotal: 'SUB TOTAL:', currCountryRiskExpSubTotal: 91 + 4093, prevCountryRiskExpSubTotal: 95 + 4465, currTransferRiskExpSubTotal: 91 + 545, prevTransferRiskExpSubTotal: 95 + 823, blank: ' ' }
    ];
    // console.log('after getCountryXData()...');
    //   this.sixthRowElement.country = this.cxReports.pop().country;
    // this.sixthRowElement.currCountryRiskExp = this.cxReports[0].currCountryRiskExp;
    // this.sixthRowElement.prevCountryRiskExp = this.cxReports[0].prevCountryRiskExp;
    // this.sixthRowElement.currCountryRiskCon = this.cxReports[0].currCountryRiskCon;
    // this.sixthRowElement.currTransferRiskExp = this.cxReports[0].currTransferRiskExp;
    // this.sixthRowElement.prevTransferRiskExp = this.cxReports[0].prevTransferRiskExp;
    // this.sixthRowElement.currTransferRiskCon = this.cxReports[0].currTransferRiskCon;

    // this.sixthRowElements.push(this.sixthRowElement);
    // console.log(this.sixthRowElements)

    // this.fourToSevenRows.initialize();
    // this.findTable = this.fb.group({
    //   tableRows: this.fb.array([])
    // });

    // this.dataSource = new MatTableDataSource(this.data),
    //   this.dataSource.paginator = this.paginator;
    // console.log("FindTABLE", this.findTable);    
  }

  ngAfterViewInit() {

    // console.log(this.cxReports.pop());
    // console.log(this.cxReports[0].currCountryRiskExp);

    console.log('ngAfterViewInit doing nothing');
    // this.sixthRowElement.country = this.cxReports.pop().country;
    // this.sixthRowElement.currCountryRiskExp = this.cxReports[0].currCountryRiskExp;
    // this.sixthRowElement.prevCountryRiskExp = this.cxReports[0].prevCountryRiskExp;
    // this.sixthRowElement.currCountryRiskCon = this.cxReports[0].currCountryRiskCon;
    // this.sixthRowElement.currTransferRiskExp = this.cxReports[0].currTransferRiskExp;
    // this.sixthRowElement.prevTransferRiskExp = this.cxReports[0].prevTransferRiskExp;
    // this.sixthRowElement.currTransferRiskCon = this.cxReports[0].currTransferRiskCon;

    // this.sixthRowElements.push(this.sixthRowElement);
    // console.log(this.sixthRowElements)
  }

  ngOnChanges() {
    if (this.cxReports !== undefined && this.cxReports !== null && this.cxReports !== []) {
      console.log('cxReports has data, yay!!');
      this.sixthRowElement.country = this.cxReports[0].country;
      this.sixthRowElement.currCountryRiskExp = this.cxReports[0].currCountryRiskExp;
      this.sixthRowElement.prevCountryRiskExp = this.cxReports[0].prevCountryRiskExp;
      this.sixthRowElement.currCountryRiskCon = this.cxReports[0].currCountryRiskCon;
      this.sixthRowElement.currTransferRiskExp = this.cxReports[0].currTransferRiskExp;
      this.sixthRowElement.prevTransferRiskExp = this.cxReports[0].prevTransferRiskExp;
      this.sixthRowElement.currTransferRiskCon = this.cxReports[0].currTransferRiskCon;

      this.sixthRowElements.push(this.sixthRowElement);
      console.log(this.sixthRowElements)
    } else {
      console.log('cxReports does not have  data, hmm..');
      this.sixthRowElement.country = this.cxReports.pop().country;
      this.sixthRowElement.currCountryRiskExp = this.cxReports[0].currCountryRiskExp;
      this.sixthRowElement.prevCountryRiskExp = this.cxReports[0].prevCountryRiskExp;
      this.sixthRowElement.currCountryRiskCon = this.cxReports[0].currCountryRiskCon;
      this.sixthRowElement.currTransferRiskExp = this.cxReports[0].currTransferRiskExp;
      this.sixthRowElement.prevTransferRiskExp = this.cxReports[0].prevTransferRiskExp;
      this.sixthRowElement.currTransferRiskCon = this.cxReports[0].currTransferRiskCon;

      this.sixthRowElements.push(this.sixthRowElement);
      console.log(this.sixthRowElements)
    }

  }

  exportExcel() {
    alert('Export as Excel');
  }

  exportPDF() {
    alert('Export as PDF');
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  // }
}

