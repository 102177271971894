import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { UtilsService } from 'src/app/core/utils.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  loading: boolean = true;

  constructor(private router: Router, private apiService: ApiService, private authService: AuthService, public utils: UtilsService) {
    console.log('Sending request to authenticate user....');

    window.location.href = window.location.origin + '/api/countryx';
  }

  ngOnInit(): void {
    // window.location.href = window.location.origin + '/api/countryx';
  }

  login() {
    this.authService.validate()
      .then((response) => {
        this.authService.setUserInfo({ 'user': response['user'] });
        this.router.navigate(['home']);

      })
  }

  submit() {

    console.log('Routing from warning page on home landing page to main home page');
    this.router.navigate(['/countryxx']);

  }

}
