import { Component, OnInit, Renderer2, AfterViewInit, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from "src/app/services/api.service";
import { Router } from '@angular/router';
import { FacsimileService } from '../services/facsimile.service';
import { FacsimileSelectCriteria, FacsimileSelectData } from '../models/selectCriteria.model';
import { CXSelectService } from '../services/cxselect.service';
import { UtilsService } from '../core/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-facselect',
  templateUrl: './facselect.component.html',
  styleUrls: ['./facselect.component.scss']
})
export class FacSelectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  facSelectCriteriaForm: FormGroup;

  savedSearch: FacsimileSelectCriteria;

  dropdownData: FacsimileSelectData;

  previousQuarter: string;

  singleSelectDropdownSettings = {
    singleSelection: true,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };

  loading: boolean = true;

  validation_messages = {
    'reportFormatList': [
      { type: 'required', message: 'Please select Report Format' },
    ],
    'reportingPeriodList': [
      { type: 'required', message: 'Please select Reporting Period' },
    ],
    'bankBHCNameList': [
      { type: 'required', message: 'Please select Bank/BHC Name' },
    ],
  };

  constructor(private fb: FormBuilder, 
    private apiService: ApiService, 
    private router: Router, 
    private facsimileApiService: FacsimileService, 
    private selectService: CXSelectService, 
    public utils: UtilsService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.dropdownData = this.selectService.getFacsimileSelectData();
    this.savedSearch = this.selectService.getFacsimileSelectCriteria();
    this.createForms();
  }

  ngAfterViewInit() { }

  ngAfterViewChecked() {
    let reportingPeriod: Element = document.getElementById("reportingPeriod");

    if (reportingPeriod) {
      var reportingPeriodInput: Element = reportingPeriod.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (reportingPeriodInput) {
        this.renderer.setAttribute(reportingPeriodInput, 'title', reportingPeriod.getAttribute("title"));
      }
    }

    let reportFormat: Element = document.getElementById("reportFormat");

    if (reportFormat) {
      var reportFormatInput: Element = reportFormat.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (reportFormatInput) {
        this.renderer.setAttribute(reportFormatInput, 'title', reportFormat.getAttribute("title"));
      }
    }

    let bankBHCName: Element = document.getElementById("bankBHCName");

    if (bankBHCName) {
      var bankBHCNameInput: Element = bankBHCName.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (bankBHCNameInput) {
        this.renderer.setAttribute(bankBHCNameInput, 'title', bankBHCName.getAttribute("title"));
      }
    }
  }

  async createForms() {
    await this.getSelectCriteriaDropdownValues();
    // fac select form validations
    this.facSelectCriteriaForm = this.fb.group({
      reportFormat: new FormControl(this.savedSearch.reportFormat, Validators.required),
      reportingPeriod: new FormControl(this.savedSearch.reportingPeriod, Validators.required),
      bankBHCName: new FormControl(this.savedSearch.bankBHCName, Validators.required),
    });
    this.loading = false;
  }

  private async getSelectCriteriaDropdownValues() {
    // If no data already exists, get data  
    if (this.dropdownData.reportingPeriodList.length === 0) {
      console.log('getSelectCriteriaDropdownValues...');
      const periodsResult: any = await this.apiService.getReportingPeriods();
      this.dropdownData.reportingPeriodList = periodsResult.data.map((i) => i.reporting_period.substring(0, 10));
      if (this.savedSearch.reportingPeriod[0] === '') {
        this.savedSearch.reportingPeriod = [this.dropdownData.reportingPeriodList[0]];
      }
      // Load report type and banks based on latest reporting period
      await this.getRemainingDropdownData(this.savedSearch.reportingPeriod[0]);
      this.selectService.setFacsimileSelectData(this.dropdownData);
    }
  }

  async onReportingPeriodSelect(item: any) {
    this.loading = true;
    await this.getRemainingDropdownData(item);
    this.selectService.setFacsimileSelectData(this.dropdownData);
    const { reportFormat, bankBHCName } = this.facSelectCriteriaForm.value;
    // If previous form criteria doesn't exist in new data, set to default value.
    if (!this.checkCriteria(this.dropdownData.reportFormatList, reportFormat)) {
      this.facSelectCriteriaForm.patchValue({ reportFormat: [this.dropdownData.reportFormatList[0]] })
    }
    if (bankBHCName !== null) {
      if (!this.checkCriteria(this.dropdownData.bankBHCNameList, bankBHCName)) {
        this.facSelectCriteriaForm.patchValue({ bankBHCName: null })
      }
    }
    this.selectService.setFacsimileSelectCriteria(this.facSelectCriteriaForm.value);
    this.loading = false;
  }

  async getRemainingDropdownData(item: any) {
    const formatResult: any = await this.apiService.getReportFormatForFacsimile(item);
    this.dropdownData.reportFormatList = formatResult.data;

    const bankResult: any = await this.apiService.getBankBHCNamesForFacsimile(item);
    this.dropdownData.bankBHCNameList = bankResult.data.map(col => col.bank);
  }

  alertInvalidFormOnSubmit() {
    Swal.fire('There are errors on the form.', 'Please fix them before continuing.', 'error');
  }

  submit() {
    if (this.facSelectCriteriaForm.valid) {
      // call POST service, send selected values in POST body
      this.selectService.setFacsimileSelectCriteria(this.facSelectCriteriaForm.value);
      console.log('sending POST request to API Service...');
      const { reportFormat, reportingPeriod, bankBHCName } = this.facSelectCriteriaForm.value;
      const route = this.createRoute();
      this.router.navigate([`${route}`], { queryParams: { facFormat: reportFormat[0], facReportPeriod: reportingPeriod[0], facBank: bankBHCName[0] } });
    } else
      this.alertInvalidFormOnSubmit();
  }

  createRoute(): string {
    const { reportFormat, reportingPeriod } = this.facSelectCriteriaForm.value;
    if (this.facsimileApiService.isReportDateBefore2006(reportingPeriod[0])) {
      return '/form009_2005';
    }
    if (reportFormat[0].includes('Schedule 1') || reportFormat[0].includes('Schedule 1A') || reportFormat[0].includes('Schedule 2')) {
      return '/form009_2006';
    }
    return '/form009_2013';
  }

  checkCriteria(dropdownData: string[], currentSelection: string): boolean {
    return dropdownData.includes(currentSelection[0]);
  }

  async resetCriteria(): Promise<void> {
    // If reporting period changes on reset, get new dropdown data
    if (this.facSelectCriteriaForm.get('reportingPeriod').value[0] !== this.dropdownData.reportingPeriodList[0]) {
      this.loading = true;
      await this.onReportingPeriodSelect(this.dropdownData.reportingPeriodList[0]);
    }
    this.facSelectCriteriaForm.setValue({
      reportFormat: [this.dropdownData.reportFormatList[0]],
      reportingPeriod: [this.dropdownData.reportingPeriodList[0]],
      bankBHCName: null
    })
    this.selectService.setFacsimileSelectCriteria(this.facSelectCriteriaForm.value);
    this.loading = false;
  }

  getRecentSelect(item: any, criteria: string): void {
    if (item.length > 1) {
      const recentItem = item[item.length - 1];
      this.facSelectCriteriaForm.patchValue({
        [criteria]: [recentItem]
      })
    }
    if (item.length > 0 && criteria === 'reportingPeriod') {
      this.onReportingPeriodSelect(item[item.length - 1]);
    }
  }
}


