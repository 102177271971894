import { Component, OnInit } from '@angular/core';
import { CXReportStructured, SixthRowElement } from 'src/app/models/report.model';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ExcelExportService } from '../services/excel-export.service';
import { bankMainColumns, bankColumnsToDisplay, bankDisplayedColumnsForms, bankInnerDisplayedColumns, dataCategories } from '../common/bankTableData'
import { ByBankDynamicExcelData } from '../models/tableAndExcel.model';
import { ExposureSelectCriteria } from '../models/selectCriteria.model';


/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
  selector: 'cx-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BankComponent implements OnInit {

  criteriaObj: ExposureSelectCriteria = {
    reportFormat: [''],
    reportingPeriod: [],
    bankBHCName: [],
    region: [],
    country: [],
    caseManager: [],
    fieldOffice: [],
    concentration: [],
    reportType: ''
  };
  currentQtr: string;
  previousQuarter: string;

  loading: boolean = true;

  cxReportStructured: CXReportStructured[] = [];

  dataSource: MatTableDataSource<CXReportStructured>;
  sixthRowDataSource: MatTableDataSource<SixthRowElement>;

  displayedColumnsForms = bankDisplayedColumnsForms;
  mainColumns = bankMainColumns;
  columnsToDisplay = bankColumnsToDisplay;
  innerDisplayedColumns = bankInnerDisplayedColumns;

  expandedElement: CXReportStructured;

  secondRowHeaders = dataCategories;
  thirdRowHeaders: string[];
  url_prefix: any;

  constructor(private apiService: CountryXApiService, public utils: UtilsService, private route: ActivatedRoute, private excelExportService: ExcelExportService, private router: Router) { }

  ngOnInit(): void {
    this.setComponentData();
    this.getData();
    this.apiService.getCurrentQuarter().then((response: any) => {
      this.currentQtr = response.data[0].reportingPeriod;
    });
  }

  setComponentData(): void {
    const { bankBHCName, caseManager, concentration, country, fieldOffice, reportFormat, previousQuarter, region, reportingPeriod } =
      this.route.snapshot.queryParams;

    // Adds params as array if not array  
    this.criteriaObj.bankBHCName = typeof bankBHCName === 'string' ? [bankBHCName] : bankBHCName;
    this.criteriaObj.caseManager = typeof caseManager === 'string' ? [caseManager] : caseManager;
    this.criteriaObj.concentration = typeof concentration === 'string' ? [concentration] : concentration;
    this.criteriaObj.country = typeof country === 'string' ? [country] : country;
    this.criteriaObj.fieldOffice = typeof fieldOffice === 'string' ? [fieldOffice] : fieldOffice;
    this.criteriaObj.reportFormat = typeof reportFormat === 'string' ? [reportFormat] : reportFormat;
    this.criteriaObj.region = typeof region === 'string' ? [region] : region;
    this.criteriaObj.reportingPeriod = typeof reportingPeriod === 'string' ? [reportingPeriod] : reportingPeriod;
    this.previousQuarter = previousQuarter;
    this.thirdRowHeaders = ['Country Name', `${reportingPeriod} $Exp`, `${previousQuarter} $Exp`, `${reportingPeriod} Tier 1 Concentration`, `${reportingPeriod} $Exp`, `${previousQuarter} $Exp`, `${reportingPeriod} Tier 1 Concentration`,];
  }

  getData(): void {
    this.apiService.getCountryXDataByBank(this.criteriaObj, this.previousQuarter).then((response: any) => {
      this.loading = false;
      if (response.data.length > 0) {
        this.cxReportStructured = this.apiService.getTransformedCountryXDataByBank(response.data);
        this.dataSource = new MatTableDataSource(this.cxReportStructured);
      } else {
        this.router.navigate(['/nodata'], { queryParams: { format: this.criteriaObj.reportFormat[0], reportPeriod: this.criteriaObj.reportingPeriod[0], bank: this.criteriaObj.bankBHCName, previousQuarter: this.previousQuarter, region: this.criteriaObj.region, country: this.criteriaObj.country, caseManager: this.criteriaObj.caseManager, fieldOffice: this.criteriaObj.fieldOffice, concentration: this.criteriaObj.concentration[0] } });
      }
    });
  }

  exportExcel(): void {
    // Pass in dynamic data - static data handled in service
    const bankInfo = [];
    const regionData = [];
    const countryData = [];
    const subtotalRowData = [];

    this.cxReportStructured.forEach((bank) => {
      const { fourthRowGroup, fifthRow, sixthRowElement, seventhRow } = bank;
      const bankArr = this.columnsToDisplay.map((col) => {
        if (col === 'totalAssets' || col === 'tier1Cap') {
          return Number(fourthRowGroup[col].replace(/,/g, ''));
        }
        return fourthRowGroup[col];
      });
      bankInfo.push(bankArr);

      regionData.push([`Region: ${fifthRow.region}`, `Case Manager: ${fifthRow.caseManager}`, '', `Field Office: ${fifthRow.fieldOffice}`]);

      const sixthRowArray = sixthRowElement.map((elem) => {
        return this.innerDisplayedColumns.map((col) => {
          if (col === "currCountryRiskCon" || col === "currTransferRiskCon") {
            return Number(elem[col].slice(0, -1)) / 100;
          }
          if (col !== 'country') {
            return Number(elem[col].replace(/,/g, ''));
          }
          return elem[col];
        })
      });
      countryData.push(sixthRowArray);

      subtotalRowData.push(['Subtotal:', seventhRow.currCountryRiskExpTotal, seventhRow.prevCountryRiskExpTotal, '', seventhRow.currTransferRiskExpTotal, seventhRow.prevTransferRiskExpTotal, '']);
    })
    const info: ByBankDynamicExcelData = {
      tableHeader: `COUNTRY EXPOSURE >= ${this.criteriaObj.concentration}% OF TIER 1 CAPITAL - ${this.criteriaObj.reportingPeriod}`,
      format: this.criteriaObj.reportFormat[0],
      thirdRowHeaders: this.thirdRowHeaders,
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    };
    this.excelExportService.exportToExcel('bank', info);
  }

  async generateLatestFacsimileReport(certNumber: number, facBank: string) {
    const facFormat = 'Schedule C Part I';

    return this.router.navigate(['/form009_2013'], { queryParams: { facFormat, facReportPeriod: this.currentQtr, facBank }, queryParamsHandling: 'merge' });
  }
}

