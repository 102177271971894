import { Component, OnInit } from '@angular/core';
import { ExpDetailRowElement, CXReportCountryExp } from 'src/app/models/report.model';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { expBankColumnForms, expBankColumnHeaders, expMainColumns, expMainColumnsToDisplay, expSubtotalColumns, expSubtotals } from '../common/bankTableData';
import { ExcelExportService } from '../services/excel-export.service';
import { ByCountryExpDynamicExcelData } from '../models/tableAndExcel.model';
import { FacsimileService } from '../services/facsimile.service';
import { ExposureSelectCriteria } from '../models/selectCriteria.model';

@Component({
  selector: 'app-countryexp',
  templateUrl: './countryexp.component.html',
  styleUrls: ['./countryexp.component.scss']
})
export class CountryexpComponent implements OnInit {

  criteriaObj: ExposureSelectCriteria = {
    reportFormat: [''],
    reportingPeriod: [],
    bankBHCName: [],
    region: [],
    country: [],
    caseManager: [],
    fieldOffice: [],
    concentration: [],
    reportType: ''
  };

  currentQtr: string;
  previousQuarter: string;
  loading: boolean = true
  cxReportCountryExp: CXReportCountryExp[] = [];

  subtotals = expSubtotals;
  subtotalColumns = expSubtotalColumns;
  displayedColumnsForms = expBankColumnForms;
  headerColumns = expBankColumnHeaders;
  mainColumns = expMainColumns;
  columnsToDisplay = expMainColumnsToDisplay;

  dataSource: MatTableDataSource<CXReportCountryExp>;
  sixthRowDataSource: MatTableDataSource<ExpDetailRowElement>;

  expandedElement: CXReportCountryExp;

  constructor(private apiService: CountryXApiService, public utils: UtilsService, private route: ActivatedRoute, private excelExportService: ExcelExportService, private router: Router, private facsimileApiService: FacsimileService) { }

  ngOnInit() {
    this.setComponentData();
    this.getData();
    this.apiService.getCurrentQuarter().then((response: any) => {
      this.currentQtr = response.data[0].reportingPeriod;
    });
  }

  setComponentData(): void {
    const { bankBHCName, caseManager, concentration, country, fieldOffice, reportFormat, region, reportingPeriod, previousQuarter } =
      this.route.snapshot.queryParams;

    // Adds params as array if not array  
    this.criteriaObj.bankBHCName = typeof bankBHCName === 'string' ? [bankBHCName] : bankBHCName;
    this.criteriaObj.caseManager = typeof caseManager === 'string' ? [caseManager] : caseManager;
    this.criteriaObj.concentration = typeof concentration === 'string' ? [concentration] : concentration;
    this.criteriaObj.country = typeof country === 'string' ? [country] : country;
    this.criteriaObj.fieldOffice = typeof fieldOffice === 'string' ? [fieldOffice] : fieldOffice;
    this.criteriaObj.reportFormat = typeof reportFormat === 'string' ? [reportFormat] : reportFormat;
    this.criteriaObj.region = typeof region === 'string' ? [region] : region;
    this.criteriaObj.reportingPeriod = typeof reportingPeriod === 'string' ? [reportingPeriod] : reportingPeriod;
    this.previousQuarter = previousQuarter;
  }

  getData(): void {
    this.apiService.getCountryXDataByBank(this.criteriaObj, this.previousQuarter).then((response: any) => {
      this.loading = false;
      if (response.data.length > 0) {
        this.cxReportCountryExp = this.apiService.getTransformedCountryXDataByCountryExp(response.data);
        this.dataSource = new MatTableDataSource(this.cxReportCountryExp);
      } else {
        this.router.navigate(['/nodata'], { queryParams: { format: this.criteriaObj.reportFormat[0], reportPeriod: this.criteriaObj.reportingPeriod[0], bank: this.criteriaObj.bankBHCName, previousQuarter: this.previousQuarter, region: this.criteriaObj.region, country: this.criteriaObj.country, caseManager: this.criteriaObj.caseManager, fieldOffice: this.criteriaObj.fieldOffice, concentration: this.criteriaObj.concentration[0] } });
      }
    });
  }

  exportExcel(): void {
    // Pass in dynamic data - static data handled in service
    const countries = [];
    const regionData = [];
    const bankInfo = [];
    const countryData = [];
    const subtotalRowData = [];

    this.cxReportCountryExp.forEach((country) => {
      countries.push(country.countryGroupExp);
      const distinctRegionData = [];
      const distinctBankInfo = [];
      const distinctCountryData = []
      const { cxReportCountryBaseListExp, subtotalRow } = country;

      cxReportCountryBaseListExp.forEach((rec) => {
        const { fourthRowGroup, regionCaseManagerFieldOfficeRow, expDetailRowElement } = rec;
        distinctRegionData.push([`Region: ${regionCaseManagerFieldOfficeRow.region}`, '', `Case Manager: ${regionCaseManagerFieldOfficeRow.caseManager}`, '', `Field Office: ${regionCaseManagerFieldOfficeRow.fieldOffice}`]);

        const bankRowData = expBankColumnHeaders.map((col) => {
          if (col === 'totalAssets' || col === 'tier1Cap') {
            return Number(fourthRowGroup[col].replace(/,/g, ''));
          }
          return fourthRowGroup[col];
        });
        bankRowData.splice(4, 0, '')
        bankRowData.splice(1, 0, '');
        distinctBankInfo.push(bankRowData);

        const countryArr = expDetailRowElement.map((elem) => {
          return this.columnsToDisplay.map((col) => {
            if (col !== 'country') {
              return Number(elem[col].replace(/,/g, ''));
            }
            return elem[col]
          });
        });
        distinctCountryData.push(countryArr);
      });

      const excelSubtotalFields = expSubtotals.slice(1).map((col) => col.name);
      const subtotalRowArr = excelSubtotalFields.map((field) => subtotalRow[field]);
      subtotalRowArr.unshift('Subtotal:');
      subtotalRowData.push(subtotalRowArr);

      regionData.push(distinctRegionData);
      bankInfo.push(distinctBankInfo);
      countryData.push(distinctCountryData);
    })

    const info: ByCountryExpDynamicExcelData = {
      tableHeader: `COUNTRY EXPOSURE - FOREIGN CLAIMS >= ${this.criteriaObj.concentration}% OF TIER 1 CAPITAL - ${this.criteriaObj.reportingPeriod}`,
      format: this.criteriaObj.reportFormat[0],
      countries,
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    };
    this.excelExportService.exportToExcel('country-exp', info);
  }

  async generateLatestFacsimileReport(certNumber: number, facBank: string) {
    const facFormat = 'Schedule C Part I';

    return this.router.navigate(['/form009_2013'], { queryParams: { facFormat, facReportPeriod: this.currentQtr, facBank }, queryParamsHandling: 'merge' });
  }
}



