import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nodata-button',
  templateUrl: './nodata-button.component.html',
  styleUrls: ['./nodata-button.component.scss']
})
export class NodataButtonComponent implements OnInit {

  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickBackToSearch(event) {
    this.onClick.emit(event);
  }
}