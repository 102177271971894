import { Component, OnInit } from '@angular/core';
import { SixthRowElement, CXReportCountryCaseManager, CXReportCountryFieldOffice, CXReportStructured } from 'src/app/models/report.model';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { FacsimileService } from '../services/facsimile.service';

@Component({
  selector: 'app-form009-y2006',
  templateUrl: './form009-y2006.component.html',
  styleUrls: ['./form009-y2006.component.scss']
})
export class Form009Y2006Component implements OnInit {

  data: any;

  facReportPeriod: string;
  facBank: string;
  facFormat: string;
  cert: number;

  loading: boolean = true;

  cxReportCountryFieldOfficeList: CXReportCountryFieldOffice[] = [];

  displayedColumnsForms: string[] = ['cert', 'class', 'bank', 'region', 'caseManager', 'fieldOffice', 'country', 'holdingCompany', 'city', 'state', 'totalAssets', 'tier1Cap'];

  dataColumns: string[] = ['cntry_nm', 'cntry_cd', 'fcex8577', 'fcex8578', 'fcex8579', 'fcex8580', 'fcex8581', 'fcex8670', 'fcex8584', 'fcex8586', 'fcex8587', 'fcex8588', 'fcex8590', 'fcex8591', 'fcex8592', 'fcex8595', 'fcexa153', 'fcex8599', 'fcex8600', 'fcex8593', 'fcex8594', 'fcexa152', 'fcexa154'];

  subGroupColumns1: string[] = ['FCEX', '9151', '9207', 'C915', 'C916', 'C917', 'C918', 'C919', 'C920', 'C921', 'C922', 'C923', 'C924', 'C925', 'C926', 'C927', 'C928', 'C929', 'C930', 'C931', 'C932', 'C933', 'C934', 'C935', 'C936', 'C937'];

  subGroupColumns1A: string[] = ['FCEX', '9151', '9207', 'C938', 'C939', 'C940', '8595', 'A152', 'C941'];

  subGroupColumns2: string[] = ['FCEX', '9151', '9207', 'A334', 'A335', 'A336', 'A337', 'A338', 'A339', 'A340'];

  sixthRowDataSource: MatTableDataSource<SixthRowElement>;
  dataSource: MatTableDataSource<any>;



  mainColumns1: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexc915',
      display: '(1)'
    },
    {
      name: 'fcexc916',
      display: '(2)'
    },
    {
      name: 'fcexc917',
      display: '(3)'
    },
    {
      name: 'fcexc918',
      display: '(4)'
    },
    {
      name: 'fcexc919',
      display: '(5)'
    },
    {
      name: 'fcexc920',
      display: '(6)'
    },
    {
      name: 'fcexc921',
      display: '(7)'
    },
    {
      name: 'fcexc922',
      display: '(8)'
    },
    {
      name: 'fcexc923',
      display: '(9)'
    },
    {
      name: 'fcexc924',
      display: '(10)'
    },
    {
      name: 'fcexc925',
      display: '(11)'
    },
    {
      name: 'fcexc926',
      display: '(12)'
    },
    {
      name: 'fcexc927',
      display: '(13)'
    },
    {
      name: 'fcexc928',
      display: '(14)'
    },
    {
      name: 'fcexc929',
      display: '(15)'
    },
    {
      name: 'fcexc930',
      display: '(16)'
    },
    {
      name: 'fcexc931',
      display: '(17)'
    },
    {
      name: 'fcexc932',
      display: '(18)'
    },
    {
      name: 'fcexc933',
      display: '(19)'
    },
    {
      name: 'fcexc934',
      display: '(20)'
    },
    {
      name: 'fcexc935',
      display: '(21)'
    },
    {
      name: 'fcexc936',
      display: '(22)'
    },
    {
      name: 'fcexc937',
      display: '(23)'
    }
  ];


  columnsToDisplay1: any[] = this.mainColumns1.map(col => col.name);

  mainColumns1A: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexc938',
      display: '(1)'
    },
    {
      name: 'fcexc939',
      display: '(2)'
    },
    {
      name: 'fcexc940',
      display: '(3)'
    },
    {
      name: 'fcex8595',
      display: '(4)'
    },
    {
      name: 'fcexa152',
      display: '(5)'
    },
    {
      name: 'fcexc941',
      display: '(6)'
    }
  ];


  columnsToDisplay1A: any[] = this.mainColumns1A.map(col => col.name);

  mainColumns2: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexa334',
      display: '(1)'
    },
    {
      name: 'fcexa335',
      display: '(2)'
    },
    {
      name: 'fcexa336',
      display: '(3)'
    },
    {
      name: 'fcexa337',
      display: '(4)'
    },
    {
      name: 'fcexa338',
      display: '(5)'
    },
    {
      name: 'fcexa339',
      display: '(6)'
    },
    {
      name: 'fcexa340',
      display: '(7)'
    }
  ];

  columnsToDisplay2: any[] = this.mainColumns2.map(col => col.name);

  schedule1Title: string = 'Schedule 1:  Country Exposure Report (Excluding Foreign Exchange and Derivative Products)';
  schedule1ATitle: string = 'Schedule 1A:  Country Exposure Report - Foreign-Office Liabilities and Memorandum Items';
  schedule2Title: string = 'Schedule 2: Country Exposure Report - Foreign Exchange and Derivative Products';

  expandedElement: CXReportCountryFieldOffice;

  constructor(private apiService: FacsimileService, public utils: UtilsService, private route: ActivatedRoute, private router: Router, private cxApiService: CountryXApiService) { }

  ngOnInit(): void {
    const { facReportPeriod, facBank, facFormat } = this.route.snapshot.queryParams;
    this.facReportPeriod = facReportPeriod;
    this.facBank = facBank;
    this.facFormat = facFormat;
    this.getData();
  }

  getData(): void {
    this.apiService.getFacsimileData(this.facFormat, this.facReportPeriod, this.facBank).then((response: any) => {
      this.loading = false;
      if (response.data.length > 0) {
        this.data = this.sortBySchedule(response.data);
        this.cert = response.data[0].cert;
        this.dataSource = new MatTableDataSource(this.data);
      } else {
        console.log('Invalid data');
      }
    });
  }

  sortBySchedule(data: any[]): any[] {
    switch (this.facFormat) {
      case 'Schedule 1':
        return this.apiService.processFacsimileDataFor2006Schedule1(data);
      case 'Schedule 1A':
        return this.apiService.processFacsimileDataForSchedule1A(data);
      case 'Schedule 2':
        return this.apiService.processFacsimileDataForSchedule2(data);
      default:
        return [];
    }
  }

  isSchedule1() {
    if (this.facFormat === 'Schedule 1')
      return true;
    else return false;
  }

  isSchedule1A() {
    if (this.facFormat === 'Schedule 1A') {
      return true;
    }
    else return false;
  }

  isSchedule2() {
    if (this.facFormat === 'Schedule 2')
      return true;
    else return false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  async generateCountryExposureByBankReport() {
    const previousQuarter = this.cxApiService.calculatePreviousQuarter(this.facReportPeriod);

    return this.router.navigate(['/cxbybank'], { queryParams: { reportFormat: 'By Bank', reportingPeriod: this.facReportPeriod, bankBHCName: this.facBank, previousQuarter, concentration: '0' }, queryParamsHandling: 'merge' });
  }
}