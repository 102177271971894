import { Component, OnInit } from '@angular/core';
import { CXSelectService } from '../services/cxselect.service';
import { bankTableSubhead } from '../common/bankTableData'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-exposure-header',
  templateUrl: './exposure-header.component.html',
  styleUrls: ['./exposure-header.component.scss']
})
export class ExposureHeaderComponent implements OnInit {

  tableHeader: string;
  reportFormat: string;
  tableSubhead = bankTableSubhead;

  constructor(private route: ActivatedRoute, private selectService: CXSelectService) { }

  ngOnInit(): void {
    const { concentration, reportingPeriod, reportFormat } = this.route.snapshot.queryParams;
    this.reportFormat = reportFormat;
    const reportType = this.selectService.getExposureReportType();
    this.tableHeader = reportType === 'RegRpt'
      ? `COUNTRY EXPOSURE >= ${concentration}% OF TIER 1 CAPITAL - ${reportingPeriod}`
      : `COUNTRY EXPOSURE - FOREIGN CLAIMS >= ${concentration}% OF TIER 1 CAPITAL - ${reportingPeriod}`;
  }
}
