import { Component } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
  <div class="container-fluid"><img src="src/assets/loading.svg"></div>
  `,
  styles: [`
    :host {
      display: block;
    }
    img {
      display: block;
      margin: 20px auto;
      width: 400px;
      height: 400px;
    }
    .container-fluid {
      margin-top: 90px;
      padding-top: 140px;  
    }
  `]
})
export class LoadingComponent {
}