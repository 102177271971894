import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Schedule12005Report, Schedule12006Report, Schedule1AReport, Schedule2Report, ScheduleC1Report, ScheduleC2Report, ScheduleDReport, ScheduleLReport, ScheduleOReport } from '../models/report.model';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class FacsimileService {
  constructor(private http: HttpClient) { }

  private facsData: any = undefined;

  setData(cxData: any) {
    this.facsData = cxData;
  }

  getData(): any {
    return this.facsData;
  }


  private async request(method: string, url: string, data?: any) {
    //const token = await this.oktaAuth.getAccessToken();
    const result = this.http.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body'
    });
    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  url_prefix = API_URL + `api/`;
  // to run locally use const url = `${environment.serverURL}/getFundingSrcs`;

  formatNumber(numberValue) {
    if (numberValue.toString().toLowerCase().includes('subtotal'))
      return numberValue;
    return numberValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  getReportingPeriods() {
    let url = this.url_prefix + 'getReportingPeriods';
    return this.request('GET', url);
  };

  getReportFormatForFacsimile(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod)
      queryParams += '?reportPeriod=' + reportPeriod;

    let url = this.url_prefix + 'getReportFormatForFacsimile';
    url += queryParams;
    return this.request('GET', url);
  };

  getBankBHCNamesForFacsimile(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod)
      queryParams += '?reportPeriod=' + reportPeriod;
    let url = this.url_prefix + 'getBankBHCNamesForFacsimile';
    url += queryParams;
    return this.request('GET', url);
  };

  getQueryParams(reportFormat: string, reportPeriod: string, bankName: string, cert?: number, rssdId?: string) {

    var queryParams = '';
    if (reportPeriod && bankName) {
      queryParams += '?reportPeriod=' + reportPeriod + '&bank=' + bankName;
      if (cert)
        queryParams += '&cert=' + cert;
      if (rssdId)
        queryParams += '&rssdId=' + rssdId;
    }

    return queryParams;
  }

  getFacsimileData(reportFormat: string, reportPeriod: string, bankName: string, cert?: number, rssdId?: string) {
    var queryParams = this.getQueryParams(reportFormat, reportPeriod, bankName, cert, rssdId);
    let url;// = this.url_prefix;

    switch (reportFormat) {
      case 'Schedule 1':
        url = this.getFacsimileDataForSchedule1or2(reportFormat, reportPeriod, bankName);
        break;

      case 'Schedule 1A':
        url = this.getFacsimileDataForSchedule1or2(reportFormat, reportPeriod, bankName);
        break;

      case 'Schedule 2':
        url = this.getFacsimileDataForSchedule1or2(reportFormat, reportPeriod, bankName);
        break;

      case 'Schedule C Part I':
        url = this.getFacsimileDataForScheduleC1(reportFormat, reportPeriod, bankName);
        break;

      case 'Schedule C Part II':
        url = this.getFacsimileDataForScheduleC2(reportFormat, reportPeriod, bankName);
        break;

      case 'Schedule L':
        url = this.getFacsimileDataForScheduleL(reportFormat, reportPeriod, bankName);
        break;

      case 'Schedule O':
        url = this.getFacsimileDataForScheduleO(reportFormat, reportPeriod, bankName);
        break;

      case 'Schedule D':
        url = this.getFacsimileDataForScheduleD(reportFormat, reportPeriod, bankName);
        break;
    }
    url += queryParams;
    return this.request('GET', url);
  };

  getLatestFacsimileData(reportFormat: string, reportPeriod: string, bankName: string, cert: number, rssdId: string) {
    let processedFacsimileData;
    var queryParams = this.getQueryParams(reportFormat, reportPeriod, bankName, cert, rssdId);

    let url;// = this.url_prefix;

    url = this.getFacsimileDataForScheduleC1(reportFormat, reportPeriod, bankName);

    url += queryParams;
    return this.request('GET', url);
  };

  getFacsimileDataForSchedule1or2(reportFormat: string, reportPeriod: string, bankName: string) {
    var queryParams = '';
    let url = this.url_prefix;

    var sched1y2005: boolean = false;
    var sched1y2006: boolean = false;
    var sched2: boolean = false;
    var sched1A: boolean = false;


    if (reportPeriod && bankName)
      queryParams += '?reportPeriod=' + reportPeriod + '&bank=' + bankName;

    if (reportFormat === 'Schedule 1') {
      if (this.isReportDateBefore2006(reportPeriod)) {
        sched1y2005 = true;
        url += 'getFacsimileDataFor2005Schedule1';
      }
      else {
        sched1y2006 = true;
        url += 'getFacsimileDataFor2006Schedule1';
      }
    }
    if (reportFormat === 'Schedule 2') {
      sched2 = true;
      url += 'getFacsimileDataForSchedule2';
    }

    if (reportFormat === 'Schedule 1A') {
      sched1A = true;
      url += 'getFacsimileDataForSchedule1A';
    }
    console.log('getFacsimileDataForSchedule1or2', url);

    return url;
  };

  getFacsimileDataForScheduleC1(reportFormat: string, reportPeriod: string, bankName: string) {
    var queryParams = '';

    if (reportPeriod && bankName)
      queryParams += '?reportPeriod=' + reportPeriod + '&bank=' + bankName;

    let url = this.url_prefix;

    url += 'getFacsimileDataForScheduleC1';
    return url;
  };

  getFacsimileDataForScheduleC2(reportFormat: string, reportPeriod: string, bankName: string) {
    var queryParams = '';
    if (reportPeriod && bankName)
      queryParams += '?reportPeriod=' + reportPeriod + '&bank=' + bankName;

    let url = this.url_prefix;

    url += 'getFacsimileDataForScheduleC2';

    return url;
  };

  getFacsimileDataForScheduleL(reportFormat: string, reportPeriod: string, bankName: string) {
    var queryParams = '';
    if (reportPeriod && bankName)
      queryParams += '?reportPeriod=' + reportPeriod + '&bank=' + bankName;

    let url = this.url_prefix;

    url += 'getFacsimileDataForScheduleL';
    console.log('getFacsimileDataForScheduleL', url);

    return url;
  };

  getFacsimileDataForScheduleO(reportFormat: string, reportPeriod: string, bankName: string) {
    var queryParams = '';

    if (reportPeriod && bankName)
      queryParams += '?reportPeriod=' + reportPeriod + '&bank=' + bankName;

    let url = this.url_prefix;

    url += 'getFacsimileDataForScheduleO';

    return url;
  };

  getFacsimileDataForScheduleD(reportFormat: string, reportPeriod: string, bankName: string) {
    var queryParams = '';

    if (reportPeriod && bankName)
      queryParams += '?reportPeriod=' + reportPeriod + '&bank=' + bankName;

    let url = this.url_prefix;

    url += 'getFacsimileDataForScheduleD';
    return url;
  };

  isReportDateBefore2006(reportPeriod: string) {
    let selectedQtr = new Date(reportPeriod);

    if (selectedQtr.getFullYear() < 2006) {
      return true;
    } else return false;
  }

  processFacsimileDataFor2005Schedule1(originalSchedule1ReportData: Schedule12005Report[]) {

    let transformedSchedule1ReportData: Schedule12005Report[] = [];
    let areaIndList = [];

    for (var i = 0; i < originalSchedule1ReportData.length; i++) {

      let newDataRow: Schedule12005Report = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', d_dt: '', ent_nme: '', fcex8577: '', fcex8578: '', fcex8579: '', fcex8580: '', fcex8581: '', fcex8584: '', fcex8586: '', fcex8587: '', fcex8588: '', fcex8590: '', fcex8591: '', fcex8592: '', fcex8593: '', fcex8594: '', fcex8595: '', fcex8599: '', fcex8600: '', fcex8670: '', fcexa152: '', fcexa153: '', fcexa154: '', fcexc481: '', fed_rssd_id: '' };

      var currentAreaInd = originalSchedule1ReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            newDataRow.cntry_nm = '';
            break;

        }

        transformedSchedule1ReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', d_dt: '', ent_nme: '', fcex8577: '', fcex8578: '', fcex8579: '', fcex8580: '', fcex8581: '', fcex8584: '', fcex8586: '', fcex8587: '', fcex8588: '', fcex8590: '', fcex8591: '', fcex8592: '', fcex8593: '', fcex8594: '', fcex8595: '', fcex8599: '', fcex8600: '', fcex8670: '', fcexa152: '', fcexa153: '', fcexa154: '', fcexc481: '', fed_rssd_id: '' };
      }

      newDataRow = originalSchedule1ReportData[i];

      transformedSchedule1ReportData.push(newDataRow);
    }
    return transformedSchedule1ReportData;
  }

  processFacsimileDataFor2006Schedule1(originalSchedule1ReportData: Schedule12006Report[]) {

    let transformedSchedule1ReportData: Schedule12006Report[] = [];
    let areaIndList = [];

    for (var i = 0; i < originalSchedule1ReportData.length; i++) {

      let newDataRow: Schedule12006Report = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcex9151: '', fcex9207: '', fcexc915: '', fcexc916: '', fcexc917: '', fcexc918: '', fcexc919: '', fcexc920: '', fcexc921: '', fcexc922: '', fcexc923: '', fcexc924: '', fcexc925: '', fcexc926: '', fcexc927: '', fcexc928: '', fcexc929: '', fcexc930: '', fcexc931: '', fcexc932: '', fcexc933: '', fcexc934: '', fcexc935: '', fcexc936: '', fcexc937: '', fed_rssd_id: '' };

      var currentAreaInd = originalSchedule1ReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            newDataRow.cntry_nm = '';
            break;

        }

        transformedSchedule1ReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcex9151: '', fcex9207: '', fcexc915: '', fcexc916: '', fcexc917: '', fcexc918: '', fcexc919: '', fcexc920: '', fcexc921: '', fcexc922: '', fcexc923: '', fcexc924: '', fcexc925: '', fcexc926: '', fcexc927: '', fcexc928: '', fcexc929: '', fcexc930: '', fcexc931: '', fcexc932: '', fcexc933: '', fcexc934: '', fcexc935: '', fcexc936: '', fcexc937: '', fed_rssd_id: '' };


      }

      newDataRow = originalSchedule1ReportData[i];

      transformedSchedule1ReportData.push(newDataRow);

    }
    return transformedSchedule1ReportData;

  }

  processFacsimileDataForSchedule1A(originalSchedule1ReportData: Schedule1AReport[]) {

    let transformedSchedule1ReportData: Schedule1AReport[] = [];
    let areaIndList = [];
    for (var i = 0; i < originalSchedule1ReportData.length; i++) {

      let newDataRow: Schedule1AReport = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexc938: '', fcexc939: '', fcexc940: '', fcex8595: '', fcexa152: '', fcexc941: '', fcexc481: '', fed_rssd_id: '' };

      var currentAreaInd = originalSchedule1ReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            newDataRow.cntry_nm = '';
            break;

        }
        transformedSchedule1ReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexc938: '', fcexc939: '', fcexc940: '', fcex8595: '', fcexa152: '', fcexc941: '', fcexc481: '', fed_rssd_id: '' };


      }

      newDataRow = originalSchedule1ReportData[i];

      transformedSchedule1ReportData.push(newDataRow);

    }
    return transformedSchedule1ReportData;

  }

  processFacsimileDataForSchedule2(originalSchedule2ReportData: Schedule2Report[]) {

    let transformedSchedule2ReportData: Schedule2Report[] = [];
    let areaIndList = [];


    for (var i = 0; i < originalSchedule2ReportData.length; i++) {
      let newDataRow: Schedule2Report = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexa334: '', fcexa335: '', fcexa336: '', fcexa337: '', fcexa338: '', fcexa340: '', fcexa339: '', fcexc481: '', fed_rssd_id: '' };

      var currentAreaInd = originalSchedule2ReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            console.log('By Bank');
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            console.log('By Bank within Case Manager');
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            console.log('By Bank within Field Office');
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            console.log('By Country');
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            console.log('By Country within Case Manager');
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            console.log('By Country within Field Office');
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            console.log('By Country within Region');
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            console.log('By Country within Region');
            newDataRow.cntry_nm = '';
            break;

        }

        transformedSchedule2ReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexa334: '', fcexa335: '', fcexa336: '', fcexa337: '', fcexa338: '', fcexa340: '', fcexa339: '', fcexc481: '', fed_rssd_id: '' };
      }

      newDataRow = originalSchedule2ReportData[i];

      transformedSchedule2ReportData.push(newDataRow);

    }
    return transformedSchedule2ReportData;

  }

  processFacsimileDataForScheduleC1(originalScheduleC1ReportData: ScheduleC1Report[]) {

    let transformedScheduleC1ReportData: ScheduleC1Report[] = [];
    let areaIndList = [];

    for (var i = 0; i < originalScheduleC1ReportData.length; i++) {

      let newDataRow: ScheduleC1Report = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC915: '', fcexC916: '', fcexM851: '', fcexM852: '', fcexM853: '', fcexC918: '', fcexC919: '', fcexM854: '', fcexM855: '', fcexM856: '', fcexC921: '', fcexC922: '', fcexC923: '', fcexC924: '', fcexM857: '', fcexM858: '', fcexM859: '', fcexC926: '', fcexC927: '', fcexM860: '', fcexM861: '', fcexM862: '', fed_rssd_id: '' };

      var currentAreaInd = originalScheduleC1ReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            newDataRow.cntry_nm = '';
            break;
        }

        transformedScheduleC1ReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC915: '', fcexC916: '', fcexM851: '', fcexM852: '', fcexM853: '', fcexC918: '', fcexC919: '', fcexM854: '', fcexM855: '', fcexM856: '', fcexC921: '', fcexC922: '', fcexC923: '', fcexC924: '', fcexM857: '', fcexM858: '', fcexM859: '', fcexC926: '', fcexC927: '', fcexM860: '', fcexM861: '', fcexM862: '', fed_rssd_id: '' };
      }

      newDataRow = originalScheduleC1ReportData[i];
      transformedScheduleC1ReportData.push(newDataRow);
    }
    return transformedScheduleC1ReportData;
  }

  processFacsimileDataForScheduleC2(originalScheduleC2ReportData: ScheduleC2Report[]) {

    let transformedScheduleC2ReportData: ScheduleC2Report[] = [];
    let areaIndList = [];

    for (var i = 0; i < originalScheduleC2ReportData.length; i++) {
      let newDataRow: ScheduleC2Report = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC929: '', fcexC930: '', fcexM863: '', fcexM864: '', fcexM865: '', fcexC932: '', fcexC933: '', fcexM866: '', fcexM867: '', fcexM868: '', fcexC935: '', fcexM869: '', fcexM870: '', fcexM871: '', fcexM872: '', fcexM873: '', fcexM956: '', fcexM874: '', fed_rssd_id: '' };

      var currentAreaInd = originalScheduleC2ReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            console.log('By Bank');
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            console.log('By Bank within Case Manager');
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            console.log('By Bank within Field Office');
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            console.log('By Country');
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            console.log('By Country within Case Manager');
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            console.log('By Country within Field Office');
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            console.log('By Country within Region');
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            console.log('By Country within Region');
            newDataRow.cntry_nm = '';
            break;

        }
        transformedScheduleC2ReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC929: '', fcexC930: '', fcexM863: '', fcexM864: '', fcexM865: '', fcexC932: '', fcexC933: '', fcexM866: '', fcexM867: '', fcexM868: '', fcexC935: '', fcexM869: '', fcexM870: '', fcexM871: '', fcexM872: '', fcexM873: '', fcexM956: '', fcexM874: '', fed_rssd_id: '' };
      }

      newDataRow = originalScheduleC2ReportData[i];
      transformedScheduleC2ReportData.push(newDataRow);
    }
    return transformedScheduleC2ReportData;
  }

  processFacsimileDataForScheduleL(originalScheduleLReportData: ScheduleLReport[]) {
    let transformedScheduleLReportData: ScheduleLReport[] = [];
    let areaIndList = [];

    for (var i = 0; i < originalScheduleLReportData.length; i++) {
      let newDataRow: ScheduleLReport = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC938: '', fcexC939: '', fcexC940: '', fcex8595: '', fed_rssd_id: '' };

      var currentAreaInd = originalScheduleLReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            console.log('By Bank');
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            console.log('By Bank within Case Manager');
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            console.log('By Bank within Field Office');
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            console.log('By Country');
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            console.log('By Country within Case Manager');
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            console.log('By Country within Field Office');
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            console.log('By Country within Region');
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            console.log('By Country within Region');
            newDataRow.cntry_nm = '';
            break;
        }
        transformedScheduleLReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC938: '', fcexC939: '', fcexC940: '', fcex8595: '', fed_rssd_id: '' };
      }

      newDataRow = originalScheduleLReportData[i];
      transformedScheduleLReportData.push(newDataRow);
    }
    return transformedScheduleLReportData;
  }

  processFacsimileDataForScheduleO(originalScheduleOReportData: ScheduleOReport[]) {

    let transformedScheduleOReportData: ScheduleOReport[] = [];
    let areaIndList = [];
    for (var i = 0; i < originalScheduleOReportData.length; i++) {
      let newDataRow: ScheduleOReport = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC936: '', fcexM875: '', fcexM876: '', fcexM877: '', fcexM878: '', fcexM879: '', fcexC941: '', fed_rssd_id: '' };

      var currentAreaInd = originalScheduleOReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            console.log('By Bank');
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            console.log('By Bank within Case Manager');
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            console.log('By Bank within Field Office');
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            console.log('By Country');
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            console.log('By Country within Case Manager');
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            console.log('By Country within Field Office');
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            console.log('By Country within Region');
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            console.log('By Country within Region');
            newDataRow.cntry_nm = '';
            break;

        }
        transformedScheduleOReportData.push(newDataRow);
        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexC936: '', fcexM875: '', fcexM876: '', fcexM877: '', fcexM878: '', fcexM879: '', fcexC941: '', fed_rssd_id: '' };
      }

      newDataRow = originalScheduleOReportData[i];
      transformedScheduleOReportData.push(newDataRow);
    }
    return transformedScheduleOReportData;
  }

  processFacsimileDataForScheduleD(originalScheduleDReportData: ScheduleDReport[]) {

    let transformedScheduleDReportData: ScheduleDReport[] = [];
    let areaIndList = [];

    for (var i = 0; i < originalScheduleDReportData.length; i++) {
      let newDataRow: ScheduleDReport = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexA334: '', fcexA335: '', fcexM880: '', fcexA336: '', fcexA337: '', fcexA338: '', fed_rssd_id: '' };

      var currentAreaInd = originalScheduleDReportData[i].ctry_area_ind;
      if (areaIndList.indexOf(currentAreaInd) === -1) {
        areaIndList.push(currentAreaInd);

        switch (currentAreaInd.toString()) {
          case '1':
            console.log('By Bank');
            newDataRow.cntry_nm = 'EUROPE';
            break;

          case '3':
            console.log('By Bank within Case Manager');
            newDataRow.cntry_nm = 'LATIN AMERICA/CARIBBEAN';
            break;

          case '4':
            console.log('By Bank within Field Office');
            newDataRow.cntry_nm = 'ASIA/MIDDLE EAST';
            break;

          case '5':
            console.log('By Country');
            newDataRow.cntry_nm = 'AFRICA';
            break;

          case '6':
            console.log('By Country within Case Manager');
            newDataRow.cntry_nm = 'OTHER COUNTRIES';
            break;

          case '7':
            console.log('By Country within Field Office');
            newDataRow.cntry_nm = 'NORTH AMERICA';
            break;

          case '8':
            console.log('By Country within Region');
            newDataRow.cntry_nm = `INT'L & REGIONAL`;
            break;

          case '9':
            console.log('By Country within Region');
            newDataRow.cntry_nm = '';
            break;

        }
        transformedScheduleDReportData.push(newDataRow);

        newDataRow = { cntry_cd: '', cntry_nm: '', ctry_area_ind: '', ctry_tot_ind: '', ent_nme: '', fcexA334: '', fcexA335: '', fcexM880: '', fcexA336: '', fcexA337: '', fcexA338: '', fed_rssd_id: '' };
      }

      newDataRow = originalScheduleDReportData[i];
      transformedScheduleDReportData.push(newDataRow);
    }
    return transformedScheduleDReportData;
  }

  async generateLatestFacsimileReportFromExposure(certNumber: number, facBank: string, currentQtr: string, facFormat: string): Promise<void> {
    let rssdId = '';
    const response: any = await this.getLatestFacsimileData(facFormat, currentQtr, facBank, certNumber, rssdId);
    const processedFacsimileData = this.processFacsimileDataForScheduleC1(response.data);
    this.setData(processedFacsimileData);
  }
}