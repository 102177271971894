import { Component, OnInit, Renderer2, AfterViewInit, AfterViewChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from "src/app/services/api.service";
import { Router } from '@angular/router';
import { CXSelectService } from '../services/cxselect.service';
import { ExposureSelectCriteria, ExposureSelectData } from '../models/selectCriteria.model';
import Swal from 'sweetalert2';
import { UtilsService } from '../core/utils.service';

@Component({
  selector: 'app-cxselect-page',
  templateUrl: './cxselect.component.html',
  styleUrls: ['./cxselect.component.scss']
})
export class CxselectComponent implements OnInit, AfterViewInit, AfterViewChecked {

  cxselectCriteriaForm: FormGroup;

  savedSearch: ExposureSelectCriteria;

  dropdownData: ExposureSelectData;

  previousQuarter: string;

  bankBHCNumber: string;

  singleSelectDropdownSettings = {
    singleSelection: true,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };

  multiSelectDropdownSettings = {
    singleSelection: false,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    limitSelection: 5
  };

  loading: boolean = true;

  constructor(private fb: FormBuilder, 
    private apiService: ApiService, 
    private router: Router, 
    private selectService: 
    CXSelectService, 
    public utils: UtilsService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.dropdownData = this.selectService.getExposureSelectData();
    this.savedSearch = this.selectService.getExposureSelectCriteria();
    this.createForms();
  }

  ngAfterViewInit() { }

  ngAfterViewChecked() {
    let reportFormat: Element = document.getElementById("reportFormat");

    if (reportFormat) {
      var reportFormatInput: Element = reportFormat.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (reportFormatInput) {
        this.renderer.setAttribute(reportFormatInput, 'title', reportFormat.getAttribute("title"));
      }
    }

    let reportingPeriod: Element = document.getElementById("reportingPeriod");

    if (reportingPeriod) {
      var reportingPeriodInput: Element = reportingPeriod.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (reportingPeriodInput) {
        this.renderer.setAttribute(reportingPeriodInput, 'title', reportingPeriod.getAttribute("title"));
      }
    }

    let bankBHCName: Element = document.getElementById("bankBHCName");

    if (bankBHCName) {
      var bankBHCNameInput: Element = bankBHCName.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (bankBHCNameInput) {
        this.renderer.setAttribute(bankBHCNameInput, 'title', bankBHCName.getAttribute("title"));
      }
    }

    let region: Element = document.getElementById("region");

    if (region) {
      var regionInput: Element = region.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (regionInput) {
        this.renderer.setAttribute(regionInput, 'title', region.getAttribute("title"));
      }
    }

    let country: Element = document.getElementById("country");

    if (country) {
      var countryInput: Element = country.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (countryInput) {
        this.renderer.setAttribute(countryInput, 'title', country.getAttribute("title"));
      }
    }

    let caseManager: Element = document.getElementById("caseManager");

    if (caseManager) {
      var caseManagerInput: Element = caseManager.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (caseManagerInput) {
        this.renderer.setAttribute(caseManagerInput, 'title', caseManager.getAttribute("title"));
      }
    }

    let fieldOffice: Element = document.getElementById("fieldOffice");

    if (fieldOffice) {
      var fieldOfficeInput: Element = fieldOffice.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (fieldOfficeInput) {
        this.renderer.setAttribute(fieldOfficeInput, 'title', fieldOffice.getAttribute("title"));
      }
    }

    let concentration: Element = document.getElementById("concentration");

    if (concentration) {
      var concentrationInput: Element = concentration.getElementsByClassName("ng-input")[0].firstElementChild
  
      if (concentrationInput) {
        this.renderer.setAttribute(concentrationInput, 'title', concentration.getAttribute("title"));
      }
    }
  }

  async createForms() {
    await this.getSelectCriteriaDropdownValues();
    // cx select form validations
    this.cxselectCriteriaForm = this.fb.group({
      reportFormat: new FormControl(this.savedSearch.reportFormat, Validators.required),
      reportingPeriod: new FormControl(this.savedSearch.reportingPeriod, Validators.required),
      bankBHCName: new FormControl(this.savedSearch.bankBHCName, Validators.required),
      region: new FormControl(this.savedSearch.region, Validators.required),
      country: new FormControl(this.savedSearch.country, Validators.required),
      caseManager: new FormControl(this.savedSearch.caseManager, Validators.required),
      fieldOffice: new FormControl(this.savedSearch.fieldOffice, Validators.required),
      concentration: new FormControl(this.savedSearch.concentration, Validators.required),
    });
    this.loading = false;
  }

  private async getSelectCriteriaDropdownValues() {
    console.log('getSelectCriteriaDropdownValues...');
    // If no data already exists, get data
    if (this.dropdownData.reportingPeriodList.length === 0) {
      // Reporting formats, period and concentration never need re-run.
      const formatsResult: any = await this.apiService.getReportFormats();
      this.dropdownData.reportFormatList = formatsResult.data.map((i) => i.format);

      const periodsResult: any = await this.apiService.getReportingPeriods();
      this.dropdownData.reportingPeriodList = periodsResult.data.map((i) => i.reporting_period.substring(0, 10));
      if (this.savedSearch.reportingPeriod[0] === '') {
        this.savedSearch.reportingPeriod = [this.dropdownData.reportingPeriodList[0]];
      }

      const concentrationsResult: any = await this.apiService.getConcentration();
      this.dropdownData.concentrationList = concentrationsResult.data.map((i) => i.concentration);

      // Load rest of data based on latest reporting period
      await this.getRemainingDropdownData(this.savedSearch.reportingPeriod[0]);
      this.selectService.setExposureSelectData(this.dropdownData);
    }
    this.previousQuarter = this.calculatePreviousQuarter(this.savedSearch.reportingPeriod[0]);
  }

  async getRemainingDropdownData(item: any) {
    const [bankResult, caseManagersResult, countriesResult, regionsResult, fieldOfficesResult]: [any, any, any, any, any] = await Promise.all([<Promise<any>>this.apiService.getBankBHCNames(item), <Promise<any>>this.apiService.getCaseManagers(item), <Promise<any>>this.apiService.getCountries(item), <Promise<any>>this.apiService.getFDICRegions(item), <Promise<any>>this.apiService.getFieldOffices(item)]);

    this.dropdownData.bankBHCNameList = bankResult.data.map((i) => i.bank_bhc_name);
    this.dropdownData.caseManagerList = caseManagersResult.data.map((i) => i.case_manager);
    this.dropdownData.countryList = countriesResult.data.map((i) => i.country);
    this.dropdownData.regionList = regionsResult.data.map((i) => i.region);
    this.dropdownData.fieldOfficeList = fieldOfficesResult.data.map((i) => i.field_office);
  }

  async onReportingPeriodSelect(item: any) {
    // Checking that reporting period has a value. If the user just clicks an X, it won't have value and we don't want to run an update.
    if (item.length > 0) {
      const { reportingPeriod, bankBHCName, caseManager, country, fieldOffice, region } = this.cxselectCriteriaForm.value;
      this.loading = true;
      await this.getRemainingDropdownData(item);
      this.selectService.setExposureSelectData(this.dropdownData);
      this.previousQuarter = this.calculatePreviousQuarter(reportingPeriod[0])

      // If previous criteria doesn't exist in new data, set to default value.
      const checkedBanks = this.checkCriteria(this.dropdownData.bankBHCNameList, bankBHCName);
      this.cxselectCriteriaForm.patchValue({ bankBHCName: checkedBanks })

      const checkedCaseManagers = this.checkCriteria(this.dropdownData.caseManagerList, caseManager);
      this.cxselectCriteriaForm.patchValue({ caseManager: checkedCaseManagers })

      const checkedCountries = this.checkCriteria(this.dropdownData.countryList, country);
      this.cxselectCriteriaForm.patchValue({ country: checkedCountries })

      const checkedFieldOffices = this.checkCriteria(this.dropdownData.fieldOfficeList, fieldOffice);
      this.cxselectCriteriaForm.patchValue({ fieldOffice: checkedFieldOffices })

      const checkedRegions = this.checkCriteria(this.dropdownData.regionList, region);
      this.cxselectCriteriaForm.patchValue({ region: checkedRegions })

      const critObj: ExposureSelectCriteria = Object.assign({}, this.cxselectCriteriaForm.value);
      critObj.reportType = this.savedSearch.reportType;
      this.selectService.setExposureSelectCriteria(critObj);
      this.loading = false;
    }
  }

  calculatePreviousQuarter(reportPeriod: string) {
    let currQuart = new Date(reportPeriod);
    let prevQuart: string;
    let strM;
    let intD;
    let strY;

    let currentQuarter = (Math.floor((currQuart.getMonth() - 1) / 3)) + 1;
    var lastQuarter = (currentQuarter > 1) ? currentQuarter - 1 : currentQuarter = 4;

    strY = currQuart.getFullYear();

    switch (lastQuarter) {
      case 1: strM = '03'; intD = 31; break;
      case 2: strM = '06'; intD = 30; break;
      case 3: strM = '09'; intD = 30; break;
      case 4: strM = '12'; intD = 31; strY = strY - 1; break;
    }

    prevQuart = strY + "-" + strM + "-" + intD;
    return prevQuart;

  }

  getCheckBoxVal(val) {
    this.savedSearch.reportType = val;
  }

  alertInvalidFormOnSubmit() {
    Swal.fire('There are errors on the form.', 'Please fix them before continuing.', 'error');
  }

  submit() {
    if (this.cxselectCriteriaForm.valid) {
      const critObj: ExposureSelectCriteria = Object.assign({}, this.cxselectCriteriaForm.value);
      critObj.reportType = this.savedSearch.reportType;
      this.selectService.setExposureSelectCriteria(critObj);

      console.log('sending POST request to API Service...');

      const { reportFormat, reportingPeriod, bankBHCName, region, country, caseManager, fieldOffice, concentration } = this.cxselectCriteriaForm.value;

      this.previousQuarter = this.calculatePreviousQuarter(reportingPeriod);
      // Create filter type and navigation page base on reportFormat
      const filter = this.createFilter(reportFormat[0]);
      const route = this.createRoute(reportFormat[0]);

      return this.router.navigate([`${route}`], { queryParams: { reportFormat, reportingPeriod, bankBHCName, previousQuarter: this.previousQuarter, region, country, caseManager, fieldOffice, concentration, filter } });
    } else
      this.alertInvalidFormOnSubmit();
  }

  createRoute(format: string): string {
    if (format === "By Bank" && this.savedSearch.reportType === 'RegRpt') {
      return '/cxbybank';
    }
    if (format === "By Bank" && this.savedSearch.reportType === 'ExpndRpt') {
      return '/cxbybankexp';
    }
    if ((format === "By Bank within Case Manager" || format === "By Bank within Field Office" || format === "By Bank within Region") && (this.savedSearch.reportType === 'RegRpt')) {
      return '/cxbybankfilter';
    }
    if ((format === "By Bank within Case Manager" || format === "By Bank within Field Office" || format === "By Bank within Region") && (this.savedSearch.reportType === 'ExpndRpt')) {
      return '/cxbybankexpfilter';
    }
    if (format === "By Country" && this.savedSearch.reportType === 'RegRpt') {
      return '/cxbycountry';
    }
    if (format === "By Country" && this.savedSearch.reportType === 'ExpndRpt') {
      return '/cxbycountryexp';
    }
    if ((format === "By Country within Case Manager" || format === "By Country within Field Office" || format === "By Country within Region") && (this.savedSearch.reportType === 'RegRpt')) {
      return '/cxbycountryfilter';
    }
    if ((format === "By Country within Case Manager" || format === "By Country within Field Office" || format === "By Country within Region") && (this.savedSearch.reportType === 'ExpndRpt')) {
      return '/cxbycountryexpfilter';
    }
    return ''
  }

  createFilter(format: string): string {
    if (format.endsWith('Manager')) {
      return 'caseManager';
    }
    if (format.endsWith('Office')) {
      return 'fieldOffice';
    }
    if (format.endsWith('Region')) {
      return 'region';
    }
    return ''
  }

  checkCriteria(dropdownData: string[], currentSelection: string[]): string[] {
    const finalArray: string[] = [];
    currentSelection.forEach((item) => {
      if (dropdownData.includes(item)) {
        finalArray.push(item);
      }
    });
    // If array is empty, set to ALL
    if (finalArray.length === 0) {
      return ['ALL'];
    }
    return finalArray;
  }

  async resetCriteria(): Promise<void> {
    // If reporting period changes on reset, get new dropdown data
    if (this.cxselectCriteriaForm.get('reportingPeriod').value[0] !== this.dropdownData.reportingPeriodList[0]) {
      this.loading = true;
      await this.onReportingPeriodSelect(this.dropdownData.reportingPeriodList[0]);

      this.previousQuarter = this.previousQuarter = this.calculatePreviousQuarter(this.dropdownData.reportingPeriodList[0])
    }

    this.cxselectCriteriaForm.setValue({
      reportFormat: ['By Bank'],
      reportingPeriod: [this.dropdownData.reportingPeriodList[0]],
      bankBHCName: ['ALL'],
      region: ['ALL'],
      country: ['ALL'],
      caseManager: ['ALL'],
      fieldOffice: ['ALL'],
      concentration: ['5'],
    })
    this.savedSearch.reportType = 'RegRpt';

    const critObj: ExposureSelectCriteria = Object.assign({}, this.cxselectCriteriaForm.value);
    critObj.reportType = this.savedSearch.reportType;
    this.selectService.setExposureSelectCriteria(critObj);
    this.previousQuarter = this.previousQuarter = this.calculatePreviousQuarter(this.dropdownData.reportingPeriodList[0])
    this.loading = false;
  }

  createFilterType(format: string): string {
    if (format.endsWith('Manager')) {
      return 'caseManager';
    }
    if (format.endsWith('Office')) {
      return 'fieldOffice';
    }
    if (format.endsWith('Region')) {
      return 'region';
    }
    return ''
  }

  handleAllType(item: any, criteria: string): void {
    // If last added item is ALL, clear all other entries and leave ALL
    if (item.length > 0) {
      const lastItem = item[item.length - 1];
      if (lastItem === 'ALL') {
        this.cxselectCriteriaForm.patchValue({
          [criteria]: ['ALL']
        })
      } else {
        // If item is not ALL, check for ALL and remove if there.
        const allIdx = item.indexOf('ALL');
        if (allIdx > -1) {
          item.splice(allIdx, 1);
          this.cxselectCriteriaForm.patchValue({
            [criteria]: item
          })
        }
      }
    }
  }

  getRecentSelect(item: any, criteria: string): void {
    if (item.length > 1) {
      const recentItem = item[item.length - 1];
      this.cxselectCriteriaForm.patchValue({
        [criteria]: [recentItem]
      })
    }
    if (item.length > 0 && criteria === 'reportingPeriod') {
      this.onReportingPeriodSelect(item[item.length - 1]);
    }
  }
}


