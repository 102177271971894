// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // URL of development API
  // apiUrl: 'https://countryxcloud-d.fdic.gov/'
  apiUrl: window.location.origin + '/'

  // apiUrl: 'http://localhost:3306/'
};