import { Component, OnInit } from '@angular/core';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { FacsimileService } from '../services/facsimile.service';

@Component({
  selector: 'app-form009-y2013',
  templateUrl: './form009-y2013.component.html',
  styleUrls: ['./form009-y2013.component.scss']
})
export class Form009Y2013Component implements OnInit {

  data: any;

  facReportPeriod: string;
  facBank: string;
  facFormat: string;
  cert: number;

  loading: boolean = true;

  // 5 reports - Schedule C1, C2, L, O, D
  subGroupColumnsC1: string[] = ['FCEX', '9151', '9207', 'C915', 'C916', 'M851', 'M852', 'M853', 'C918', 'C919', 'M854', 'M855', 'M856', 'C921', 'C922', 'C923', 'C924', 'M857', 'M858', 'M859', 'C926', 'C927', 'M860', 'M861', 'M862'];

  subGroupColumnsC2: string[] = ['FCEX', '9151', '9207', 'C929', 'C930', 'M863', 'M864', 'M865', 'C932', 'C933', 'M866', 'M867', 'M868', 'C935', 'M869', 'M870', 'M871', 'M872', 'M873', 'M956', 'M874'];

  subGroupColumnsL: string[] = ['FCEX', '9151', '9207', 'C938', 'C939', 'C940', '8595'];

  subGroupColumnsO: string[] = ['FCEX', '9151', '9207', 'C936', 'M875', 'M876', 'M877', 'M878', 'M879', 'C941'];

  subGroupColumnsD: string[] = ['FCEX', '9151', '9207', 'A334', 'A335', 'M880', 'A336', 'A337', 'A338'];

  dataSource: MatTableDataSource<any>;

  subGroupsC1Map: any[] = [
    {
      name: 'g1',
      display: 'Banks'
    },
    {
      name: 'g2',
      display: 'Public'
    },
    {
      name: 'g3',
      display: 'NBFIs'
    },
    {
      name: 'g4',
      display: 'Corporate'
    },
    {
      name: 'g5',
      display: 'Households'
    },
    {
      name: 'g6',
      display: 'Banks'
    },
    {
      name: 'g7',
      display: 'Public'
    },
    {
      name: 'g8',
      display: 'NBFIs'
    },
    {
      name: 'g9',
      display: 'Corporate'
    },
    {
      name: 'g10',
      display: 'Households'
    },
    {
      name: 'g11',
      display: 'Remaining Maturity Up to and Including 1 Year'
    },
    {
      name: 'g12',
      display: 'Claims on Banks'
    },
    {
      name: 'g13',
      display: 'Claims on Public'
    },
    {
      name: 'g14',
      display: 'Claims on NBFIs'
    },
    {
      name: 'g15',
      display: 'Claims on Corporate'
    },
    {
      name: 'g16',
      display: 'Claims on Households'
    },
    {
      name: 'g17',
      display: 'Claims on Banks'
    },
    {
      name: 'g18',
      display: 'Claims on Public'
    },
    {
      name: 'g19',
      display: 'Claims on NBFIs'
    },
    {
      name: 'g20',
      display: 'Claims on Corporate'
    },
    {
      name: 'g21',
      display: 'Claims on Households'
    }
  ];

  subGroupsC1: any[] = this.subGroupsC1Map.map(col => col.name);

  subGroupsC2Map: any[] = [
    {
      name: 'g1',
      display: 'Banks'
    },
    {
      name: 'g2',
      display: 'Public'
    },
    {
      name: 'g3',
      display: 'NBFIs'
    },
    {
      name: 'g4',
      display: 'Corporate'
    },
    {
      name: 'g5',
      display: 'Households'
    },
    {
      name: 'g6',
      display: 'Banks'
    },
    {
      name: 'g7',
      display: 'Public'
    },
    {
      name: 'g8',
      display: 'NBFIs'
    },
    {
      name: 'g9',
      display: 'Corporate'
    },
    {
      name: 'g10',
      display: 'Households'
    },
    {
      name: 'g11',
      display: 'Breakdown of Total of Columns 6 through 10'
    },
    {
      name: 'g12',
      display: 'Breakdown of Total of Columns 1 through 10'
    },
    {
      name: 'g13',
      display: 'Total Collateral'
    },
    {
      name: 'g14',
      display: 'Of Which, Cash'
    },
    {
      name: 'g15',
      display: 'Of Which, Same Country'
    },
    {
      name: 'g16',
      display: 'Of Which, Resale and Reverse Repurchase Agreements and Securities Lending (Counterparty)'
    },
    {
      name: 'g17',
      display: 'Trading Assets (Reported in Columns 1 through 10)'
    },
    {
      name: 'g18',
      display: 'Offsetting Positions for Trading Book'
    }
  ];

  subGroupsC2: any[] = this.subGroupsC2Map.map(col => col.name);

  mainColumnsC1: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexC915',
      display: '(1)'
    },
    {
      name: 'fcexC916',
      display: '(2)'
    },
    {
      name: 'fcexM851',
      display: '(3)'
    },
    {
      name: 'fcexM852',
      display: '(4)'
    },
    {
      name: 'fcexM853',
      display: '(5)'
    },
    {
      name: 'fcexC918',
      display: '(6)'
    },
    {
      name: 'fcexC919',
      display: '(7)'
    },
    {
      name: 'fcexM854',
      display: '(8)'
    },
    {
      name: 'fcexM855',
      display: '(9)'
    },
    {
      name: 'fcexM856',
      display: '(10)'
    },
    {
      name: 'fcexC921',
      display: '(11)'
    },
    {
      name: 'fcexC922',
      display: '(12)'
    },
    {
      name: 'fcexC923',
      display: '(13)'
    },
    {
      name: 'fcexC924',
      display: '(14)'
    },
    {
      name: 'fcexM857',
      display: '(15)'
    },
    {
      name: 'fcexM858',
      display: '(16)'
    },
    {
      name: 'fcexM859',
      display: '(17)'
    },
    {
      name: 'fcexC926',
      display: '(18)'
    },
    {
      name: 'fcexC927',
      display: '(19)'
    },
    {
      name: 'fcexM860',
      display: '(20)'
    },
    {
      name: 'fcexM861',
      display: '(21)'
    },
    {
      name: 'fcexM862',
      display: '(22)'
    }
  ];

  columnsToDisplayC1: any[] = this.mainColumnsC1.map(col => col.name);

  mainColumnsC2: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexC929',
      display: '(1)'
    },
    {
      name: 'fcexC930',
      display: '(2)'
    },
    {
      name: 'fcexM863',
      display: '(3)'
    },
    {
      name: 'fcexM864',
      display: '(4)'
    },
    {
      name: 'fcexM865',
      display: '(5)'
    },
    {
      name: 'fcexC932',
      display: '(6)'
    },
    {
      name: 'fcexC933',
      display: '(7)'
    },
    {
      name: 'fcexM866',
      display: '(8)'
    },
    {
      name: 'fcexM867',
      display: '(9)'
    },
    {
      name: 'fcexM868',
      display: '(10)'
    },
    {
      name: 'fcexC935',
      display: '(11)'
    },
    {
      name: 'fcexM869',
      display: '(12)'
    },
    {
      name: 'fcexM870',
      display: '(13)'
    },
    {
      name: 'fcexM871',
      display: '(14)'
    },
    {
      name: 'fcexM872',
      display: '(15)'
    },
    {
      name: 'fcexM873',
      display: '(16)'
    },
    {
      name: 'fcexM956',
      display: '(17)'
    },
    {
      name: 'fcexM874',
      display: '(18)'
    }
  ];

  columnsToDisplayC2: any[] = this.mainColumnsC2.map(col => col.name);

  mainColumnsL: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexC938',
      display: '(1)'
    },
    {
      name: 'fcexC939',
      display: '(2)'
    },
    {
      name: 'fcexC940',
      display: '(3)'
    },
    {
      name: 'fcex8595',
      display: '(4)'
    }
  ];

  columnsToDisplayL: any[] = this.mainColumnsL.map(col => col.name);

  mainColumnsO: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexC936',
      display: '(1)'
    },
    {
      name: 'fcexM875',
      display: '(2)'
    },
    {
      name: 'fcexM876',
      display: '(3)'
    },
    {
      name: 'fcexM877',
      display: '(4)'
    },
    {
      name: 'fcexM878',
      display: '(5)'
    },
    {
      name: 'fcexM879',
      display: '(6)'
    },
    {
      name: 'fcexC941',
      display: '(7)'
    }
  ];

  columnsToDisplayO: any[] = this.mainColumnsO.map(col => col.name);

  mainColumnsD: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexA334',
      display: '(1)'
    },
    {
      name: 'fcexA335',
      display: '(2)'
    },
    {
      name: 'fcexM880',
      display: '(3)'
    },
    {
      name: 'fcexA336',
      display: '(4)'
    },
    {
      name: 'fcexA337',
      display: '(5)'
    },
    {
      name: 'fcexA338',
      display: '(6)'
    }
  ];

  columnsToDisplayD: any[] = this.mainColumnsD.map(col => col.name);

  //new ones
  scheduleC1Title: string = 'Schedule C, Part I: Claims on an Immediate Risk Basis';
  scheduleC2Title: string = 'Schedule C, Part II: Claims on an Ultimate Risk Basis and Memorandum Items';
  scheduleLTitle: string = 'Schedule L: Foreign-Office Liabilities';
  scheduleOTitle: string = 'Schedule O: Off-Balance-Sheet Items';
  scheduleDTitle: string = 'Schedule D: Claims from Positions in Derivative Contracts';

  constructor(private apiService: FacsimileService, public utils: UtilsService, private route: ActivatedRoute, private router: Router, private cxApiService: CountryXApiService) { }

  ngOnInit(): void {
    const { facReportPeriod, facBank, facFormat } = this.route.snapshot.queryParams;
    this.facReportPeriod = facReportPeriod;
    this.facBank = facBank;
    this.facFormat = facFormat;
    this.getData();
  }

  getData(): void {
    this.apiService.getFacsimileData(this.facFormat, this.facReportPeriod, this.facBank).then((response: any) => {
      this.loading = false;
      if (response.data.length > 0) {
        this.data = this.sortBySchedule(response.data);
        this.cert = response.data[0].cert;
        this.dataSource = new MatTableDataSource(this.data);
      } else {
        console.log('Invalid data');
      }
    });
  }

  sortBySchedule(data: any[]): any[] {
    switch (this.facFormat) {
      case 'Schedule C Part I':
        return this.apiService.processFacsimileDataForScheduleC1(data);
      case 'Schedule C Part II':
        return this.apiService.processFacsimileDataForScheduleC2(data);
      case 'Schedule L':
        return this.apiService.processFacsimileDataForScheduleL(data);
      case 'Schedule O':
        return this.apiService.processFacsimileDataForScheduleO(data);
      case 'Schedule D':
        return this.apiService.processFacsimileDataForScheduleD(data);
      default:
        return [];
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  // the legacy app generates the By Bank report only from the Facsimile report
  async generateCountryExposureByBankReport() {
    const previousQuarter = this.cxApiService.calculatePreviousQuarter(this.facReportPeriod);

    return this.router.navigate(['/cxbybank'], { queryParams: { reportFormat: 'By Bank', reportingPeriod: this.facReportPeriod, bankBHCName: this.facBank, previousQuarter, concentration: '0' }, queryParamsHandling: 'merge' });
  }
}