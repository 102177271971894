import { Component, OnInit } from '@angular/core';
import { ExpDetailRowElement, CXReportBankExp } from 'src/app/models/report.model';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { expSubtotals, expSubtotalColumns, expBankColumnForms, expBankColumnHeaders, expMainColumns, expMainColumnsToDisplay, bankTableSubhead } from '../common/bankTableData';
import { ByBankExpDynamicExcelData } from '../models/tableAndExcel.model';
import { ExcelExportService } from '../services/excel-export.service';
import { ExposureSelectCriteria } from '../models/selectCriteria.model';


@Component({
  selector: 'cx-bankexp',
  templateUrl: './bankexp.component.html',
  styleUrls: ['./bankexp.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BankexpComponent implements OnInit {

  criteriaObj: ExposureSelectCriteria = {
    reportFormat: [''],
    reportingPeriod: [],
    bankBHCName: [],
    region: [],
    country: [],
    caseManager: [],
    fieldOffice: [],
    concentration: [],
    reportType: ''
  };

  currentQtr: string;
  previousQuarter: string;
  loading: boolean = true;

  cxReportBankExp: CXReportBankExp[] = [];

  subtotals = expSubtotals;
  subtotalColumns = expSubtotalColumns;
  displayedColumnsForms = expBankColumnForms;
  headerColumns = expBankColumnHeaders;
  mainColumns = expMainColumns;
  columnsToDisplay = expMainColumnsToDisplay;

  dataSource: MatTableDataSource<CXReportBankExp>;
  sixthRowDataSource: MatTableDataSource<ExpDetailRowElement>;

  expandedElement: CXReportBankExp;

  constructor(private apiService: CountryXApiService, public utils: UtilsService, private route: ActivatedRoute, private excelExportService: ExcelExportService, private router: Router) { }

  ngOnInit() {
    this.setComponentData();
    this.getData();
    this.apiService.getCurrentQuarter().then((response: any) => {
      this.currentQtr = response.data[0].reportingPeriod;
    });
  }

  setComponentData(): void {
    const { bankBHCName, caseManager, concentration, country, fieldOffice, reportFormat, region, reportingPeriod, previousQuarter } =
      this.route.snapshot.queryParams;

    // Adds params as array if not array  
    this.criteriaObj.bankBHCName = typeof bankBHCName === 'string' ? [bankBHCName] : bankBHCName;
    this.criteriaObj.caseManager = typeof caseManager === 'string' ? [caseManager] : caseManager;
    this.criteriaObj.concentration = typeof concentration === 'string' ? [concentration] : concentration;
    this.criteriaObj.country = typeof country === 'string' ? [country] : country;
    this.criteriaObj.fieldOffice = typeof fieldOffice === 'string' ? [fieldOffice] : fieldOffice;
    this.criteriaObj.reportFormat = typeof reportFormat === 'string' ? [reportFormat] : reportFormat;
    this.criteriaObj.region = typeof region === 'string' ? [region] : region;
    this.criteriaObj.reportingPeriod = typeof reportingPeriod === 'string' ? [reportingPeriod] : reportingPeriod;
    this.previousQuarter = previousQuarter;
  }

  getData(): void {
    this.apiService.getCountryXDataByBank(this.criteriaObj, this.previousQuarter).then((response: any) => {
      this.loading = false;
      if (response.data.length > 0) {
        this.cxReportBankExp = this.apiService.getTransformedCountryXDataByBankExp(response.data);
        this.dataSource = new MatTableDataSource(this.cxReportBankExp);
      } else {
        this.router.navigate(['/nodata'], { queryParams: { format: this.criteriaObj.reportFormat[0], reportPeriod: this.criteriaObj.reportingPeriod[0], bank: this.criteriaObj.bankBHCName, previousQuarter: this.previousQuarter, region: this.criteriaObj.region, country: this.criteriaObj.country, caseManager: this.criteriaObj.caseManager, fieldOffice: this.criteriaObj.fieldOffice, concentration: this.criteriaObj.concentration[0] } });
      }
    });
  }

  exportExcel(): void {
    // Pass in dynamic data - static data handled in service
    const bankInfo = [];
    const regionData = [];
    const countryData = [];
    const subtotalRowData = [];

    this.cxReportBankExp.forEach((bank) => {
      const { cxReportBankBaseListExp: cxReportBankBaseListExp, subtotalRow } = bank;
      const { fourthRowGroup, caseMgrFieldOffRow, expDetailRowElement } = cxReportBankBaseListExp[0];
      const bankRowData = expBankColumnHeaders.map((col) => {
        if (col === 'totalAssets' || col === 'tier1Cap') {
          return Number(fourthRowGroup[col].replace(/,/g, ''));
        }
        return fourthRowGroup[col]
      });
      bankRowData.splice(4, 0, '')
      bankRowData.splice(1, 0, '');
      bankInfo.push(bankRowData);

      regionData.push([`Region: ${caseMgrFieldOffRow.region}`, '', `Case Manager: ${caseMgrFieldOffRow.caseManager}`, '', `Field Office: ${caseMgrFieldOffRow.fieldOffice}`]);

      const countryArr = expDetailRowElement.map((elem) => {
        return this.columnsToDisplay.map((col) => {
          if (col !== 'country') {
            return Number(elem[col].replace(/,/g, ''));
          }
          return elem[col]
        });
      });
      countryData.push(countryArr);

      const excelSubtotalFields = expSubtotals.slice(1).map((col) => col.name);
      const subtotalRowArr = excelSubtotalFields.map((field) => subtotalRow[field]);
      subtotalRowArr.unshift('Subtotal:');
      subtotalRowData.push(subtotalRowArr);
    });

    const info: ByBankExpDynamicExcelData = {
      tableHeader: `COUNTRY EXPOSURE - FOREIGN CLAIMS >= ${this.criteriaObj.concentration}% OF TIER 1 CAPITAL - ${this.criteriaObj.reportingPeriod}`,
      format: this.criteriaObj.reportFormat[0],
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    };
    this.excelExportService.exportToExcel('bank-exp', info);
  }

  async generateLatestFacsimileReport(certNumber: number, facBank: string) {
    const facFormat = 'Schedule C Part I';

    return this.router.navigate(['/form009_2013'], { queryParams: { facFormat, facReportPeriod: this.currentQtr, facBank }, queryParamsHandling: 'merge' });
  }
}
