import { Injectable } from '@angular/core';
import { ExposureSelectCriteria, ExposureSelectData, FacsimileSelectCriteria, FacsimileSelectData } from '../models/selectCriteria.model';

@Injectable({
  providedIn: 'root'
})

export class CXSelectService {

  exposureSelectCriteria: ExposureSelectCriteria = {
    reportFormat: ['By Bank'],
    reportingPeriod: [''],
    bankBHCName: ['ALL'],
    region: ['ALL'],
    country: ['ALL'],
    caseManager: ['ALL'],
    fieldOffice: ['ALL'],
    concentration: ['5'],
    reportType: 'RegRpt',
  }

  exposureSelectData: ExposureSelectData = {
    reportFormatList: [],
    reportingPeriodList: [],
    bankBHCNameList: [],
    regionList: [],
    countryList: [],
    caseManagerList: [],
    fieldOfficeList: [],
    concentrationList: [],
  }

  facsimileSelectCriteria: FacsimileSelectCriteria = {
    reportFormat: ['Schedule C Part I'],
    reportingPeriod: [''],
    bankBHCName: null
  }

  facsimileSelectData: FacsimileSelectData = {
    reportFormatList: [],
    reportingPeriodList: [],
    bankBHCNameList: []
  }

  getExposureSelectCriteria(): ExposureSelectCriteria {
    return this.exposureSelectCriteria;
  }

  setExposureSelectCriteria(critObj: ExposureSelectCriteria): void {
    this.exposureSelectCriteria = critObj;
  }

  getExposureSelectData(): ExposureSelectData {
    return this.exposureSelectData;
  }

  getExposureReportType(): string {
    return this.exposureSelectCriteria.reportType;
  }

  setExposureSelectData(dataObj: ExposureSelectData): void {
    this.exposureSelectData = dataObj;
  }

  getFacsimileSelectCriteria(): FacsimileSelectCriteria {
    return this.facsimileSelectCriteria;
  }

  setFacsimileSelectCriteria(critObj: FacsimileSelectCriteria): void {
    this.facsimileSelectCriteria = critObj;
  }

  getFacsimileSelectData(): FacsimileSelectData {
    return this.facsimileSelectData;
  }

  setFacsimileSelectData(dataObj: FacsimileSelectData): void {
    this.facsimileSelectData = dataObj;
  }
}