// import { Component, OnInit } from '@angular/core';
// import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Component, OnInit, Output, Input, SimpleChange, EventEmitter, AfterViewInit, ViewChild, NgModule, SimpleChanges, OnChanges } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { FourthRowElement, CXReport, CXReportStructured, CXReportAdapter } from 'src/app/models/report.model';
import { UtilsService } from 'src/app/core/utils.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-cxtable',
  templateUrl: './cxtable.component.html',
  styleUrls: ['./cxtable.component.scss']
})
export class CxtableComponent implements OnInit {

  reportPeriod: string;
  concentration: string;
  format: string;
  bank: string;
  region: string;
  country: string;
  caseManager: string;
  fieldOffice: string;

  loading: boolean = true;

  findTable: FormGroup;
  dataSource = new MatTableDataSource<any[]>();//not needed as of 11/24

  cxReport: CXReport;
  cxReports: CXReport[];

  cxReportStructured: CXReportStructured[] = [];


  // displayedColumnsForms: string[] = ['cert', 'class', 'bank', 'region', 'caseManager', 'fieldOffice', 'country', 'holdingCompany', 'city', 'state', 'totalAssets', 'tier1Cap']

  selectedReportFormat: string = 'By Bank';
  selectedReportingPeriod: string = '6/30/2020';
  // selectedBankBHCName: string[] = ['AMERANT BANK, NATIONAL ASSOCIATION'] ;
  // selectedRegion: string[] = ['ATLANTA'];
  // selectedCountry: string[] = ['VENEZUELA', 'JAPAN'];
  // selectedCaseManager: string = 'SERVAES, ANN';
  // selectedFieldOffice: string[] = ['SOUTH FLORIDA'];
  selectedConcentration: string = '5';

  filters: any[] = [{
    reportFormat: this.selectedReportFormat, reportingPeriod: this.selectedReportingPeriod, concentration: this.selectedConcentration
  }];

  // columnHeaders: any[] = [{ name: 'Cert' }, { name: 'Class' }, { name: 'Bank' }, { name: 'Region' }, { name: 'Case Manager' }, { name: 'Field Office' }, { name: 'Country' }, { name: 'Holding Company' }, { name: 'City' }, { name: 'State' }, { name: 'Total Assets' }, { name: 'Tier1 Cap' }]

  firstRowHeaders: any[] = [{ name: 'CERT' }, { name: 'CLASS' }, { name: 'BANK' }, { name: 'HOLDING COMPANY' }, { name: 'CITY' }, { name: 'ST' }, { name: 'TOTAL ASSETS' }, { name: 'TIER 1 CAP' }]

  // secondRowHeaders: any[] = [{ name: '' }, { name: '' }, { name: '' }, { name: 'COUNTRY RISK CLAIMS' }, { name: '' }, { name: '' }, { name: 'TRANSFER RISK CLAIMS' }, { name: '' }, { name: '' }]

  secondRowHeaders: any[] = [{ blank: '', countryRisk: 'COUNTRY RISK CLAIMS', transferRisk: 'TRANSFER RISK CLAIMS' }]

  currentQuarter = '6/30/2020';
  previousQuarter = '3/31/2020';
  expHeaderCurQtr = this.currentQuarter + ' $ EXP';
  expHeaderPrevQtr = this.previousQuarter + ' $ EXP';
  tier1Header = this.currentQuarter + ' TIER 1 CONCENTRATION';

  // thirdRowHeaders: any[] = [{ name: 'Country Name' }, { name: '' }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }, { name: this.expHeaderCurQtr }, { name: this.expHeaderPrevQtr }, { name: this.tier1Header }];

  thirdRowHeaders: any[] = [{ countryName: 'COUNTRY NAME', blank: '', expHeaderCurQtr: this.expHeaderCurQtr, expHeaderPrevQtr: this.expHeaderPrevQtr, tier1Header: this.tier1Header }];

  fourthRowElements: FourthRowElement[] = [
    { cert: 22953, class: 'N', bank: 'AMERANT BANK, NATIONAL ASSOCIATION', holdingCompany: 'AMERANT BANCORP INC.', city: 'CORAL GABLES', state: 'FL', totalAssets: 8122, tier1Cap: 780 }
  ];

  regionHeader = 'REGION:  ';
  caseManagerHeader = 'CASE MANAGER:  ';
  fieldOfficeHeader = 'FIELD OFFICE:  ';
  regionElement = this.regionHeader + 'ATLANTA';
  caseManagerElement = this.caseManagerHeader + 'SERVAES, ANN';
  fieldOfficeElement = this.fieldOfficeHeader + 'SOUTH FLORIDA';

  // fifthRowHeaders: any[] = [
  //   { name: this.regionHeader }, { name: '' }, { name: this.caseManagerHeader }, { name: '' }, { name: this.fieldOfficeHeader }
  // ];

  // fifthRowHeaderElements: any[] = [
  //   { name: this.regionElement }, { name: this.caseManagerElement }, { name: this.fieldOfficeElement }
  // ];

  fifthRow = this.regionElement + ' ' + this.caseManagerElement + ' ' + this.fieldOfficeElement;

  currCountryRiskExp = 91;
  prevCountryRiskExp = 95;
  currCountryRiskCon = 11.66;
  currTransferRiskExp = 91;
  prevTransferRiskExp = 95;
  currTransferRiskCon = 11.66;

  // sixthRowElements: any[] = [
  //   { country: 'VENEZUELA', currCountryRiskExp: this.currCountryRiskExp, prevCountryRiskExp: this.prevCountryRiskExp, currCountryRiskCon: this.currCountryRiskCon, currTransferRiskExp: this.currTransferRiskExp, prevTransferRiskExp: this.prevTransferRiskExp, currTransferRiskCon: this.currTransferRiskCon },
  //   { country: 'JAPAN', currCountryRiskExp: 4093, prevCountryRiskExp: 4465, currCountryRiskCon: '21.35%', currTransferRiskExp: 545, prevTransferRiskExp: 823, currTransferRiskCon: '2.84%' }
  // ];

  sixthRowElement: any = { country: '', currCountryRiskExp: null, prevCountryRiskExp: null, currCountryRiskCon: null, currTransferRiskExp: null, prevTransferRiskExp: null, currTransferRiskCon: null };
  sixthRowElements: any[] = [];

  currCountryRiskExpSubTotal = 0;
  prevCountryRiskExpSubTotal = 0;
  currTransferRiskExpSubTotal = 0;
  prevTransferRiskExpSubTotal = 0;

  seventhRowElements: any[] = [];
  // seventhRowElements: any[] = [
  //   { subtotal: 'SUB TOTAL:', currCountryRiskExpSubTotal: 91 + 4093, prevCountryRiskExpSubTotal: 95 + 4465, currTransferRiskExpSubTotal: 91 + 545, prevTransferRiskExpSubTotal: 95 + 823, blank: ' ' }
  // ];


  error: boolean;

  constructor(private apiService: CountryXApiService, public utils: UtilsService, private route: ActivatedRoute) {
    // this.loading = true;
    console.log('construct cxtable');
    this.cxReportStructured = this.apiService.getData();
    if (this.cxReportStructured !== undefined) {
      this.loading = false;
    }
    console.log('construct cxtable', this.cxReportStructured);


    // this.cxReports = this.apiService.getData();
    // if (this.cxReports !== undefined) {
    //   this.loading = false;
    // }
    // console.log('construct cxtable', this.cxReports);
  }


  private async getCountryXData() {
    this.loading = true;
    console.log('getCountryXData...');

    // instead of this
    // this.apiService.getCountryXDataByBank().subscribe((response: any) => {

    //   this.cxReports = response.data;
    //   this.loading = false;
    // },
    //   err => {
    //     console.error(err);
    //     this.loading = false;
    //     this.error = true;
    //   }
    // );

    //trying this
    // this.cxReportStructured = this.apiService.getCountryXDataByBankB();
    this.cxReportStructured = await this.apiService.getData();
    if (this.cxReportStructured !== undefined && this.cxReportStructured !== null && this.cxReportStructured !== []) {
      this.loading = false;
    }


    // this.apiService.getCountryXDataByBank().subscribe((response: any) => {

    //   // this.cxReports = response.data.map((i: CXReport) => {
    //   response.data.map((i: CXReport) => {

    //     var cxReportObj: CXReport = { cert: null, instClass: '', bank: '', region: '', caseManager: '', fieldOffice: '', country: '', holdingCompany: '', city: '', state: '', totalAssets: null, tier1Cap: null, currCountryRiskExp: null, prevCountryRiskExp: null, currCountryRiskCon: null, currTransferRiskExp: null, prevTransferRiskExp: null, currTransferRiskCon: null };

    //     cxReportObj.cert = i.cert;
    //     cxReportObj.instClass = i.instClass;
    //     cxReportObj.bank = i.bank;
    //     cxReportObj.region = i.region;
    //     cxReportObj.caseManager = i.caseManager;
    //     cxReportObj.fieldOffice = i.fieldOffice;
    //     cxReportObj.country = i.country;
    //     cxReportObj.holdingCompany = i.holdingCompany;
    //     cxReportObj.city = i.city;
    //     cxReportObj.state = i.state;
    //     cxReportObj.totalAssets = i.totalAssets;
    //     cxReportObj.tier1Cap = i.tier1Cap;
    //     cxReportObj.currCountryRiskExp = i.currCountryRiskExp;
    //     cxReportObj.prevCountryRiskExp = i.prevCountryRiskExp;
    //     cxReportObj.currCountryRiskCon = i.currCountryRiskCon;
    //     cxReportObj.currTransferRiskExp = i.currTransferRiskExp;
    //     cxReportObj.prevTransferRiskExp = i.prevTransferRiskExp;
    //     cxReportObj.currTransferRiskCon = i.currTransferRiskCon;

    //     // this.cxReports.push(cxReportObj);
    //     // console.log(this.cxReports);
    //   });
    //   this.loading = false;
    // },
    //   err => {
    //     console.error(err);
    //     this.loading = false;
    //     this.error = true;
    //   }
    // );


  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.reportPeriod = params.reportPeriod;
        this.concentration = params.concentration;
        this.format = params.format;
        this.bank = params.bank;
        this.region = params.region;
        this.country = params.country;
        this.caseManager = params.caseManager;
        this.fieldOffice = params.fieldOffice;
      }
      );

  }

  ngAfterViewInit() {
    if (this.cxReportStructured !== undefined) {
      this.loading = false;
    }
    console.log('ngAfterViewInit doing nothing');
  }

  // ngOnChanges() {
  //   if (this.cxReportStructured !== undefined && this.cxReportStructured !== null && this.cxReportStructured !== []) {
  //     this.loading = false;
  //   }
  //   // this.cxReports = this.apiService.getData();
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.cxReportStructured !== undefined) {
      this.loading = false;
    }
    // if (this.cxReports !== undefined && this.cxReports !== null && this.cxReports !== []) {
    //   this.dataSource = new MatTableDataSource(this.cxReports);
    //   // this.sortedData = this.data.slice();
    //   // this.dataSource.paginator = this.paginator;
    // } else {
    //   this.dataSource = new MatTableDataSource(this.cxReports);
    // }

  }

  exportExcel() {
    alert('Export as Excel');
  }

  exportPDF() {
    alert('Export as PDF');
  }


}
