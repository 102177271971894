import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html',
  styleUrls: ['./unauth.component.scss']
})
export class UnauthComponent implements OnInit {

  constructor() {
    window.location.href = window.location.origin + '/api/unauthorized';
  }

  ngOnInit(): void {
  }

}
