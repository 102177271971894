import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-excel-button',
  templateUrl: './excel-button.component.html',
  styleUrls: ['./excel-button.component.scss']
})
export class ExcelButtonComponent implements OnInit {

  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickExcel(event) {
    this.onClick.emit(event);
  }

}
