import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(private http: HttpClient) { }
  private async request(method: string, url: string, data?: any) {
    console.log(url);
    const result = this.http.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body'
    });
    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  url_prefix = API_URL + `api/`;

  getReportFormats() {
    let url = this.url_prefix + 'getReportFormats';
    return this.request('GET', url);
  }

  getFDICRegions(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod) {
      queryParams += '?reportPeriod=' + reportPeriod;
    }
    let url = this.url_prefix + 'getFDICRegions';
    url += queryParams;
    return this.request('GET', url);
  };

  getCountries(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod) {
      queryParams += '?reportPeriod=' + reportPeriod;
    }
    let url = this.url_prefix + 'getCountries';
    url += queryParams;
    return this.request('GET', url);
  };

  getFieldOffices(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod) {
      queryParams += '?reportPeriod=' + reportPeriod;
    }
    let url = this.url_prefix + 'getFieldOffices';
    url += queryParams;
    return this.request('GET', url);
  };

  getBankBHCNames(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod) {
      queryParams += '?reportPeriod=' + reportPeriod;
    }
    let url = this.url_prefix + 'getBankBHCNames';
    url += queryParams;
    return this.request('GET', url);
  };

  getCaseManagers(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod) {
      queryParams += '?reportPeriod=' + reportPeriod;
    }
    let url = this.url_prefix + 'getCaseManagers';
    url += queryParams;
    return this.request('GET', url);
  };

  getConcentration() {
    let url = this.url_prefix + 'getConcentration';
    return this.request('GET', url);
  };

  getReportingPeriods() {
    let url = this.url_prefix + 'getReportingPeriods';
    return this.request('GET', url);
  };

  getReportFormatForFacsimile(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod)
      queryParams += '?reportPeriod=' + reportPeriod;

    let url = this.url_prefix + 'getReportFormatForFacsimile';
    url += queryParams;
    return this.request('GET', url);
  };

  getBankBHCNamesForFacsimile(reportPeriod: string) {
    var queryParams = '';
    if (reportPeriod)
      queryParams += '?reportPeriod=' + reportPeriod;
    let url = this.url_prefix + 'getBankBHCNamesForFacsimile';
    url += queryParams;
    return this.request('GET', url);
  };

  getAuthenticatedUser() {
    var queryParams = '';
    console.log('getAuthenticatedUser is calling /authenticateUser')

    let url = this.url_prefix + 'authenticateUser';
    console.log(url);
    return this.request('GET', url);
  };

  getAuthenticatedUserCallback() {
    var queryParams = '';
    console.log('getAuthenticatedUser is calling /authenticateUserCallback')

    let url = this.url_prefix + 'countryx';
    console.log(url);
    return this.request('GET', url);
  };
}