import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CxselectComponent } from './cxselect/cxselect.component';
import { HelpComponent } from './help/help.component';
import { TableComponent } from './table/table.component';
import { TableBasicExample } from './basic/basic.component';
import { CallbackComponent } from './callback/callback.component';
import { CxtableComponent } from './cxtable/cxtable.component';
import { NodataComponent } from './nodata/nodata.component';
import { TableBasicExampleComponent } from './table-basic-example/table-basic-example.component';
import { BankComponent } from './bank/bank.component';
import { BankFilteredComponent } from './bank-filtered/bank-filtered.component';
import { BankexpComponent } from './bankexp/bankexp.component';
import { BankexpFilteredComponent } from './bankexp-filtered/bankexp-filtered.component';
import { CountryComponent } from './country/country.component';
import { CountryFilteredComponent } from './country-filtered/country-filtered.component';
import { CountryexpComponent } from './countryexp/countryexp.component';
import { CountryexpFilteredComponent } from './countryexp-filtered/countryexp-filtered.component';
import { FacSelectComponent } from './facselect/facselect.component';
import { Form009Y2005Component } from './form009-y2005/form009-y2005.component';
import { Form009Y2006Component } from './form009-y2006/form009-y2006.component';
import { Form009Y2013Component } from './form009-y2013/form009-y2013.component';
import { AuthComponent } from './auth/auth.component';
import { HomeWarnComponent } from './home-warn/home-warn.component';
import { UnauthComponent } from './unauth/unauth.component';
import { AuthGuard } from './services/auth.guard';
import { RootGuard } from './services/root.guard';

const routes: Routes = [
  { path: '', component: AuthComponent, canLoad: [RootGuard] }, //
  { path: 'CountryX', component: AuthComponent, canLoad: [RootGuard] },//added for keeping it consistent with legacy
  { path: 'unauthorized', component: UnauthComponent },
  { path: 'home', component: HomeWarnComponent, canActivate: [AuthGuard] },
  { path: 'countryx', component: CxselectComponent, canActivate: [AuthGuard] },// change path value
  { path: 'facsimile', component: FacSelectComponent, canActivate: [AuthGuard] },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
  { path: 'cxbybank', component: BankComponent, canActivate: [AuthGuard] },
  { path: 'cxbybankfilter', component: BankFilteredComponent, canActivate: [AuthGuard] },
  { path: 'cxbybankexp', component: BankexpComponent, canActivate: [AuthGuard] },
  { path: 'cxbybankexpfilter', component: BankexpFilteredComponent, canActivate: [AuthGuard] },
  { path: 'cxbycountry', component: CountryComponent, canActivate: [AuthGuard] },
  { path: 'cxbycountryfilter', component: CountryFilteredComponent, canActivate: [AuthGuard] },
  { path: 'cxbycountryexp', component: CountryexpComponent, canActivate: [AuthGuard] },
  { path: 'cxbycountryexpfilter', component: CountryexpFilteredComponent, canActivate: [AuthGuard] },
  { path: 'form009_2005', component: Form009Y2005Component, canActivate: [AuthGuard] },
  { path: 'form009_2006', component: Form009Y2006Component, canActivate: [AuthGuard] },
  { path: 'form009_2013', component: Form009Y2013Component, canActivate: [AuthGuard] },
  { path: 'cxbybanktable', component: TableComponent },
  { path: 'cxtable', component: CxtableComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'basic', component: TableBasicExample },
  { path: 'nodata', component: NodataComponent, canActivate: [AuthGuard] },
  { path: 'basict', component: TableBasicExampleComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RootGuard, AuthGuard]
})
export class AppRoutingModule { }
