import { Component, OnInit } from '@angular/core';
import { SixthRowElement, CXReportCountryCaseManager, CXReportCountryFieldOffice, CXReportStructured } from 'src/app/models/report.model';
import { CountryXApiService } from 'src/app/services/countryx.service';
import { UtilsService } from 'src/app/core/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { FacsimileService } from '../services/facsimile.service';

@Component({
  selector: 'app-form009-y2005',
  templateUrl: './form009-y2005.component.html',
  styleUrls: ['./form009-y2005.component.scss']
})
export class Form009Y2005Component implements OnInit {

  data: any;

  facReportPeriod: string;
  facBank: string;
  facFormat: string;
  cert: number;

  loading: boolean = true;

  cxReportCountryFieldOfficeList: CXReportCountryFieldOffice[] = [];

  displayedColumnsForms: string[] = ['cert', 'class', 'bank', 'region', 'caseManager', 'fieldOffice', 'country', 'holdingCompany', 'city', 'state', 'totalAssets', 'tier1Cap'];

  dataColumns: string[] = ['cntry_nm', 'cntry_cd', 'fcex8577', 'fcex8578', 'fcex8579', 'fcex8580', 'fcex8581', 'fcex8670', 'fcex8584', 'fcex8586', 'fcex8587', 'fcex8588', 'fcex8590', 'fcex8591', 'fcex8592', 'fcex8595', 'fcexa153', 'fcex8599', 'fcex8600', 'fcex8593', 'fcex8594', 'fcexa152', 'fcexa154'];

  // dataColumns: string[] = ['cntry_nm', 'cntry_cd', 'fcex8577', 'fcex8578']; //for testing header and data rows

  // dataSource: MatTableDataSource<CXReportCountryFieldOffice>;
  sixthRowDataSource: MatTableDataSource<SixthRowElement>;
  dataSource: MatTableDataSource<any>;

  topGroupColumns: any[] = [
    {
      name: 'group1',
      display: 'Cross Border Claims'
    },
    {
      name: 'groupBlank',
      display: '&nbsp;'
    },
    {
      name: 'group2',
      display: 'Memorandum Items'
    }
  ];

  mainColumns: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcex8577',
      display: '(1)'
    },
    {
      name: 'fcex8578',
      display: '(2)'
    },
    {
      name: 'fcex8579',
      display: '(3)'
    },
    {
      name: 'fcex8580',
      display: '(4)'
    },
    {
      name: 'fcex8581',
      display: '(5)'
    },
    {
      name: 'fcex8670',
      display: '(6)'
    },
    {
      name: 'fcex8584',
      display: '(7)'
    },
    {
      name: 'fcex8586',
      display: '(8)'
    },
    {
      name: 'fcex8587',
      display: '(9)'
    },
    {
      name: 'fcex8588',
      display: '(10)'
    },
    {
      name: 'fcex8590',
      display: '(11)'
    },
    {
      name: 'fcex8591',
      display: '(12)'
    },
    {
      name: 'fcex8592',
      display: '(13)'
    },
    {
      name: 'fcex8595',
      display: '(14)'
    },
    {
      name: 'fcexa153',
      display: '(15)'
    },
    {
      name: 'fcex8599',
      display: '(16)'
    },
    {
      name: 'fcex8600',
      display: '(17)'
    },
    {
      name: 'fcex8593',
      display: '(18)'
    },
    {
      name: 'fcex8594',
      display: '(19)'
    },
    {
      name: 'fcexa152',
      display: '(20)'
    },
    {
      name: 'fcexa154',
      display: '(21)'
    }
  ];


  columnsToDisplay: any[] = this.mainColumns.map(col => col.name);

  mainColumns2: any[] = [
    {
      name: 'cntry_nm',
      display: 'Country(a)'
    },
    {
      name: 'cntry_cd',
      display: 'Code(b)'
    },
    {
      name: 'fcexa334',
      display: '(1)'
    },
    {
      name: 'fcexa335',
      display: '(2)'
    },
    {
      name: 'fcexa336',
      display: '(3)'
    },
    {
      name: 'fcexa337',
      display: '(4)'
    },
    {
      name: 'fcexa338',
      display: '(5)'
    },
    {
      name: 'fcexa339',
      display: '(6)'
    },
    {
      name: 'fcexa340',
      display: '(7)'
    }
  ];

  columnsToDisplay2: any[] = this.mainColumns2.map(col => col.name);

  schedule1Title: string = 'Schedule 1:  Country Exposure Report (Excluding Foreign Exchange and Derivative Products)';
  schedule2Title: string = 'Schedule 2: Country Exposure Report - Foreign Exchange and Derivative Products)';


  expandedElement: CXReportCountryFieldOffice;

  constructor(private apiService: FacsimileService, public utils: UtilsService, private route: ActivatedRoute, private router: Router, private cxApiService: CountryXApiService) { }

  ngOnInit(): void {
    const { facReportPeriod, facBank, facFormat } = this.route.snapshot.queryParams;
    this.facReportPeriod = facReportPeriod;
    this.facBank = facBank;
    this.facFormat = facFormat;
    this.getData();
  }

  getData(): void {
    this.apiService.getFacsimileData(this.facFormat, this.facReportPeriod, this.facBank).then((response: any) => {
      this.loading = false;
      if (response.data.length > 0) {
        this.data = this.sortBySchedule(response.data);
        this.cert = response.data[0].cert;
        this.dataSource = new MatTableDataSource(this.data);
      } else {
        console.log('Invalid data');
      }
    });
  }

  sortBySchedule(data: any[]): any[] {
    switch (this.facFormat) {
      case 'Schedule 1':
        return this.apiService.processFacsimileDataFor2005Schedule1(data);
      case 'Schedule 2':
        return this.apiService.processFacsimileDataForSchedule2(data);
      default:
        return [];
    }
  }

  isSchedule1() {
    if (this.facFormat.toString() === 'Schedule 1')
      return true;
    else return false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  async generateCountryExposureByBankReport() {
    const previousQuarter = this.cxApiService.calculatePreviousQuarter(this.facReportPeriod);

    return this.router.navigate(['/cxbybank'], { queryParams: { reportFormat: 'By Bank', reportingPeriod: this.facReportPeriod, bankBHCName: this.facBank, previousQuarter, concentration: '0' }, queryParamsHandling: 'merge' });
  }

}