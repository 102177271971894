import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { User, LoginResponse } from '../models/user.interface';
// import { User } from '../interfaces/user.interface';
// import { LocalStorageService } from './local-storage.service';


// interface LoginResponse {
//   accessToken: string;
//   refreshToken: string;
//   user: User;
// }

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn: Subject<boolean>;
  // user$ = new BehaviorSubject(null);
  userInfo: string;

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService) { }

  // login(form: { username: string; password: string }): Observable<LoginResponse> {
  //   // return this.http.post<LoginResponse>(`${environment.apiUrl}/login`, form)
  //   return this.http.get<LoginResponse>(`${environment.apiUrl}/authenticateUserCallback`)
  //     .pipe(
  //       tap(response => {
  //         this.user$.next(response.user);
  //         this.setToken('token', response.accessToken);
  //         this.setToken('refreshToken', response.refreshToken);
  //       })
  //     );
  // }

  public isAuthenticated(): Boolean {// need to work on this logic - TEMPORARY

    // console.log('Check if user is authenticated..')
    let userData = localStorage.getItem('userInfo')

    if (userData && JSON.parse(userData)) {
      console.log('isAuthenticated() userData: ', userData)
      return true;
    }
    // console.log('User is not authenticated')
    return false;
  }

  public setUserInfo(userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  public removeUserInfo() {
    localStorage.removeItem('userInfo');
    console.log('Check if user info is removed:: ', localStorage.getItem('userInfo'))
  }

  public validate() {
    console.log('Validate in authService')
    return this.http.get(`${environment.apiUrl} + 'api/countryx'`, { withCredentials: true }).toPromise()
  }

  public getLoggedInUserID() {
    this.http.get(environment.apiUrl + 'api/getLoggedInUserID').subscribe((resp: any) => {
      this.setUserInfo(resp.data);

    }, (errorResp) => {
      console.log('Oops, something went wrong getting the logged in status')
    })
  }

  // public isLoggedIn() {
  //   console.log('IsLoggedIn in authService')
  //   this.http.get(environment.apiUrl + '/api/countryx', {
  //     withCredentials: true
  //   }).subscribe((resp: any) => {
  //     this.loggedIn.next(resp.loggedIn);
  //   }, (errorResp) => {
  //     console.log('Oops, something went wrong getting the logged in status')
  //   })
  // }

  // validate or login ???
  // login(): Observable<LoginResponse> {
  //   // return this.http.post<LoginResponse>(`${environment.apiUrl}/login`, form)
  //   return this.http.get<LoginResponse>(`${environment.apiUrl}api/countryx`)
  //     .pipe(
  //       tap(response => {
  //         console.log('Login Response: ', response)
  //         this.setUserInfo(response.user)
  //         this.user$.next(response.user);
  //         this.setToken('token', response.accessToken);
  //         this.setToken('refreshToken', response.refreshToken);
  //       })
  //     );


  // }

  logout() {

    window.location.href = window.location.origin + '/api/logoutUser';
  }

  reset() {
    this.http.get(environment.apiUrl + 'api/reset');
  }

  // logout(): void {
  //   this.localStorageService.removeItem('token');
  //   this.localStorageService.removeItem('refreshToken');
  //   this.user$.next(null);
  // }

  // getCurrentUser(): Observable<User> {
  //   return this.user$.pipe(
  //     switchMap(user => {
  //       // check if we already have user data
  //       if (user) {
  //         return of(user);
  //       }

  //       const token = this.localStorageService.getItem('token');
  //       // if there is token then fetch the current user
  //       if (token) {
  //         return this.fetchCurrentUser();
  //       }

  //       return of(null);
  //     })
  //   );
  // }

  // fetchCurrentUser(): Observable<User> {
  //   return this.http.get<User>(`${environment.apiUrl}/current-user`)
  //     .pipe(
  //       tap(user => {
  //         this.user$.next(user);
  //       })
  //     );
  // }

  refreshToken(): Observable<{ accessToken: string; refreshToken: string }> {
    const refreshToken = this.localStorageService.getItem('refreshToken');

    return this.http.post<{ accessToken: string; refreshToken: string }>(
      `${environment.apiUrl}/refresh-token`,
      {
        refreshToken
      }).pipe(
        tap(response => {
          this.setToken('token', response.accessToken);
          this.setToken('refreshToken', response.refreshToken);
        })
      );
  }

  private setToken(key: string, token: string): void {
    console.log('Token Key:', key);
    console.log('Token Value:', token);
    this.localStorageService.setItem(key, token);
  }

}