import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) { }


  canActivate() {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['/unauthorized']);
    return false;
  }


  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return this.authService.getCurrentUser().pipe(
  //     map(user => !!user),
  //     tap(isLogged => {
  //       if (!isLogged) {
  //         this.router.navigateByUrl('/unauthorized');
  //       }
  //     })
  //   );

  // if(this.authService.isRouteAuthenticated()){
  //   return true;
  // }
  // this.router.navigate["/home"];
  // return false;

}
