import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { bankDisplayedColumnsForms, bankMainColumns, bankColumnsToDisplay, bankInnerDisplayedColumns, bankTableSubhead, dataCategories, setFilterVars } from '../common/bankTableData';
import { UtilsService } from '../core/utils.service';
import { CXReportBankFiltered, SixthRowElement, } from '../models/report.model';
import { MainReportColumn, BankFilteredDynamicExcelData, FilterVars } from '../models/tableAndExcel.model';
import { CountryXApiService } from '../services/countryx.service';
import { ExcelExportService } from '../services/excel-export.service';
import { ExposureSelectCriteria } from '../models/selectCriteria.model';

@Component({
  selector: 'app-bank-filtered',
  templateUrl: './bank-filtered.component.html',
  styleUrls: ['./bank-filtered.component.scss']
})
export class BankFilteredComponent implements OnInit {

  criteriaObj: ExposureSelectCriteria = {
    reportFormat: [''],
    reportingPeriod: [],
    bankBHCName: [],
    region: [],
    country: [],
    caseManager: [],
    fieldOffice: [],
    concentration: [],
    reportType: ''
  };
  filterKey: string;
  currentQtr: string;
  previousQuarter: string;
  loading: boolean = true;

  cxReportBankFilteredList: CXReportBankFiltered[] = [];

  dataSource: MatTableDataSource<CXReportBankFiltered>;
  sixthRowDataSource: MatTableDataSource<SixthRowElement>;

  displayedColumnsForms = bankDisplayedColumnsForms;
  mainColumns = bankMainColumns;
  columnsToDisplay = bankColumnsToDisplay;
  innerDisplayedColumns = bankInnerDisplayedColumns;
  filterTitle: string;
  subHeaderColumns: string[];

  expandedElement: CXReportBankFiltered;

  secondRowHeaders = dataCategories;
  thirdRowHeaders: string[];
  subColumns: MainReportColumn[];

  constructor(private apiService: CountryXApiService, public utils: UtilsService, private route: ActivatedRoute, private excelExportService: ExcelExportService, private router: Router) { }

  ngOnInit(): void {
    this.setComponentData();
    this.getData();
    this.apiService.getCurrentQuarter().then((response: any) => {
      this.currentQtr = response.data[0].reportingPeriod;
    });
  }

  setComponentData(): void {
    const { bankBHCName, caseManager, concentration, country, fieldOffice, reportFormat, previousQuarter, region, reportingPeriod, filter } =
      this.route.snapshot.queryParams;

    // Adds params as array if not array  
    this.criteriaObj.bankBHCName = typeof bankBHCName === 'string' ? [bankBHCName] : bankBHCName;
    this.criteriaObj.caseManager = typeof caseManager === 'string' ? [caseManager] : caseManager;
    this.criteriaObj.concentration = typeof concentration === 'string' ? [concentration] : concentration;
    this.criteriaObj.country = typeof country === 'string' ? [country] : country;
    this.criteriaObj.fieldOffice = typeof fieldOffice === 'string' ? [fieldOffice] : fieldOffice;
    this.criteriaObj.reportFormat = typeof reportFormat === 'string' ? [reportFormat] : reportFormat;
    this.criteriaObj.region = typeof region === 'string' ? [region] : region;
    this.criteriaObj.reportingPeriod = typeof reportingPeriod === 'string' ? [reportingPeriod] : reportingPeriod;
    this.previousQuarter = previousQuarter;
    this.thirdRowHeaders = ['Country Name', `${reportingPeriod} $Exp`, `${previousQuarter} $Exp`, `${reportingPeriod} Tier 1 Concentration`, `${reportingPeriod} $Exp`, `${previousQuarter} $Exp`, `${reportingPeriod} Tier 1 Concentration`,];

    this.filterKey = filter;
    const filterVars: FilterVars = setFilterVars(filter);
    this.filterTitle = filterVars.filterTitle;
    this.subColumns = filterVars.subColumns;
    this.subHeaderColumns = this.subColumns.map((col) => col.name);
  }

  getData(): void {
    this.apiService.getCountryXDataByBank(this.criteriaObj, this.previousQuarter).then((response: any) => {
      this.loading = false;
      if (response.data.length > 0) {
        this.cxReportBankFilteredList = this.apiService.getTransformedCountryXDataByBankFiltered(response.data, this.filterKey);
        this.dataSource = new MatTableDataSource(this.cxReportBankFilteredList);
      } else {
        this.router.navigate(['/nodata'], { queryParams: { format: this.criteriaObj.reportFormat[0], reportPeriod: this.criteriaObj.reportingPeriod[0], bank: this.criteriaObj.bankBHCName, previousQuarter: this.previousQuarter, region: this.criteriaObj.region, country: this.criteriaObj.country, caseManager: this.criteriaObj.caseManager, fieldOffice: this.criteriaObj.fieldOffice, concentration: this.criteriaObj.concentration[0] } });
      }
    });
  }

  exportExcel(): void {
    const objForExcel: BankFilteredDynamicExcelData = this.excelExportService.createFilteredBankData(this.cxReportBankFilteredList, this.filterTitle, this.subColumns);

    objForExcel.tableHeader = `COUNTRY EXPOSURE >= ${this.criteriaObj.concentration}% OF TIER 1 CAPITAL - ${this.criteriaObj.reportingPeriod}`;
    objForExcel.format = this.criteriaObj.reportFormat[0];
    objForExcel.thirdRowHeaders = this.thirdRowHeaders;

    this.excelExportService.exportToExcel('bank-filtered', objForExcel);
  }

  async generateLatestFacsimileReport(certNumber: number, facBank: string) {
    const facFormat = 'Schedule C Part I';

    return this.router.navigate(['/form009_2013'], { queryParams: { facFormat, facReportPeriod: this.currentQtr, facBank }, queryParamsHandling: 'merge' });
  }
}