import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { dataCategoriesExcel, bankTableSubhead, bankColumnsToDisplay, bankInnerDisplayedColumns, expMainColumnsExcel, createExpExcelBankRow, expBankColumnHeaders, expSubtotals, expMainColumnsToDisplay, bankInfoHeaders } from '../common/bankTableData';
import { CXReportBankFiltered, CXReportBankFilteredExp, CXReportCountryFiltered, CXReportCountryFilteredExp } from '../models/report.model';
import { ByBankDynamicExcelData, ByCountryDynamicExcelData, CountryFilteredDynamicExcelData, BankFilteredDynamicExcelData, MainReportColumn, BankFilteredExpDynamicExcelData, ByBankExpDynamicExcelData, ByCountryExpDynamicExcelData, CountryFilteredExpDynamicExcelData, } from '../models/tableAndExcel.model';
import { CXSelectService } from './cxselect.service';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  specialTextColor = 'FF000066';
  heavyShading = 'FFC0C0C0';
  lightShading = 'FFE0E0E0';
  filterRowColor = 'FF003256';
  whiteText = 'FFFFFF';

  selectCriteria: MainReportColumn[] = [
    { display: 'Report Format:', name: 'reportFormat' },
    { display: 'FDIC Region:', name: 'region' },
    { display: 'Country:', name: 'country' },
    { display: 'Field Office:', name: 'fieldOffice' },
    { display: 'Bank/BHC:', name: 'bankBHCName' },
    { display: 'Case Manager:', name: 'caseManager' },
    { display: 'Quarter:', name: 'reportingPeriod' },
    { display: 'Concentration:', name: 'concentration' }
  ];

  expandedReport: boolean;

  constructor(private selectService: CXSelectService, private route: ActivatedRoute) { }

  exportToExcel(reportType: string, data: any): void {
    this.expandedReport = reportType.includes('exp') ? true : false;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Country Exposure', { views: [{ showGridLines: false }] });
    worksheet = this.createHeader(worksheet, data);
    worksheet = this.expandedReport
      ? this.createTemplateExpanded(worksheet, data)
      : this.createTemplateRegular(worksheet, data);

    switch (reportType) {
      case 'bank':
        worksheet = this.byBankExport(worksheet, data);
        break;
      case 'bank-filtered':
        worksheet = this.bankFilteredExport(worksheet, data);
        break;
      case 'bank-exp':
        worksheet = this.byBankExpExport(worksheet, data);
        break;
      case 'bank-filtered-exp':
        worksheet = this.bankFilteredExpExport(worksheet, data);
        break;
      case 'country':
        worksheet = this.byCountryExport(worksheet, data);
        break;
      case 'country-filtered':
        worksheet = this.countryFilteredExport(worksheet, data);
        break;
      case 'country-exp':
        worksheet = this.byCountryExpExport(worksheet, data);
        break;
      case 'country-filtered-exp':
        worksheet = this.countryFilteredExpExport(worksheet, data);
        break;
      default:
        console.log('Report type not found');
        workbook = null;
    }
    if (workbook !== null) {
      worksheet = this.createSelectCriteria(worksheet);
      worksheet = this.createFooter(worksheet);
      // Create and download Excel file
      workbook.xlsx.writeBuffer().then((items) => {
        let blob = new Blob([items], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'CountryX.xlsx');
      });
    } else {
      alert('There was an error when exporting to Excel.')
    }
  }

  // Structure data for bank report with filters
  createFilteredBankData(data: CXReportBankFiltered[], filterTitle: string, subColumn: MainReportColumn[]): BankFilteredDynamicExcelData {
    const sortByElement = [];
    const regionData = [];
    const bankInfo = [];
    const countryData = [];
    const subtotalRowData = [];

    data.forEach((parentItem) => {
      const { cxReportBankBaseList, subtotalRow } = parentItem;
      sortByElement.push(`${filterTitle}: ${parentItem.filterGroup}`);
      const distinctRegionData = [];
      const distinctBankInfo = [];
      const distinctCountryData = []

      cxReportBankBaseList.forEach((bank) => {
        const { fourthRowGroup, caseMgrFieldOffRow, sixthRowElement } = bank;
        const regionDataArr = subColumn.map((col) => `${col.display}: ${caseMgrFieldOffRow[col.name]}`);
        distinctRegionData.push(regionDataArr);
        const bankArr = bankColumnsToDisplay.map((col) => {
          if (col === 'totalAssets' || col === 'tier1Cap') {
            return Number(fourthRowGroup[col].replace(/,/g, ''));
          }
          return fourthRowGroup[col];
        });
        distinctBankInfo.push(bankArr);
        // Percentage columns need changed from string to number format and to correct decimal format
        const countryArray = sixthRowElement.map((elem) => {
          return bankInnerDisplayedColumns.map((col) => {
            if (col === "currCountryRiskCon" || col === "currTransferRiskCon") {
              return Number(elem[col].slice(0, -1)) / 100;
            }
            if (col !== 'country') {
              return Number(elem[col].replace(/,/g, ''));
            }
            return elem[col];
          })
        });
        distinctCountryData.push(countryArray)
      });
      regionData.push(distinctRegionData);
      bankInfo.push(distinctBankInfo);
      countryData.push(distinctCountryData);
      subtotalRowData.push(['Subtotal:', subtotalRow.currCountryRiskExpTotal, subtotalRow.prevCountryRiskExpTotal, '', subtotalRow.currTransferRiskExpTotal, subtotalRow.prevTransferRiskExpTotal, '']);
    });
    return {
      tableHeader: '',
      format: '',
      thirdRowHeaders: [],
      sortByElement,
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    };
  }

  createFilteredBankExpData(data: CXReportBankFilteredExp[], filterTitle: string, subColumn: MainReportColumn[]): BankFilteredExpDynamicExcelData {
    const sortByElement = [];
    const regionData = [];
    const bankInfo = [];
    const countryData = [];
    const subtotalRowData = [];
    data.forEach((parentItem) => {
      const { cxReportBankBaseListExp: cxReportBankBaseList, subtotalRow } = parentItem;
      sortByElement.push(`${filterTitle}: ${parentItem.filterGroup}`);
      const distinctRegionData = [];
      const distinctBankInfo = [];
      const distinctCountryData = []

      cxReportBankBaseList.forEach((bank) => {
        const { fourthRowGroup, caseMgrFieldOffRow, expDetailRowElement } = bank;
        const regionDataArr = subColumn.map((col) => `${col.display}: ${caseMgrFieldOffRow[col.name]}`);
        regionDataArr.splice(1, 0, '');
        regionDataArr.push('');
        distinctRegionData.push(regionDataArr);
        const bankArr = expBankColumnHeaders.map((col) => {
          if (col === 'totalAssets' || col === 'tier1Cap') {
            return Number(fourthRowGroup[col].replace(/,/g, ''));
          }
          return fourthRowGroup[col];
        });
        bankArr.splice(4, 0, '')
        bankArr.splice(1, 0, '');
        distinctBankInfo.push(bankArr);

        const countryArray = expDetailRowElement.map((elem) => {
          return expMainColumnsToDisplay.map((col) => {
            if (col !== 'country') {
              return Number(elem[col].replace(/,/g, ''));
            }
            return elem[col];
          })
        });
        distinctCountryData.push(countryArray)
      });
      regionData.push(distinctRegionData);
      bankInfo.push(distinctBankInfo);
      countryData.push(distinctCountryData);

      const excelSubtotalFields = expSubtotals.slice(1).map((col) => col.name);
      const subtotalRowArr = excelSubtotalFields.map((field) => subtotalRow[field]);
      subtotalRowArr.unshift('Subtotal:');
      subtotalRowData.push(subtotalRowArr);
    });
    return {
      tableHeader: '',
      format: '',
      sortByElement,
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    };
  }

  // Structure data for country report with filters
  createFilteredCountryData(data: CXReportCountryFiltered[], filterTitle: string, subColumn: MainReportColumn[]): CountryFilteredDynamicExcelData {
    const sortByElement = [];
    const countries = [];
    const regionData = [];
    const bankInfo = [];
    const countryData = [];
    const subtotalRowData = [];

    data.forEach((parentItem) => {
      // sortByElement.push(item.caseManagerGroup);
      sortByElement.push(`${filterTitle}: ${parentItem.filterGroup}`);
      const distinctCountries = [];
      const distinctRegionData = [];
      const distinctBankInfo = [];
      const distinctCountryData = []
      const { cxReportCountryCaseManagerBaseList, subtotalRow } = parentItem;

      cxReportCountryCaseManagerBaseList.forEach((rec) => {
        const { countryGroup, countrySubBaseList } = rec;
        countrySubBaseList.forEach((bank) => {
          distinctCountries.push(countryGroup);
          const { fourthRowGroup, regionCaseManagerFieldOfficeRow, sixthRowElement } = bank;
          const regionDataArr = subColumn.map((col) => `${col.display}: ${regionCaseManagerFieldOfficeRow[col.name]}`);
          distinctRegionData.push(regionDataArr);
          const bankArr = bankColumnsToDisplay.map((col) => {
            if (col === 'totalAssets' || col === 'tier1Cap') {
              return Number(fourthRowGroup[col].replace(/,/g, ''));
            }
            return fourthRowGroup[col]
          });
          distinctBankInfo.push(bankArr);
          // Percentage columns need changed from string to number format and to correct decimal format
          const sixthRowArray = bankInnerDisplayedColumns.map((col) => {
            if (col === "currCountryRiskCon" || col === "currTransferRiskCon") {
              return Number(sixthRowElement[0][col].slice(0, -1)) / 100;
            }
            if (col !== 'country') {
              return Number(sixthRowElement[0][col].replace(/,/g, ''));
            }
            return sixthRowElement[0][col];
          });
          distinctCountryData.push(sixthRowArray);
        });
      });

      countries.push(distinctCountries);
      regionData.push(distinctRegionData);
      bankInfo.push(distinctBankInfo);
      countryData.push(distinctCountryData);

      subtotalRowData.push(['Subtotal:', subtotalRow.currCountryRiskExpTotal, subtotalRow.prevCountryRiskExpTotal, '', subtotalRow.currTransferRiskExpTotal, subtotalRow.prevTransferRiskExpTotal, '']);
    });

    return {
      tableHeader: '',
      format: '',
      thirdRowHeaders: [],
      sortByElement,
      countries,
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    };
  }

  createFilteredCountryExpData(data: CXReportCountryFilteredExp[], filterTitle: string, subColumn: MainReportColumn[]): CountryFilteredExpDynamicExcelData {
    const sortByElement = [];
    const countries = [];
    const regionData = [];
    const bankInfo = [];
    const countryData = [];
    const subtotalRowData = [];

    data.forEach((parentItem) => {
      sortByElement.push(`${filterTitle}: ${parentItem.filterGroup}`);
      const distinctCountries = [];
      const distinctRegionData = [];
      const distinctBankInfo = [];
      const distinctCountryData = []
      const { cxReportCountryBaseList, subtotalRow } = parentItem;

      cxReportCountryBaseList.forEach((rec) => {
        const { countryGroup, countrySubBaseList } = rec;
        countrySubBaseList.forEach((bank) => {
          distinctCountries.push(countryGroup);
          const { fourthRowGroup, regionCaseManagerFieldOfficeRow, expDetailRowElement } = bank;
          const regionDataArr = subColumn.map((col) => `${col.display}: ${regionCaseManagerFieldOfficeRow[col.name]}`);
          regionDataArr.splice(1, 0, '');
          regionDataArr.push('');
          distinctRegionData.push(regionDataArr);
          const bankArr = expBankColumnHeaders.map((col) => {
            if (col === 'totalAssets' || col === 'tier1Cap') {
              return Number(fourthRowGroup[col].replace(/,/g, ''));
            }
            return fourthRowGroup[col]
          });
          bankArr.splice(4, 0, '')
          bankArr.splice(1, 0, '');
          distinctBankInfo.push(bankArr);

          const countryArray = expMainColumnsToDisplay.map((col) => {
            if (col !== 'country') {
              return Number(expDetailRowElement[col].replace(/,/g, ''));
            }
            return expDetailRowElement[col];
          })
          distinctCountryData.push(countryArray);
        });
      });

      countries.push(distinctCountries);
      regionData.push(distinctRegionData);
      bankInfo.push(distinctBankInfo);
      countryData.push(distinctCountryData);

      const excelSubtotalFields = expSubtotals.slice(1).map((col) => col.name);
      const subtotalRowArr = excelSubtotalFields.map((field) => subtotalRow[field]);
      subtotalRowArr.unshift('Subtotal:');
      subtotalRowData.push(subtotalRowArr);
    });

    return {
      tableHeader: '',
      format: '',
      sortByElement,
      countries,
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    };
  }

  // Create static rows, configuration common to all reports
  createHeader(worksheet: Worksheet, data: any): Worksheet {
    const {
      tableHeader,
      format,
    } = data;
    const lastColumn = this.expandedReport ? 'I' : 'H';
    // When adding rows, must be an array. If not already an array, pass in as one.
    // Title row through third headings row is the same info, so can be set once initially
    const titleRow = worksheet.addRow([tableHeader]);
    worksheet.mergeCells(`A1:${lastColumn}1`);
    titleRow.font = { name: 'Calibri', size: 12, bold: true, italic: true, color: { argb: this.specialTextColor } };
    titleRow.height = 20;
    worksheet.getCell('A1').alignment = { vertical: 'bottom', horizontal: 'center' };

    const formatRow = worksheet.addRow([format]);
    worksheet.mergeCells(`A2:${lastColumn}2`);
    formatRow.font = { name: 'Calibri', size: 12, bold: true, italic: true, color: { argb: this.specialTextColor } };
    formatRow.height = 17;
    worksheet.getCell('A2').alignment = { vertical: 'top', horizontal: 'center' };

    const subTitleRow = worksheet.addRow([bankTableSubhead]);
    worksheet.mergeCells(`A3:${lastColumn}3`);
    subTitleRow.font = { name: 'Calibri', size: 10, bold: true, italic: true, color: { argb: this.specialTextColor } };
    worksheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center' };
    subTitleRow.height = 20;

    return worksheet;
  }

  createTemplateRegular(worksheet: Worksheet, data: any): Worksheet {
    const { thirdRowHeaders, } = data;

    const spacer = worksheet.addRow([]);
    worksheet.mergeCells(`A${worksheet.rowCount}:H${worksheet.rowCount}`);
    worksheet.getCell(`A${worksheet.rowCount}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: this.heavyShading },
    }
    spacer.height = 6;

    const categoryRow = worksheet.addRow(dataCategoriesExcel);
    categoryRow.eachCell((cell) => {
      cell.font = { name: 'Calibri', size: 10, bold: true, italic: true, color: { argb: this.specialTextColor } };
    });
    worksheet.mergeCells(`B${worksheet.rowCount}:D${worksheet.rowCount}`);
    worksheet.mergeCells(`E${worksheet.rowCount}:G${worksheet.rowCount}`);

    worksheet.getCell(`B${worksheet.rowCount}`).border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
    worksheet.getCell(`E${worksheet.rowCount}`).border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };

    const dataHeadingsRow = worksheet.addRow(thirdRowHeaders);
    dataHeadingsRow.font = { name: 'Calibri', size: 10, bold: true, italic: true, color: { argb: this.specialTextColor } };
    dataHeadingsRow.eachCell((header, i) => {
      if (i > 1) {
        header.border = { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } };
        header.alignment = { horizontal: 'right', wrapText: true };
      }
    })
    dataHeadingsRow.height = 43;
    const blankRow = worksheet.addRow([]);
    blankRow.height = 6;

    worksheet.getColumn(1).width = 35;
    worksheet.getColumn(2).width = 24;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 13;
    worksheet.getColumn(6).width = 10;
    worksheet.getColumn(7).width = 14;
    worksheet.getColumn(8).width = 10;

    return worksheet;
  }

  createTemplateExpanded(worksheet: Worksheet, data: any): Worksheet {
    const mainHeadingsRow = worksheet.addRow(expMainColumnsExcel);
    mainHeadingsRow.height = 30;
    mainHeadingsRow.eachCell((cell) => {
      cell.font = { name: 'Calibri', size: 10, bold: true, italic: true, color: { argb: this.specialTextColor } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.heavyShading },
      }
    })
    mainHeadingsRow.alignment = { vertical: "top", wrapText: true };

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;

    return worksheet;
  }

  byBankExport(worksheet: Worksheet, data: ByBankDynamicExcelData): Worksheet {
    const {
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    } = data;

    // Records must be looped through in order. Can start with bankInfo and track index.
    bankInfo.forEach((dataset, i) => {
      worksheet = this.createBankInfoRows(worksheet, dataset);
      const regionDataRow = worksheet.addRow(regionData[i]);
      worksheet.mergeCells(`B${worksheet.rowCount}:C${worksheet.rowCount}`);
      worksheet.mergeCells(`D${worksheet.rowCount}:E${worksheet.rowCount}`);
      regionDataRow.font = { bold: true };

      countryData[i].forEach((data) => {
        worksheet = this.createCountryDataRow(worksheet, data);
      })
      worksheet = this.createSubtotalRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
    })

    worksheet.views = [
      { state: 'frozen', ySplit: 6, showGridLines: false }
    ];

    return worksheet;
  }

  bankFilteredExport(worksheet: Worksheet, data: BankFilteredDynamicExcelData): Worksheet {
    const {
      sortByElement,
      regionData,
      bankInfo,
      countryData,
      subtotalRowData,
    } = data;

    // Loop through data for each sortByElement. Could include multiple banks
    sortByElement.forEach((elem, i) => {
      worksheet = this.createSortByRow(worksheet, elem, 'H');

      // Loop through each section of data for distinct manager
      regionData[i].forEach((region, j) => {
        const regionDataRow = worksheet.addRow(region);
        regionDataRow.font = { bold: true };
        worksheet = this.createBankInfoRows(worksheet, bankInfo[i][j]);

        countryData[i][j].forEach((data) => {
          worksheet = this.createCountryDataRow(worksheet, data);
        })

        // If there is another bank, add an extra row here.
        if (j < regionData[i].length - 1) {
          worksheet.addRow([]);
        }
      });

      worksheet = this.createSubtotalRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
    });

    worksheet.views = [
      { state: 'frozen', ySplit: 6, showGridLines: false }
    ];

    return worksheet;
  }

  byBankExpExport(worksheet: Worksheet, data: ByBankExpDynamicExcelData): Worksheet {
    const {
      bankInfo,
      regionData,
      countryData,
      subtotalRowData,
    } = data;

    // Records must be looped through in order. Can start with bankInfo and track index.
    bankInfo.forEach((dataset, i) => {
      worksheet = this.createBankInfoExpRows(worksheet, bankInfo[i])

      worksheet = this.createRegionExpDataRow(worksheet, regionData[i]);

      countryData[i].forEach((data) => {
        worksheet = this.createCountryDataExpRow(worksheet, data);
      })
      worksheet = this.createSubtotalExpRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
    })

    worksheet.views = [
      { state: 'frozen', ySplit: 4, showGridLines: false }
    ];

    return worksheet;
  }

  bankFilteredExpExport(worksheet: Worksheet, data: BankFilteredExpDynamicExcelData): Worksheet {
    const {
      sortByElement,
      regionData,
      bankInfo,
      countryData,
      subtotalRowData,
    } = data;

    const spacer = worksheet.addRow([]);
    spacer.height = 8;
    // Loop through data for each sortByElement. Could include multiple banks
    sortByElement.forEach((elem, i) => {
      worksheet = this.createSortByRow(worksheet, elem, 'I');

      // Loop through each section of data for distinct manager
      regionData[i].forEach((region, j) => {
        worksheet = this.createRegionExpDataRow(worksheet, region);

        worksheet = this.createBankInfoExpRows(worksheet, bankInfo[i][j]);

        countryData[i][j].forEach((data) => {
          worksheet = this.createCountryDataExpRow(worksheet, data);
        })

        // If there is another bank, add an extra row here.
        if (j < regionData[i].length - 1) {
          worksheet.addRow([]);
        }
      });

      worksheet = this.createSubtotalExpRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
      worksheet.views = [
        { state: 'frozen', ySplit: 4, showGridLines: false }
      ];
    });
    return worksheet;
  }

  byCountryExport(worksheet: Worksheet, data: ByCountryDynamicExcelData): Worksheet {
    const {
      countries,
      regionData,
      bankInfo,
      countryData,
      subtotalRowData,
    } = data;

    // Loop through data for each country
    countries.forEach((country, i) => {
      worksheet = this.createCountryHeaderNoFilter(worksheet, country, 'H');

      regionData[i].forEach((region, j) => {
        const regionDataRow = worksheet.addRow(region);
        regionDataRow.font = { bold: true };
        worksheet.mergeCells(`B${worksheet.rowCount}:C${worksheet.rowCount}`);
        worksheet.mergeCells(`D${worksheet.rowCount}:E${worksheet.rowCount}`);
        worksheet = this.createBankInfoRows(worksheet, bankInfo[i][j]);
        worksheet = this.createCountryDataRow(worksheet, countryData[i][j]);
      });
      worksheet = this.createSubtotalRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
    });
    return worksheet;
  }

  countryFilteredExport(worksheet: Worksheet, data: CountryFilteredDynamicExcelData): Worksheet {
    const {
      sortByElement,
      countries,
      regionData,
      bankInfo,
      countryData,
      subtotalRowData,
    } = data;

    let currentCountry = '';

    // Loop through data for each distinct sortByElement
    sortByElement.forEach((elem, i) => {
      worksheet = this.createSortByRow(worksheet, elem, 'H');

      // Loop through each set of arrays in parent sortByElement
      countries[i].forEach((country, j) => {
        // Data is grouped by country, so if current country is the same, don't add a new country header row
        if (currentCountry !== country.toUpperCase()) {
          worksheet = this.createCountryHeaderWithFilter(worksheet, country, 'I');
          currentCountry = country.toUpperCase();
        }
        const regionDataRow = worksheet.addRow(regionData[i][j]);
        regionDataRow.font = { bold: true };
        worksheet = this.createBankInfoRows(worksheet, bankInfo[i][j]);
        worksheet = this.createCountryDataRow(worksheet, countryData[i][j]);
      });
      worksheet = this.createSubtotalRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
    })
    return worksheet;
  }

  byCountryExpExport(worksheet: Worksheet, data: ByCountryExpDynamicExcelData): Worksheet {
    const {
      countries,
      regionData,
      bankInfo,
      countryData,
      subtotalRowData,
    } = data;
    // Loop through data for each country
    countries.forEach((country, i) => {
      worksheet = this.createCountryHeaderNoFilter(worksheet, country, 'H');

      regionData[i].forEach((region, j) => {
        worksheet = this.createRegionExpDataRow(worksheet, region);

        worksheet = this.createBankInfoExpRows(worksheet, bankInfo[i][j]);

        worksheet = this.createCountryDataExpRow(worksheet, countryData[i][j][0]);

        if (j < regionData[i].length - 1) {
          const spacer = worksheet.addRow([]);
          spacer.height = 6;
        }
      });
      worksheet = this.createSubtotalExpRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
    });

    worksheet.views = [
      { state: 'frozen', ySplit: 4, showGridLines: false }
    ];

    return worksheet;
  }

  countryFilteredExpExport(worksheet: Worksheet, data: CountryFilteredExpDynamicExcelData): Worksheet {
    const {
      sortByElement,
      countries,
      regionData,
      bankInfo,
      countryData,
      subtotalRowData,
    } = data;

    const spacer = worksheet.addRow([]);
    spacer.height = 8;
    let currentCountry = '';

    // Loop through data for each distinct sortByElement
    sortByElement.forEach((elem, i) => {
      worksheet = this.createSortByRow(worksheet, elem, 'I');

      // Loop through each set of arrays in parent sortByElement
      countries[i].forEach((country, j) => {
        // Data is grouped by country, so if current country is the same, don't add a new country header row
        if (currentCountry !== country.toUpperCase()) {
          worksheet = this.createCountryHeaderWithFilter(worksheet, country, 'I');
          currentCountry = country.toUpperCase();
        }
        worksheet = this.createRegionExpDataRow(worksheet, regionData[i][j]);

        worksheet = this.createBankInfoExpRows(worksheet, bankInfo[i][j]);

        worksheet = this.createCountryDataExpRow(worksheet, countryData[i][j]);
      });
      worksheet = this.createSubtotalExpRow(worksheet, subtotalRowData[i]);
      worksheet.addRow([]);
    })

    worksheet.views = [
      { state: 'frozen', ySplit: 4, showGridLines: false }
    ];

    return worksheet;
  }

  createRegionExpDataRow(worksheet: Worksheet, regionData: string[]): Worksheet {
    const regionDataRow = worksheet.addRow(regionData);
    worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
    worksheet.mergeCells(`C${worksheet.rowCount}:D${worksheet.rowCount}`);
    worksheet.mergeCells(`E${worksheet.rowCount}:F${worksheet.rowCount}`);
    regionDataRow.font = { bold: true };
    regionDataRow.alignment = { vertical: "middle", horizontal: "center" };
    regionDataRow.height = 17.5;

    return worksheet;
  }

  createCountryDataRow(worksheet: Worksheet, countryData: (string | number)[]): Worksheet {
    const newRow = worksheet.addRow(countryData);
    newRow.eachCell((cell, i) => {
      if (i > 1) {
        cell.alignment = { horizontal: "center" }
      }
      // Items 2, 3, 5, and 6 need changed to number format with commas
      if (i === 2 || i === 3 || i === 5 || i === 6) {
        cell.numFmt = '#,##0';
      }
      // Items 4 and 7 need changed to percentage format
      if (i === 4 || i === 7) {
        cell.numFmt = '0.00%';
      }
    });
    return worksheet;
  }

  createCountryDataExpRow(worksheet: Worksheet, countryData: (string | number)[]): Worksheet {
    const newRow = worksheet.addRow(countryData);
    newRow.eachCell((cell, i) => {
      if (i > 1) {
        cell.alignment = { horizontal: "center" };
        cell.numFmt = '#,##0';
      }
    });
    return worksheet;
  }

  createBankInfoRows(worksheet: Worksheet, bankData: (string | number)[]): Worksheet {
    const bankHeadRow = worksheet.addRow(bankInfoHeaders);
    bankHeadRow.font = { bold: true };
    bankHeadRow.alignment = { vertical: "middle", horizontal: "center" };
    bankHeadRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.lightShading },
      }
    });
    const bankInfoRow = worksheet.addRow(bankData);
    bankInfoRow.eachCell((cell, i) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.lightShading },
      },
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      if (i === 7 || i === 8) {
        cell.numFmt = '#,##0';
      }
    })
    bankInfoRow.height = 30;
    return worksheet;
  }

  createBankInfoExpRows(worksheet: Worksheet, bankData: (string | number)[]): Worksheet {
    const bankHead = createExpExcelBankRow();
    const bankHeadRow = worksheet.addRow(bankHead);
    bankHeadRow.font = { bold: true };
    bankHeadRow.alignment = { vertical: "middle", horizontal: "center" };
    bankHeadRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.lightShading },
      }
    });
    worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
    worksheet.mergeCells(`E${worksheet.rowCount}:F${worksheet.rowCount}`);
    bankHeadRow.height = 17.5;

    const bankInfoRow = worksheet.addRow(bankData);
    bankInfoRow.eachCell((cell, i) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.lightShading },
      }
      if (i === 8 || i === 9) {
        cell.numFmt = '#,##0';
      }
    });
    bankInfoRow.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
    worksheet.mergeCells(`E${worksheet.rowCount}:F${worksheet.rowCount}`);
    bankInfoRow.height = 30;

    return worksheet;
  }

  createSortByRow(worksheet: Worksheet, element: string, lastColumn: string): Worksheet {
    worksheet.addRow([element]);
    worksheet.mergeCells(`A${worksheet.rowCount}:${lastColumn}${worksheet.rowCount}`);
    worksheet.getCell(`A${worksheet.rowCount}`).font = { bold: true, color: { 'argb': this.whiteText } };
    worksheet.getCell(`A${worksheet.rowCount}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.filterRowColor } };

    return worksheet;
  }

  createCountryHeaderNoFilter(worksheet: Worksheet, country: string, lastColumn: string): Worksheet {
    const countryHeader = worksheet.addRow([country]);
    worksheet.mergeCells(`A${worksheet.rowCount}:${lastColumn}${worksheet.rowCount}`);
    countryHeader.font = { name: 'Arial', size: 10, bold: true, color: { 'argb': this.whiteText } };
    worksheet.getCell(`A${worksheet.rowCount}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.filterRowColor } };
    return worksheet;
  }

  createCountryHeaderWithFilter(worksheet: Worksheet, country: string, lastColumn: string): Worksheet {
    const countryHeader = worksheet.addRow([country]);
    worksheet.mergeCells(`A${worksheet.rowCount}:${lastColumn}${worksheet.rowCount}`);
    countryHeader.font = { name: 'Arial', size: 10, bold: true };
    worksheet.getCell(`A${worksheet.rowCount}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.heavyShading } };
    return worksheet;
  }

  createSubtotalRow(worksheet: Worksheet, subtotalData: (string | number)[]): Worksheet {
    const subtotalRow = worksheet.addRow(subtotalData);
    subtotalRow.eachCell((cell, i) => {
      cell.font = { bold: true };
      if (i !== 1) {
        cell.alignment = { horizontal: "center" };
      }
      if (i === 2 || i === 3 || i === 5 || i === 6) {
        cell.numFmt = '#,##0';
      }
    })
    return worksheet;
  }

  createSubtotalExpRow(worksheet: Worksheet, subtotalData: (string | number)[]): Worksheet {
    const subtotalRow = worksheet.addRow(subtotalData);
    subtotalRow.eachCell((cell, i) => {
      cell.font = { bold: true };
      if (i !== 1) {
        cell.alignment = { horizontal: "center" };
        cell.numFmt = '#,##0';
      }
    })
    return worksheet;
  }

  createSelectCriteria(worksheet: Worksheet): Worksheet {
    const params = this.route.snapshot.queryParams;
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.mergeCells(`A${worksheet.rowCount}:H${worksheet.rowCount}`);
    worksheet.getCell(`A${worksheet.rowCount}`).border = { top: { style: 'medium' } };
    const criteriaTitleRow = worksheet.addRow(['Selection Criteria for this Report']);
    worksheet.mergeCells(`A${worksheet.rowCount}:H${worksheet.rowCount}`);
    criteriaTitleRow.font = { name: 'Calibri', size: 12, bold: true, italic: true, color: { argb: this.specialTextColor } };
    criteriaTitleRow.alignment = { horizontal: 'center' };
    worksheet.addRow([]);
    worksheet.mergeCells(`A${worksheet.rowCount}:H${worksheet.rowCount}`);
    worksheet.getCell(`A${worksheet.rowCount}`).border = { bottom: { style: 'medium' } };
    worksheet.addRow([]);
    this.selectCriteria.forEach((crit) => {
      let value: string = typeof params[crit.name] === 'object' ? params[crit.name].join(', ') : params[crit.name];
      if (crit.name === "concentration") {
        value = `GREATER THAN OR EQUAL TO ${value}%`;
      }

      worksheet.addRow([crit.display, value]);
      worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'right' };
      worksheet.getCell(`A${worksheet.rowCount}`).font = { name: 'Calibri', size: 10, bold: true, italic: true, color: { argb: this.specialTextColor } };
      worksheet.getCell(`A${worksheet.rowCount}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.lightShading },
      };
    })
    return worksheet;
  }

  createFooter(worksheet: Worksheet): Worksheet {
    const countryRisk = '* Country risk claims are the sum of all cross-border claims, including claims from derivative products, and gross foreign office claims on local residents. For a given country, country risk claims measure the exposure of reporting banks to an event that might severely limit the ability of borrowers in that country to repay their debt.';
    const transferRisk = '* Transfer risk claims are the sum of all cross-border claims, including claims from derivative products, and net foreign office claims on local residents. For a given country, transfer risk claims measure the exposure of reporting banks to an event that might severely limit their ability to remove funds from that country.';

    const mergeColumn = this.expandedReport ? 'G' : 'E';
    worksheet.addRow([]);
    const noteRow = worksheet.addRow(['Note:']);
    noteRow.font = { bold: true };
    // Cells need merged first to use wrapText
    const countryRiskRow = worksheet.addRow([]);
    worksheet.mergeCells(`A${worksheet.rowCount}:${mergeColumn}${worksheet.rowCount}`);
    worksheet.getCell(`A${worksheet.rowCount}`).value = countryRisk;
    worksheet.getCell(`A${worksheet.rowCount}`).alignment = { wrapText: true };
    countryRiskRow.height = 37;

    const transferRiskRow = worksheet.addRow([]);
    worksheet.mergeCells(`A${worksheet.rowCount}:${mergeColumn}${worksheet.rowCount}`);
    worksheet.getCell(`A${worksheet.rowCount}`).value = transferRisk;
    worksheet.getCell(`A${worksheet.rowCount}`).alignment = { wrapText: true };
    transferRiskRow.height = 37;

    return worksheet;
  }
}